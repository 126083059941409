import { UserDTO } from 'core/generated/UserDto';
import { HttpParamsType, UserDetails } from 'core/models';
import { apiUrlMatcher, ApiUrls, get, post, put, _delete } from './helpers';

// update user details
export const updateUser = async (params: HttpParamsType<FormData | UserDTO>): Promise<UserDetails> => {
  const user = await put<UserDTO>(apiUrlMatcher(ApiUrls.updateUser) + '/' + params.queryParams?.id, params);
  return UserDetails.mapFromApiValue(user);
};

// get user details
export const getUser = async (id: string): Promise<UserDetails> => {
  const user = await get<UserDTO>(apiUrlMatcher(ApiUrls.getUser) + '/' + id);
  return UserDetails.mapFromApiValue(user);
};
// add new user
export const addUser = async (params: HttpParamsType<UserDTO>): Promise<UserDetails> => {
  const user = await post<UserDTO>(apiUrlMatcher(ApiUrls.addUser), params);
  return UserDetails.mapFromApiValue(user);
};

export const deleteUser = async (id: string): Promise<void> => {
  await _delete(apiUrlMatcher(ApiUrls.deleteUser) + '/' + id);
};
