import { createAction } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ChatDetails, ErrorResponseData, MessageDetails } from 'core/models';

export const setChatAsNotReadAction = createAction<string>('set chat as not read');
export const setChatAsReadAction = createAction<string>('set chat as read');

export const getChatsAction = createAction('get chats');
export const getChatsSuccessAction = createAction<ChatDetails[]>('get chats success');
export const getChatsFailAction = createAction<ErrorResponseData>('get chats fail');

export const createChatAction = createAction<ChatDetails>('create chat');
export const createChatSuccessAction = createAction<ChatDetails[]>('create chat success');
export const createChatFailAction = createAction<ErrorResponseData>('create chat fail');

export const updateChatAction = createAction<ChatDetails>('update chat');
export const updateChatSuccessAction = createAction<ChatDetails[]>('update chat success');
export const updateChatFailAction = createAction<ErrorResponseData>('update chat fail');

export const getMessagesAction = createAction<{ chatId: string; limit: number }>('get messages');
export const getMessagesSuccessAction = createAction<IDocs<MessageDetails[]>>('get messages success');
export const getMessagesFailAction = createAction<ErrorResponseData>('get messages fail');

export const emptyMessages = createAction('empty messages list action');

export const sendMessageAction = createAction<MessageDetails>('send message');
export const sendMessageSuccessAction = createAction<MessageDetails>('send message success');
export const sendMessageFailAction = createAction<ErrorResponseData>('send message fail');
