import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { FileComponent } from '../UI/File/file.component';
import { FileUpload } from '../UI/FileUpload/file-upload.component';

interface Props {
  documents: string[];
  handleFileRemove?: (filename: string) => void;
  handleFileUpload?: (files: FileList) => void;
  hasAction?: boolean;
  simpleDisplay?: boolean;
  fileStyles?: string;
  variant?: 'card' | 'button';
  uploadVariant?: 'button' | 'icon_button';
}

export const Attachements: React.FC<Props> = ({
  simpleDisplay = false,
  hasAction = true,
  handleFileUpload = () => {},
  handleFileRemove = () => {},
  documents = [],
  fileStyles = '',
  variant = 'button',
  uploadVariant = 'icon_button',
}) => {
  const { t } = useTranslation();
  const apiFilenames: string[] = documents?.map((doc) => doc.replace('uploads\\', ''));
  const filenames: string[] = apiFilenames?.map((doc) => doc.replace(/\..*\./, '.'));

  return (
    <Stack padding={3} spacing={1} height="100%">
      {!simpleDisplay && (
        <Stack direction="row" alignItems="center" padding={1}>
          <Typography variant="h6" gutterBottom align="left" color="gray">
            {t(`project.${documents.length ? '' : 'no_'}attachments`)}
          </Typography>
          {hasAction && uploadVariant === 'icon_button' && <FileUpload variant={uploadVariant} handleChange={handleFileUpload} />}
        </Stack>
      )}
      <Stack flex={1} sx={{ overflowY: 'auto' }}>
        {documents?.map((doc, i) => (
          <FileComponent
            fileStyles={fileStyles}
            hasAction={hasAction}
            key={i}
            variant={variant}
            filename={filenames[i]}
            apiFilename={doc}
            deleteAction={handleFileRemove}
          />
        ))}
      </Stack>
      {hasAction && uploadVariant === 'button' && <FileUpload variant={uploadVariant} handleChange={handleFileUpload} />}
    </Stack>
  );
};
