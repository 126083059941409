import MuiAppBar from 'modules/Menu/navbar.component';
import React, { ReactElement, useEffect } from 'react';
import { AppState } from 'store';
import AddRecommendation from 'modules/Recommendation/recommendation.component';
import { useDispatch, useSelector } from 'react-redux';
import { connectAction, disconnectAction } from 'modules/Socket/state/socket.actions';
import { LinearProgress } from '@mui/material';
import { getChatsAction } from 'modules/Chat/state/chat.actions';
import { loadUsersAction } from 'modules/usersList/state/users.actions';
import { roles } from 'core/enums';
interface Props {
  children: ReactElement | ReactElement[];
}

const Layout: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const {
    auth: { user },
    socket: { connecting },
  } = useSelector((state: AppState) => state);
  const isAdmin = user?.role === roles.ADMIN;

  useEffect(() => {
    // connect to socket server
    dispatch(connectAction(user.id));
    // getting the connected user's chats
    dispatch(getChatsAction());
    dispatch(loadUsersAction());
    return () => {
      // disconnect the socket connection and empty the instanceof socket in case user logged out
      dispatch(disconnectAction());
    };
  }, []);

  return connecting ? (
    <LinearProgress />
  ) : (
    <>
      <MuiAppBar />
      {children}
      {isAdmin && <AddRecommendation />}
    </>
  );
};

export default React.memo(Layout);
