import { MessageDTO } from 'core/generated/MessageDTO';
import { TeamMemberDetails } from '../teamMember/team-member-details';
import { I } from '../user/user-details';

export interface MessageDetails extends I {
  id: string;
  chat: string;
  sender: TeamMemberDetails;
  text: string;
  files: string[] | File[];
  createdAt?: string;
  updatedAt?: string;
}
export namespace MessageDetails {
  export const mapFromApiValue = (message: MessageDTO): MessageDetails => {
    const messageDetails = {} as MessageDetails;
    Object.keys(message).forEach((key) => {
      if (message[key]) {
        if (key === '_id') {
          messageDetails['id'] = message['_id'];
        } else if (key === 'sender') {
          messageDetails['sender'] = TeamMemberDetails.mapFromApiValue(message['sender']);
        } else {
          messageDetails[key] = message[key];
        }
      }
    });
    return messageDetails;
  };
  export const mapToApiValue = (message: MessageDetails): MessageDTO => {
    const messageDto = {} as MessageDTO;
    Object.keys(message).forEach((key) => {
      if (message[key]) {
        if (key === 'id') {
          messageDto['_id'] = message['id'];
        } else if (key === 'sender') {
          messageDto['sender'] = TeamMemberDetails.mapToApiValue(message['sender']);
        } else {
          messageDto[key] = message[key];
        }
      }
    });
    return messageDto;
  };
  export const mapToFormData = (data: MessageDTO): FormData => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        if (key === 'files') {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < data[key].length; i++) {
            formData.append(`files`, data[key][i]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    });
    return formData;
  };
}
