import { createTheme } from '@mui/material';
import { getPersistedTheme } from 'core/utils';
import { colors } from './shared-theme';

export const getProfileTheme = (defaultTheme?: string[]) => {
  const theme = defaultTheme && defaultTheme.length ? defaultTheme : getPersistedTheme();

  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: { main: secondaryColor },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          root: {
            body: {
              '& div': {
                '&.color_picker_container': {
                  margin: '15px',
                },
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: '20px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            maxHeight: '40px',
            width: '100%',
            borderColor: primaryColor,
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            '&.theme_action_btns': {
              padding: '10px',
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: colors.GREY,
            '&.theme_icon': {
              color: secondaryColor,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.edit_theme_btn': {
              color: colors.WHITE,
              margin: '8px',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.section_title': {
              color: colors.GREY,
            },
            '&.about_item_text': {
              minHeight: '30px',
              borderRadius: '5px',
              paddingLeft: '5px',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                width: '100%',
                background: colors.HOVER_COLOR,
              },
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            '&.user_info': {
              border: '1px solid rgba(0, 0, 0, 0.2)',
              borderRadius: '5px',
              padding: '10px',
            },
          },
        },
      },
    },
  });
};
