import { createReducer, Action } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ChatDetails, MessageDetails } from 'core/models';
import {
  createChatAction,
  createChatFailAction,
  createChatSuccessAction,
  emptyMessages,
  getChatsAction,
  getChatsFailAction,
  getChatsSuccessAction,
  getMessagesAction,
  getMessagesFailAction,
  getMessagesSuccessAction,
  sendMessageSuccessAction,
  setChatAsNotReadAction,
  setChatAsReadAction,
  updateChatAction,
  updateChatSuccessAction,
  updateChatFailAction,
} from './chat.actions';

interface Chatbox {
  loading: boolean;
  chatIsbeingCreated: boolean;
  chatIsBeingUpdated: boolean;
  chatbox: ChatDetails[];
  messages: IDocs<MessageDetails[]>;
  messagesAreLoading: boolean;
}
const initialState: Chatbox = {
  loading: false,
  chatIsbeingCreated: false,
  chatIsBeingUpdated: false,
  chatbox: [] as ChatDetails[],
  messages: { limit: 10 } as IDocs<MessageDetails[]>,
  messagesAreLoading: true,
};

const _chatboxReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getChatsAction, (state) => ({ ...state, loading: true }))
    .addCase(getChatsSuccessAction, (state, { payload }) => ({ ...state, loading: false, chatbox: payload }))
    .addCase(getChatsFailAction, (state) => ({ ...state, loading: false }))

    .addCase(createChatAction, (state) => ({ ...state, chatIsbeingCreated: true }))
    .addCase(createChatFailAction, (state) => ({ ...state, chatIsbeingCreated: false }))
    .addCase(createChatSuccessAction, (state, { payload }) => ({ ...state, chatIsbeingCreated: false, chatbox: payload }))

    .addCase(updateChatAction, (state) => ({ ...state, chatIsBeingUpdated: true }))
    .addCase(updateChatSuccessAction, (state, { payload }) => ({ ...state, chatIsBeingUpdated: false, chatbox: payload }))
    .addCase(updateChatFailAction, (state) => ({ ...state, chatIsBeingUpdated: false }))

    .addCase(setChatAsNotReadAction, (state, { payload }) => {
      const recentChat = state.chatbox.find((chat) => chat.id === payload);
      if (recentChat) {
        return { ...state, chatbox: [{ ...recentChat, notRead: true }, ...state.chatbox.filter((chat) => chat.id !== payload)] };
      }
      return { ...state };
    })
    .addCase(setChatAsReadAction, (state, { payload }) => ({
      ...state,
      chatbox: state.chatbox.map((chat) => (chat.id === payload ? { ...chat, notRead: false } : chat)),
    }))

    .addCase(getMessagesAction, (state) => ({ ...state, messagesAreLoading: true }))
    .addCase(getMessagesSuccessAction, (state, { payload }) => ({ ...state, messagesAreLoading: false, messages: payload }))
    .addCase(getMessagesFailAction, (state) => ({ ...state, messagesAreLoading: false }))
    .addCase(emptyMessages, (state) => ({ ...state, messages: initialState.messages }))

    .addCase(sendMessageSuccessAction, (state, { payload }) => ({
      ...state,
      messages: { ...state.messages, docs: [...state.messages.docs, payload] },
    })),
);

export const chatboxReducer = (state: Chatbox | undefined, action: Action) => _chatboxReducer(state, action);
