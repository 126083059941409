import { Grid, ThemeProvider } from '@mui/material';
import React from 'react';
import { getItemWrapperTheme } from 'shared/themes';
interface Props {
  children: JSX.Element;
}

export const ItemWrapper: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={getItemWrapperTheme()}>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        {children}
      </Grid>
    </ThemeProvider>
  );
};
