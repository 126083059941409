export const menu = {
  projects: 'Projects',
  users: 'Users',
  list: 'List',
  home: 'Home',
  events: 'Events',
  profiles: 'Profiles',
  open_profile: 'Open Profile',
  recommendations: 'Feedbacks',
  open_projects: 'Navigate to Projects',
};
