import ProjectsList from 'modules/ProjectsList/projects.component';
import React, { useEffect } from 'react';

export const ProjectsPage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Projects list page';
  }, []);
  return <ProjectsList />;
};
