import React from 'react';
import SnackBar from 'modules/snackbar/snackbar.component';
import RoutesList from 'routes/routes';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { getTheme } from 'shared/themes';
import { LocalizationProvider } from '@mui/lab/';
import DateAdapter from '@mui/lab/AdapterMoment';
import './assets/sass/common.scss';

function App() {
  const {
    auth: {
      user: { theme },
    },
  } = useSelector((state: AppState) => state);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={getTheme(theme)}>
        <CssBaseline />
        <SnackBar />
        <RoutesList />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default React.memo(App);
