import React, { useState } from 'react';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { AvatarIcon, Search } from 'shared/components';
import { Clear as ClearIcon, AddCircle as AddCircleIcon } from '@mui/icons-material/';
import { TeamMemberDetails } from 'core/models';
import { useTranslation } from 'react-i18next';
import { ChatDetails } from 'core/models';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { updateChatAction } from '../state/chat.actions';

interface Props {
  selectedChat?: ChatDetails | null;
  hasAction?: boolean;
}

const UsersListComponent: React.FC<Props> = ({ selectedChat, hasAction = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState<string>('');
  const {
    auth: { user },
    users: { users },
    chatbox: { chatbox, chatIsBeingUpdated },
  } = useSelector((state: AppState) => state);
  selectedChat = chatbox.find((chat) => chat.id === selectedChat?.id);

  const chatMembersIds = selectedChat?.members.map((member) => member.id);
  const notMembers = users.filter((el) => !chatMembersIds?.includes(el.id));

  const addMember = (member: TeamMemberDetails) => {
    if (selectedChat) {
      dispatch(updateChatAction({ ...selectedChat, members: [...selectedChat.members, member] }));
    }
  };
  const removeMember = (member: TeamMemberDetails) => {
    if (selectedChat) {
      dispatch(updateChatAction({ ...selectedChat, members: selectedChat.members.filter((el) => el.id !== member.id) }));
    }
  };

  return (
    <Stack padding={2} spacing={2} component="form" width="35vw" justifyContent="center">
      <Typography variant="h6" gutterBottom align="center" color="gray">
        {selectedChat?.name} {t('chatbox.members')}
      </Typography>
      <Divider />
      <Search
        value={searchData}
        setsearchData={(value: string) => {
          setSearchData(value);
        }}
      />
      <List className="chat_details">
        {selectedChat?.members?.concat(hasAction ? notMembers : []).map((member) => {
          const name = member.firstName + ' ' + member.lastName;
          if (!name.includes(searchData)) return;
          const isChatAdmin = selectedChat?.admin.id === user.id;
          const isAChatMember = chatMembersIds?.includes(member.id);
          const membership = isAChatMember ? (selectedChat?.admin.id === member.id ? 'admin' : 'member') : 'not_member';
          return (
            <ListItem key={member.id}>
              <ListItemAvatar>
                <AvatarIcon name={name} avatar={member.avatar} />
              </ListItemAvatar>
              <ListItemText primary={name} secondary={t('chatbox.' + membership)} />
              {isChatAdmin && hasAction && membership !== 'admin' && (
                <ListItemSecondaryAction>
                  <Tooltip title={t('chatbox.' + (isAChatMember ? 'remove_member' : 'add_member')) as string}>
                    <IconButton
                      disabled={chatIsBeingUpdated}
                      onClick={() => {
                        isAChatMember ? removeMember(member) : addMember(member);
                      }}
                    >
                      {isAChatMember ? (
                        <ClearIcon className="member_action_icon member_action_icon_remove" />
                      ) : (
                        <AddCircleIcon className="member_action_icon member_action_icon_add" />
                      )}
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};
export const UsersList = React.memo(UsersListComponent);
