export const user = {
  id: 'ID',
  avatar: 'Avatar',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  role: 'Role',
  job_title: 'Job Title',
  department: 'Department',
  location: 'Location',
  profile: 'Profile',
  confirm_delete: 'Are you sure you want to delete this user?',
  add_user_success: 'User added successfully',
  update_user_success: 'User updated successfully',
  enabled: 'User account enabled',
  disabled: 'User account disabled',
  user_remove_success: 'User Removed Successfully',
  list: 'Users list',
  add_user: 'Add user +',
  edit_user: 'Edit user',
  password: 'Password',
};
