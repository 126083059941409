import React, { useState, useEffect } from 'react';
import { SprintDetails } from 'core/models/sprint/sprint-details';
import { Container, Stack, Typography } from '@mui/material';
import { InputElement, MuiBtnGrp } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DateRange } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { createSprintAction, updateSprintAction } from '../state/backlog.actions';
import { AppState } from 'store';

interface Props {
  handleClose?: () => void;
  toBeEditedSprint?: SprintDetails;
}

export const SprintEdit: React.FC<Props> = ({ toBeEditedSprint = {} as SprintDetails, handleClose = () => {} }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [sprint, setSprint] = useState<SprintDetails>({ project: id || '' } as SprintDetails);
  const dispatch = useDispatch();
  const { theSprintBeingUpdated, theSprintBeingCreated } = useSelector((state: AppState) => state.backlog);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSprint({ ...sprint, [e.target.name]: e.target.value });
  };
  const handleDateRangeChange = (v: DateRange<Date>) => {
    if (v) {
      if (v[0]) {
        setSprint({ ...sprint, startDate: v[0] });
      }
      if (v[1]) {
        setSprint({ ...sprint, endDate: v[1] });
      }
    }
  };
  const fields = [
    {
      value: sprint?.title || '',
      handleChange: handleChange,
      name: 'title',
      label: t('sprint.title'),
      type: 'text',
    },
    {
      value: sprint?.goal || '',
      handleChange: handleChange,
      name: 'goal',
      label: t('sprint.goal'),
      type: 'text',
      multiline: true,
    },
    {
      value: [sprint?.startDate || '', sprint?.endDate || ''],
      type: 'date_range',
      handleChange: handleDateRangeChange,
    },
  ];
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    toBeEditedSprint?.id ? dispatch(updateSprintAction(sprint)) : dispatch(createSprintAction(sprint));
    if (!theSprintBeingUpdated) {
      handleClose();
    }
  };
  useEffect(() => {
    if (toBeEditedSprint?.id) {
      setSprint(toBeEditedSprint);
    }
  }, []);

  return (
    <Container className="edit_sprint" component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" align="center" gutterBottom>
        {t(`project.${toBeEditedSprint.id ? 'edit' : 'create'}_sprint`)}
      </Typography>
      <Stack spacing={2}>
        {fields.map((field, i) => (
          <InputElement key={i} {...field} />
        ))}
      </Stack>
      <MuiBtnGrp
        saveIsDisabled={sprint.startDate && sprint.endDate ? sprint?.startDate > sprint?.endDate : false}
        saving={toBeEditedSprint.id ? !!theSprintBeingUpdated : !!theSprintBeingCreated}
        handleCancel={handleClose}
        submit={true}
        save={true}
      />
    </Container>
  );
};
