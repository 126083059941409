import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { getRecommendationTheme } from 'shared/themes';
import {
  CssBaseline,
  IconButton,
  Tooltip,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getRecommendationsAction, removeRecommendationAction } from './state/recommendation.actions';
import { Delete as DeleteIcon } from '@mui/icons-material/';
import { Alert } from 'shared/components';

const RecommendationList = () => {
  const { t } = useTranslation();
  const [recommendationId, setRecommendationId] = useState<string>();
  const open = Boolean(recommendationId);
  const dispatch = useDispatch();
  const {
    auth: {
      user: { theme },
    },
    recommendations: { recommendations, loading },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch(getRecommendationsAction());
  }, []);
  const handleOpen = (id: string) => {
    setRecommendationId(id);
  };

  const removeRecommendation = () => {
    if (recommendationId) {
      dispatch(removeRecommendationAction(recommendationId));
    }
  };

  return loading ? (
    <LinearProgress />
  ) : (
    <ThemeProvider theme={getRecommendationTheme(theme)}>
      <CssBaseline />
      <List>
        {recommendations.map((recommendation, i) => (
          <Fragment key={i}>
            <ListItem>
              <ListItemText
                primary={t('recommendation.recommendation_number') + (i + 1)}
                secondary={t('recommendation.anonymos') + ' — ' + recommendation.description}
              />
              <ListItemSecondaryAction>
                <Tooltip title={t('recommendation.delete') as string}>
                  <IconButton onClick={() => handleOpen(recommendation.id)}>
                    <DeleteIcon className="delete_icon" />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <Alert
        open={open}
        body={
          <Typography variant="h6" padding="10px 15px">
            {t('recommendation.confirm_delete')}
          </Typography>
        }
        handleOpen={() => {
          setRecommendationId(undefined);
        }}
        action={removeRecommendation}
      />
    </ThemeProvider>
  );
};

export default RecommendationList;
