import { createReducer, Action } from '@reduxjs/toolkit';
import { ProjectDetails } from 'core/models/project/project-details';
import { deleteProjectAction, deleteProjectSuccessAction, restoreProjectSuccessAction } from 'modules/Project/state/project.actions';
import { loadProjectsAction, loadProjectsFailedAction, loadProjectsSuccessAction } from './projects.actions';

export interface ProjectsState {
  loading: boolean;
  deletingProject: boolean;
  projects: ProjectDetails[];
  total: number;
}

const initialState = {
  total: 0,
  loading: false,
  deletingProject: false,
  projects: [] as ProjectDetails[],
};

const _projectsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(loadProjectsAction, (state) => ({ ...state, loading: true }))
    .addCase(loadProjectsSuccessAction, (state, { payload }) => ({
      ...state,
      loading: false,
      total: payload.total,
      projects: payload.projects,
    }))
    .addCase(deleteProjectAction, (state) => ({ ...state, deletingProject: true }))
    .addCase(deleteProjectSuccessAction, (state, { payload }) => ({ ...state, projects: payload, deletingProject: false }))
    .addCase(restoreProjectSuccessAction, (state, { payload }) => ({ ...state, projects: payload }))
    .addCase(loadProjectsFailedAction, (state) => ({
      ...state,
      loading: false,
      deletingProject: false,
    }))
    .addDefaultCase((state) => state),
);

export const projectsReducer = (state: ProjectsState | undefined, action: Action): ProjectsState => _projectsReducer(state, action);
