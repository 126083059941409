import { Button, ButtonGroup, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { UserDetails } from 'core/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldData } from 'shared/interface';
import { LoaderButton } from '../LoaderButton/button.component';

interface Props {
  el: {
    id: string;
    icon: JSX.Element;
  };
  loading: boolean;
  user: UserDetails;
  field: FieldData;
  setField: React.Dispatch<React.SetStateAction<FieldData>>;
  update: () => void;
  clearData: () => void;
}

export const EditableField: React.FC<Props> = ({ user, el, field, loading = false, setField, update, clearData }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="center" width="100%" minHeight="40px">
      {el.icon}
      {field.isBeingEdited && field.id === el.id ? (
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <TextField
            label={t(`user.${el.id}`)}
            placeholder={t(`user.${el.id}`)}
            value={field.value}
            onChange={(e) => setField({ ...field, value: e.target.value })}
          />
          <ButtonGroup>
            <Button endIcon={loading ? <LoaderButton /> : null} disabled={loading} variant="contained" onClick={() => update()}>
              {t(`common.save`)}
            </Button>
            <Button variant="contained" onClick={() => clearData()}>
              {t('common.cancel')}
            </Button>
          </ButtonGroup>
        </Stack>
      ) : (
        <Tooltip title={t('profile.' + el.id) as string} onClick={() => setField({ id: el.id, isBeingEdited: true, value: user[el.id] })}>
          <Typography className="about_item_text" color={`${user[el.id] ? 'black' : 'gray'}`}>
            {user[el.id] ? user[el.id] : t(`user.${el.id}`)}
          </Typography>
        </Tooltip>
      )}
    </Stack>
  );
};
