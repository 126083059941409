import { PayloadAction } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ErrorResponseData, QueryData } from 'core/models';
import { ProjectDetails } from 'core/models/project/project-details';
import { loadProjects } from 'core/services/projects.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { loadProjectsAction, loadProjectsFailedAction, loadProjectsSuccessAction } from './projects.actions';

function* loadProjectsEffect({
  payload,
}: PayloadAction<QueryData>): Generator<Promise<IDocs<ProjectDetails[]>> | PutEffect, void, IDocs<ProjectDetails[]>> {
  try {
    const { docs, total } = yield loadProjects(payload);
    yield put(loadProjectsSuccessAction({ projects: docs, total }));
  } catch (error) {
    yield put(loadProjectsFailedAction(error as ErrorResponseData));
  }
}
function* loadProjectsFailedEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  yield put(
    openSnackBarAction({
      message: error.status ? 'error.server' : 'error.network',
      severity: 'error',
    }),
  );
}

export function* watchProjects() {
  yield all([takeLatest(loadProjectsAction.type, loadProjectsEffect), takeLatest(loadProjectsFailedAction.type, loadProjectsFailedEffect)]);
}
