import { Button, Grid, IconButton, Typography } from '@mui/material';
import { QueryData } from 'core/models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AvatarIcon, CustomTable } from 'shared/components';
import { AppState } from 'store';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { loadUsersAction } from './state/users.actions';
import { routes } from 'core/enums/routes';
import { navigate } from 'core/services/history.service';
import { deleteUserAction } from 'modules/User/state/user.actions';
import { isPermitted } from 'core/utils';
import { Permissions, resources } from 'core/enums';

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const { users, loading, total } = useSelector((state: AppState) => state.users);
  const { user } = useSelector((state: AppState) => state.auth);
  const [searchData, setsearchData] = useState<QueryData>({ page: 0, limit: 5 });
  const loadUsers = () => dispatch(loadUsersAction(searchData));
  const [toBeDeletedId, setToBeDeletedId] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    loadUsers();
  }, [searchData]);

  const getLabels = () => {
    const labels = ['avatar', 'firstName', 'lastName', 'email', 'role', 'job_title', 'department'].map((value) => t(`user.${value}`));
    if (isPermitted(user, resources.USERS, Permissions.edit) || isPermitted(user, resources.USERS, Permissions.delete)) {
      labels.push(t('common.actions'));
    }
    return labels;
  };
  const handleDelete = () => {
    dispatch(deleteUserAction(toBeDeletedId));
  };

  const getData = () =>
    users.map((el) => ({
      avatar: <AvatarIcon avatar={el.avatar} name={el.firstName + ' ' + el.lastName} />,
      firstName: el.firstName,
      lastName: el.lastName,
      email: el.email,
      role: el.role,
      job_title: el.job_title,
      department: el.department,
      actions: (
        <>
          {isPermitted(user, resources.USERS, Permissions.edit) && (
            <IconButton onClick={() => navigate(routes.user, el.id)}>
              <EditIcon className="edit_icon" />
            </IconButton>
          )}
          {isPermitted(user, resources.USERS, Permissions.delete) && (
            <IconButton
              onClick={() => {
                setToBeDeletedId(el.id);
                setOpenAlert(true);
              }}
            >
              <DeleteIcon className="delete_icon" />
            </IconButton>
          )}
        </>
      ),
    }));
  return (
    <Grid container paddingTop={5}>
      <Typography paddingLeft="70px" variant="h4">
        {t('user.list')}
      </Typography>
      <CustomTable
        headBtn={
          isPermitted(user, resources.USERS, Permissions.edit) && (
            <Button variant="contained" onClick={() => navigate(routes.user)}>
              {t('user.add_user')}
            </Button>
          )
        }
        labels={getLabels()}
        data={getData()}
        loading={loading}
        handleChange={setsearchData}
        tableOptions={searchData}
        total={total}
      />
      <Alert
        open={openAlert}
        handleOpen={() => {
          setOpenAlert(!openAlert);
        }}
        message={t('user.confirm_delete')}
        action={handleDelete}
      />
    </Grid>
  );
};

export default Users;
