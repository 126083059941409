import React from 'react';
import { ImageList } from '@mui/material';
import { MuiImageListItem } from '../ImageListItem.component';

interface Props {
  images: string[];
}

export const MuiImageList: React.FC<Props> = ({ images = [] as string[] }) => {
  return (
    <ImageList cols={images.length > 1 ? 2 : 1}>
      {images.map((img, i) => (
        <MuiImageListItem img={img} key={i} />
      ))}
    </ImageList>
  );
};
