import Project from 'modules/Project/project.component';
import React, { useEffect } from 'react';

export const ProjectPage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Project details page';
  }, []);
  return <Project />;
};
