import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponseData, EventDetails } from 'core/models';
import { addEvent, deleteEvent, getEvents, updateEvent } from 'core/services/calendar.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  createEventAction,
  createEventFailAction,
  createEventSuccessAction,
  deleteEventAction,
  deleteEventSuccessAction,
  getEventsAction,
  getEventsFailAction,
  getEventsSuccessAction,
  updateEventAction,
  updateEventSuccessAction,
} from './calendar.actions';

function* getEventsEffect(): Generator<Promise<EventDetails[]> | PutEffect, void, EventDetails[]> {
  try {
    const data = yield getEvents();
    yield put(getEventsSuccessAction(data));
  } catch (error) {
    yield put(getEventsFailAction(error as ErrorResponseData));
  }
}

function* createEventEffect({ payload: event }: PayloadAction<EventDetails>): Generator<Promise<EventDetails[]> | PutEffect, void, EventDetails[]> {
  try {
    yield put(openSnackBarAction({ message: 'profile.calendar_updating', severity: 'info' }));
    const data = yield addEvent({ body: EventDetails.mapToApiValue(event) });
    yield put(createEventSuccessAction(data));
    yield put(openSnackBarAction({ message: 'profile.calendar_updating_success', severity: 'success' }));
  } catch (error) {
    yield put(createEventFailAction(error as ErrorResponseData));
  }
}

function* updateEventEffect({ payload: event }: PayloadAction<EventDetails>): Generator<Promise<EventDetails[]> | PutEffect, void, EventDetails[]> {
  try {
    yield put(openSnackBarAction({ message: 'profile.calendar_updating', severity: 'info' }));
    const data = yield updateEvent({ body: EventDetails.mapToApiValue(event) });
    yield put(updateEventSuccessAction(data));
    yield put(openSnackBarAction({ message: 'profile.calendar_updating_success', severity: 'success' }));
  } catch (error) {
    yield put(createEventFailAction(error as ErrorResponseData));
  }
}

function* deleteEventEffect({ payload: eventId }: PayloadAction<string>): Generator<Promise<EventDetails[]> | PutEffect, void, EventDetails[]> {
  try {
    const data = yield deleteEvent(eventId);
    yield put(deleteEventSuccessAction(data));
  } catch (error) {
    yield put(createEventFailAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  console.log(error.message);
  const message = error.status ? 'error.server' : 'error.network';
  yield put(openSnackBarAction({ message, severity: 'error' }));
}

export function* watchCalendar() {
  yield all([
    takeLatest(getEventsAction.type, getEventsEffect),
    takeLatest(createEventAction.type, createEventEffect),
    takeLatest(updateEventAction.type, updateEventEffect),
    takeLatest(deleteEventAction.type, deleteEventEffect),
    takeLatest(createEventFailAction.type, failEffect),
    takeLatest(getEventsFailAction.type, failEffect),
  ]);
}
