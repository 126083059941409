import { createTheme } from '@mui/material';
import { colors } from './shared-theme';

export const getTableTheme = (theme?: string[]) => {
  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;

  return createTheme({
    palette: {
      primary: {
        main: colors.BLACK,
        contrastText: colors.WHITE,
      },
      secondary: { main: secondaryColor },
      contrastThreshold: 3,
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: 5,
    },
    typography: {
      allVariants: {
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 15,
      },
    },
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            marginTop: 10,
            '.no_data': {
              color: colors.GREY,
              fontWeight: 'normal',
              fontStyle: 'italic',
              padding: '10px',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            maxWidth: 200,
            '&.MuiTableCell-head': {
              fontWeight: '500',
              color: colors.WHITE,
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            'tr:hover': {
              backgroundColor: colors.HOVER_COLOR,
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: secondaryColor,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '& .edit_icon': {
              color: 'green',
            },
            '& .delete_icon': {
              color: 'red',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor,
            margin: '15px',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: colors.LIGHT_BLUE,
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.star_icon': {
              color: colors.GOLD,
            },
            '&.folder_icon': {
              color: colors.DEEP_RED,
              transform: 'scale(1.3)',
            },
          },
        },
      },
    },
  });
};
