// loads all projects from the database based on a query string

import { IDocs } from 'core/generated/fetchedData';
import { ProfileDto } from 'core/generated/ProfileDto';
import { QueryData } from 'core/models';
import { ProfileDetails } from 'core/models/profile/profile-details';
import { apiUrlMatcher, ApiUrls, get } from './helpers';

export const loadProfiles = async (query: QueryData): Promise<IDocs<ProfileDetails[]>> => {
  const data = await get<IDocs<ProfileDto[]>>(apiUrlMatcher(ApiUrls.getProfiles), { queryParams: query });
  return { ...data, docs: data.docs.map((profile) => ProfileDetails.mapFromApiValue(profile)) };
};
