import { ProjectDetailsDTO } from 'core/generated/ProjectDetailsDTO';
import { SprintDetails } from '../sprint/sprint-details';
import { TeamMemberDetails } from '../teamMember/team-member-details';

const api = process.env.REACT_APP_API_ENDPOINT || '';

interface I {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ProjectDetails extends I {
  id: string;
  title: string;
  description: string;
  priority: number;
  deleted: boolean;
  lead: TeamMemberDetails;
  team: TeamMemberDetails[];
  avatar: File | string;
  documents: FileList | string[];
  sprints?: SprintDetails[];
  type: string;
  createdAt?: string;
  updatedAt?: string;
}

export namespace ProjectDetails {
  export const mapToApiValue = ({
    avatar,
    id,
    title,
    priority,
    lead,
    team,
    deleted,
    type,
    description,
    sprints,
    documents,
  }: ProjectDetails): ProjectDetailsDTO => ({
    _id: id,
    title,
    description,
    priority,
    avatar,
    lead: TeamMemberDetails.mapToApiValue(lead),
    deleted,
    team: team.map((member) => TeamMemberDetails.mapToApiValue(member)),
    type,
    sprints: sprints?.map((s) => SprintDetails.mapToApiValue(s)),
    documents,
  });
  export const mapFromApiValue = ({
    _id,
    title,
    description,
    priority,
    lead,
    deleted,
    team,
    type,
    avatar,
    sprints,
    createdAt,
    updatedAt,
    documents,
  }: ProjectDetailsDTO): ProjectDetails => ({
    id: _id,
    title,
    description,
    priority,
    avatar: avatar ? api + avatar : avatar,
    lead: TeamMemberDetails.mapFromApiValue(lead),
    deleted,
    team: team?.map((member) => TeamMemberDetails.mapFromApiValue(member)),
    type,
    documents,
    sprints: sprints?.map((sprint) => SprintDetails.mapFromApiValue(sprint)),
    createdAt,
    updatedAt,
  });
  export const mapToFormData = (data: ProjectDetailsDTO): FormData => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        if (key === 'team' || key === 'lead' || key === 'sprints') {
          formData.append(key, JSON.stringify(data[key]));
        } else if (key === 'documents') {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < data[key].length; i++) {
            formData.append(`documents`, data[key][i]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    });
    return formData;
  };
}
