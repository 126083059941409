import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponseData, QueryData } from 'core/models';
import { ProfileDetails } from 'core/models/profile/profile-details';
import { loadProfiles } from 'core/services/profiles.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { IDocs } from '../../../core/generated/fetchedData';
import { loadProfilesAction, loadProfilesFailAction, loadProfilesSuccessAction } from './profiles.actions';

function* loadProfilesEffect({
  payload,
}: PayloadAction<QueryData>): Generator<Promise<IDocs<ProfileDetails[]>> | PutEffect, void, IDocs<ProfileDetails[]>> {
  try {
    const { docs, total } = yield loadProfiles(payload);
    yield put(loadProfilesSuccessAction({ profiles: docs, total }));
  } catch (error) {
    yield put(loadProfilesFailAction(error as ErrorResponseData));
  }
}
function* loadProfilesFailEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  yield put(
    openSnackBarAction({
      message: error.status ? 'error.server' : 'error.network',
      severity: 'error',
    }),
  );
}

export function* watchProfiles() {
  yield all([takeLatest(loadProfilesAction.type, loadProfilesEffect), takeLatest(loadProfilesFailAction.type, loadProfilesFailEffect)]);
}
