export enum TaskTypes {
  story = 'story',
  task = 'task',
  epic = 'epic',
  sub_task = 'sub_task',
}
export enum TaskStatus {
  in_progress = 'in_progress',
  to_do = 'to_do',
  rejected = 'rejected',
  need_clarification = 'need_clarification',
  done = 'done',
  ready_for_test = 'ready_for_test',
  need_to_be_fixed = 'need_to_be_fixed',
}
