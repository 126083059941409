import { createTheme } from '@mui/material';
import { getPersistedTheme } from 'core/utils';
import { colors } from './shared-theme';

export const getInputTheme = () => {
  const theme = getPersistedTheme();
  const primaryColor = theme?.length ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme?.length ? theme[1] : colors.BLUE_DARK;

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: secondaryColor,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-adornedEnd': {
              paddingRight: 5,
            },
            'input[type=password]': {
              fontWeight: 'bold',
              letterSpacing: 6,
            },
            '&.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '&.chatbox_input': {
              fieldset: {
                border: 'none',
                color: 'black',
              },
            },
          },
        },
      },
    },
  });
};
