import Backlog from 'modules/Backlog/backlog.component';
import React, { useEffect } from 'react';

export const BacklogPage = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Backlog page';
  }, []);
  return <Backlog />;
};
