import * as Yup from 'yup';

const email = Yup.string().email('email_format').required('email_required');
const password = Yup.string().required('password_required');

export const validationSchema = Yup.object({
  email,
  password,
});
export const emailValidationSchema = Yup.object({
  email,
});

export const passwordValidationSchema = Yup.object({
  password,
});
