import { watchLogin } from 'modules/auth/state/auth.effects';
import { watchBacklog } from 'modules/Backlog/state/backlog.effects';
import { watchCalendar } from 'modules/Calendar/state/calendar.effects';
import { watchProfile } from 'modules/Profile/state/profile.effects';
import { watchProfiles } from 'modules/Profiles/state/profiles.effects';
import { watchProject } from 'modules/Project/state/project.effects';
import { watchProjects } from 'modules/ProjectsList/state/projects.effects';
import { watchRecommendation } from 'modules/Recommendation/state/recommendations.effects';
import { watchPassword } from 'modules/ResetPassword/state/password.effects';
import { watchRole } from 'modules/Role/state/role.effects';
import { watchTask } from 'modules/Task/state/task.effects';
import { watchUser } from 'modules/User/state/user.effects';
import { watchUsers } from 'modules/usersList/state/users.effects';
import { watchChatbox } from 'modules/Chat/state/chat.effects';
import { watchSocketConnection } from 'modules/Socket/state/socket.effects';
import { watchNotification } from 'modules/Notifications/state/notification.effects';
import { all, fork } from 'redux-saga/effects';

export function* rootSaga(): Generator {
  yield all([
    fork(watchLogin),
    fork(watchUsers),
    fork(watchProjects),
    fork(watchProject),
    fork(watchPassword),
    fork(watchProfile),
    fork(watchProfiles),
    fork(watchRole),
    fork(watchUser),
    fork(watchBacklog),
    fork(watchTask),
    fork(watchCalendar),
    fork(watchRecommendation),
    fork(watchChatbox),
    fork(watchSocketConnection),
    fork(watchNotification),
  ]);
}
