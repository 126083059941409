import { createAction } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ErrorResponseData, NotificationDetails, QueryData } from 'core/models';
import { PushNotificationDetails } from 'shared/interface';

export const createNotificationAction = createAction<NotificationDetails>('create notification');
export const createNotificationSuccessAction = createAction<NotificationDetails>('create notification success action');
export const createNotificationErrorAction = createAction<ErrorResponseData>('create failure notification');

export const getNotificationsAction = createAction<QueryData>('get notifications');
export const getNotificationsSuccessAction = createAction<IDocs<NotificationDetails[]>>('get notifications success');
export const getNotificationsErrorAction = createAction<ErrorResponseData>('get notifications failure');

export const markAsReadAction = createAction<string[]>('mark as read');
export const markAsReadSuccessAction = createAction<IDocs<NotificationDetails[]>>('mark as read success');
export const markAsReadErrorAction = createAction<ErrorResponseData>('mark as read failure');

export const ReceivedNotificationAction = createAction<NotificationDetails>('recieved notification');

export const createPushNotificationAction = createAction<PushNotificationDetails>('create push notification');
