import { ChatDto } from 'core/generated/ChatDTO';
import { I } from 'core/models';
import { MessageDetails } from '../message/message-details';
import { TeamMemberDetails } from '../teamMember/team-member-details';

export interface ChatDetails extends I {
  id: string;
  name: string;
  members: TeamMemberDetails[];
  messages: MessageDetails[];
  admin: TeamMemberDetails;
  notRead?: boolean;
}
export namespace ChatDetails {
  export const mapFromApiValue = (chat: ChatDto): ChatDetails => {
    const chatDetails = {} as ChatDetails;
    Object.keys(chat).forEach((key) => {
      if (chat[key]) {
        if (key === '_id') {
          chatDetails['id'] = chat['_id'];
        } else if (key === 'admin') {
          chatDetails['admin'] = TeamMemberDetails.mapFromApiValue(chat['admin']);
        } else if (key === 'members') {
          chatDetails['members'] = chat['members'].map((member) => TeamMemberDetails.mapFromApiValue(member));
        } else if (key === 'messages') {
          chatDetails['messages'] = chat['messages'].map((message) => MessageDetails.mapFromApiValue(message));
        } else {
          chatDetails[key] = chat[key];
        }
      }
    });
    return chatDetails;
  };
  export const mapToApiValue = (chatDetails: ChatDetails): ChatDto => {
    const chatDto = {} as ChatDto;
    Object.keys(chatDetails).forEach((key) => {
      if (chatDetails[key]) {
        if (key === 'id') {
          chatDto['_id'] = chatDetails['id'];
        } else if (key === 'admin') {
          chatDto['admin'] = TeamMemberDetails.mapToApiValue(chatDetails['admin']);
        } else if (key === 'members') {
          chatDto['members'] = chatDetails['members'].map((member) => TeamMemberDetails.mapToApiValue(member));
        } else if (key === 'messages') {
          chatDto['messages'] = chatDetails['messages'].map((message) => MessageDetails.mapToApiValue(message));
        } else {
          chatDto[key] = chatDetails[key];
        }
      }
    });
    return chatDto;
  };
}
