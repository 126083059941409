import { PayloadAction } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ErrorResponseData, NotificationDetails, QueryData } from 'core/models';
import { createNotification, getNotifications, markAsRead, sendNofitication } from 'core/services/notification.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeEvery, SelectEffect, select, takeLatest } from 'redux-saga/effects';
import {
  getNotificationsAction,
  markAsReadSuccessAction,
  getNotificationsErrorAction,
  getNotificationsSuccessAction,
  markAsReadErrorAction,
  markAsReadAction,
  createNotificationSuccessAction,
  createNotificationErrorAction,
  createNotificationAction,
  createPushNotificationAction,
} from './notification.actions';
import { AppState } from 'store';
import { PushNotificationDetails } from 'shared/interface';
import { t } from 'i18next';

function* getNotificationsEffect({
  payload: query,
}: PayloadAction<QueryData>): Generator<Promise<IDocs<NotificationDetails[]>> | PutEffect, void, IDocs<NotificationDetails[]>> {
  try {
    const data = yield getNotifications(query);
    yield put(getNotificationsSuccessAction(data));
  } catch (error) {
    yield put(getNotificationsErrorAction(error as ErrorResponseData));
  }
}

function* markAsReadEffect({
  payload: ids,
}: PayloadAction<string[]>): Generator<
  Promise<IDocs<NotificationDetails[]>> | PutEffect | SelectEffect,
  void,
  IDocs<NotificationDetails[]> | number
> {
  try {
    const limit = yield select((state: AppState) => state.notifications.limit);
    const data = yield markAsRead({ body: ids, queryParams: { limit: limit as number } });
    yield put(markAsReadSuccessAction(data as IDocs<NotificationDetails[]>));
  } catch (error) {
    yield put(markAsReadErrorAction(error as ErrorResponseData));
  }
}

function* createNotificationEffect({
  payload: notification,
}: PayloadAction<NotificationDetails>): Generator<Promise<NotificationDetails> | PutEffect | SelectEffect, void, NotificationDetails | string> {
  try {
    const id = yield select((state: AppState) => state.auth.user.id);
    if (notification.task.assignee.id !== id) {
      const data = yield createNotification({ body: NotificationDetails.mapToApiValue(notification) });
      yield put(
        createPushNotificationAction({
          title: notification.task.key,
          message: notification.task.reporter.firstName + ' ' + t('notification.' + notification.content),
          recieverId: notification.task.assignee.id,
        }),
      );
      yield put(createNotificationSuccessAction(data as NotificationDetails));
    }
  } catch (error) {
    yield put(createNotificationErrorAction(error as ErrorResponseData));
  }
}

function* createPushNotificationEffect({ payload: notification }: PayloadAction<PushNotificationDetails>): Generator {
  try {
    yield sendNofitication(notification);
  } catch (error) {
    yield put(createNotificationErrorAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  console.log(error.message);
  yield put(openSnackBarAction({ message: error.status ? 'error.server' : 'error.network', severity: 'error' }));
}

export function* watchNotification() {
  yield all([
    takeLatest(getNotificationsAction.type, getNotificationsEffect),
    takeEvery(createNotificationAction.type, createNotificationEffect),
    takeEvery(markAsReadAction.type, markAsReadEffect),
    takeEvery(createPushNotificationAction.type, createPushNotificationEffect),
    takeEvery(getNotificationsErrorAction.type, failEffect),
  ]);
}
