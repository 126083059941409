import { createAction } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ErrorResponseData, ProfileDetails } from 'core/models';

export const addRoleAction = createAction<ProfileDetails>('add role');
export const addRoleSuccessAction = createAction<ProfileDetails>('add role success');
export const addRoleFailureAction = createAction<ErrorResponseData>('add role failure');

export const updateRoleAction = createAction<ProfileDetails>('update role');
export const updateRoleSuccessAction = createAction<ProfileDetails>('update role success');

export const deleteRoleAction = createAction<string>('delete role');
export const deleteRoleSuccessAction = createAction<IDocs<ProfileDetails[]>>('delete role success');
export const deleteRoleFailAction = createAction<ErrorResponseData>('delete role fail');
