import React, { useEffect, useState } from 'react';
import { Button, Container, LinearProgress, Paper, Stack, ThemeProvider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add as AddIcon } from '@mui/icons-material/';
import { Alert, HistoryList } from 'shared/components';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { loadBacklogAction } from './state/backlog.actions';
import { useParams } from 'react-router-dom';
import { getBacklogTheme } from 'shared/themes';
import { SprintEdit } from './components/edit-sprint.component';
// import Task from 'modules/Task/task.component';
import { ProjectDetails } from 'core/models';
import { loadProject } from 'core/services/project.service';
import Sprint from 'modules/Sprint/sprint.component';
import { routes } from 'core/enums';

const Backlog: React.FC = () => {
  const { t } = useTranslation();
  const {
    auth: {
      user: { theme },
    },
    backlog: { loading, sprints, theSprintBeingCreated },
  } = useSelector((state: AppState) => state);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [creatingSprint, setCreatingSprint] = useState(false);
  // const [creatingTask, setCreatingTask] = useState(false);
  const [currentProject, setCurrentProject] = useState({} as ProjectDetails);

  useEffect(() => {
    (async () => {
      if (id) {
        const project = await loadProject(id);
        if (project) {
          setCurrentProject(project);
          dispatch(loadBacklogAction({ projectId: id }));
        }
      }
    })();
  }, []);

  // const pageTitle = t('project.projects') + ' / ' + currentProject?.title;
  const historyList = [
    {
      title: t('project.projects'),
      path: routes.projects,
    },
    {
      title: currentProject?.title,
    },
    {
      title: t('project.backlog'),
    },
  ];

  return loading ? (
    <LinearProgress />
  ) : (
    <ThemeProvider theme={getBacklogTheme(theme)}>
      <Container>
        <Stack>
          <Stack>
            <HistoryList list={historyList} />
            <Typography variant="h4" gutterBottom>
              {t('project.backlog')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6" gutterBottom>
              {t('project.backlog')}
            </Typography>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setCreatingSprint(true)}>
              {t('project.create_sprint')}
            </Button>
          </Stack>
          <Paper className="sprints_container">
            {sprints.map((sprint, i) => (
              <Sprint sprint={sprint} key={i} project={currentProject} />
            ))}
          </Paper>
          {/* <Button variant="outlined" onClick={() => setCreatingTask(true)} startIcon={<AddIcon />} className="create_issue_btn">
            {t('project.create_issue')}
          </Button> */}
        </Stack>
        <Alert
          open={creatingSprint || !!theSprintBeingCreated}
          handleOpen={() => {
            setCreatingSprint(!creatingSprint);
          }}
          hasAction={false}
          body={
            <SprintEdit
              handleClose={() => {
                setCreatingSprint(false);
              }}
            />
          }
        />
        {/* <Alert
          open={creatingTask}
          handleOpen={() => {
            setCreatingTask(!creatingTask);
          }}
          hasAction={false}
          body={
            <Task
              project={currentProject}
              handleClose={() => {
                setCreatingTask((p) => !p);
              }}
            />
          }
        /> */}
      </Container>
    </ThemeProvider>
  );
};

export default React.memo(Backlog);
