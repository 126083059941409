import { Grid, IconButton, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, CustomAutoComplete, CustomTable } from 'shared/components';
import { AppState } from 'store';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/enums/routes';
import { navigate } from 'core/services/history.service';
import { QueryData } from 'core/models';
import { Permissions, profileStatus, resources } from 'core/enums';
import { loadProfilesAction } from './state/profiles.actions';
import { deleteRoleAction } from 'modules/Role/state/role.actions';
import { isPermitted } from 'core/utils';

const ProfilesList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: AppState) => state.auth);
  const { profiles, loading, total, deleting } = useSelector((state: AppState) => state.profiles);
  const [searchData, setsearchData] = useState<QueryData>({ page: 0, limit: 5 });
  const [openAlert, setOpenAlert] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState('');
  const filterOptions = [profileStatus.ACTIVE, profileStatus.DISABLED];
  // loads profiles from the database based on search data (search query)
  const loadProfiles = (query?: QueryData) => {
    dispatch(loadProfilesAction(query));
  };
  // reloads profiles from the database whenever search data changes
  useEffect(() => {
    loadProfiles(searchData);
  }, [searchData]);

  const handleTableChange = (tableOptions: QueryData) => {
    setsearchData({ ...tableOptions });
  };

  const filterProfiles = (value: string) => {
    setsearchData({ ...searchData, status: value });
  };

  // creates table labels
  const getLabels = () => {
    const labels = [
      t('profiles.title'),
      t('profiles.description'),
      <>
        <CustomAutoComplete
          value={searchData?.status as string}
          handleChange={filterProfiles}
          id="type-select"
          label={t('profiles.status')}
          labels={filterOptions}
          inTableCell={true}
        />
      </>,
    ];
    if (isPermitted(user, resources.PROFILES, Permissions.delete) || isPermitted(user, resources.PROFILES, Permissions.edit)) {
      labels.push(t('common.actions'));
    }
    return labels;
  };
  // maps profiles data from global state to the data that will be displayed
  const getData = () =>
    profiles.map(({ id, title, description, status }) => ({
      title,
      description,
      status,
      actions: (
        <>
          {isPermitted(user, resources.PROFILES, Permissions.edit) && (
            <IconButton onClick={() => navigate(routes.role, id)}>
              <EditIcon className="edit_icon" />
            </IconButton>
          )}
          {isPermitted(user, resources.PROFILES, Permissions.delete) && (
            <IconButton
              onClick={() => {
                setToBeDeletedId(id);
                setOpenAlert(true);
              }}
            >
              <DeleteIcon className="delete_icon" />
            </IconButton>
          )}
        </>
      ),
    }));
  const handleDelete = () => {
    dispatch(deleteRoleAction(toBeDeletedId));
  };
  return (
    <Grid container paddingTop={5}>
      <Typography paddingLeft="70px" variant="h4">
        {t('profiles.list')}
      </Typography>
      <CustomTable
        headBtn={
          isPermitted(user, resources.PROFILES, Permissions.edit) && (
            <Button variant="contained" onClick={() => navigate(routes.role)}>
              {t('profile.' + 'add_profile')}
            </Button>
          )
        }
        loading={loading}
        data={getData()}
        labels={getLabels()}
        tableOptions={searchData}
        handleChange={handleTableChange}
        total={total}
      />
      <Alert
        open={openAlert}
        saving={deleting}
        handleOpen={() => {
          setOpenAlert(!openAlert);
        }}
        message={t('profiles.confirm_delete')}
        action={handleDelete}
      />
    </Grid>
  );
};

export default React.memo(ProfilesList);
