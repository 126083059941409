import { localStorageKeys } from 'core/enums/local-storage-keys';
import { routes } from 'core/enums/routes';
import { history } from 'core/services/history.service';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadUser, logoutAction } from 'modules/auth/state/auth.actions';
import { decrypt } from 'core/utils';
import jwtDecode from 'jwt-decode';
import { checkToken } from 'core/services/auth.service';
import { LinearProgress } from '@mui/material';
import { recoverPwSuccessAction } from 'modules/ResetPassword/state/password.actions';

interface GuardRouterProps {
  children: React.ReactNode;
}
interface Token {
  email: string;
  exp: number;
  iat: number;
  id: string;
  role: string;
}

const GuardRouter = ({ children }: GuardRouterProps) => {
  const dispatch = useDispatch();
  const [canAccess, setCanAccess] = useState(false);
  const { pathname } = history.location;

  useEffect(() => {
    (async () => {
      try {
        // gets encrypted auth token from localStorage if exists
        const encryptedToken = localStorage.getItem(localStorageKeys.token) || '';
        if (!encryptedToken) {
          throw new Error();
        }
        // decrypt the auth token
        const encodedToken = decrypt(encryptedToken);
        // decode auth token
        const token: Token = jwtDecode(encodedToken);
        // check if the token is valid
        const Expired = token?.exp * 1000 < new Date().getTime();
        if (Expired) throw new Error();
        // fetches user data from database based on the token
        const user = await checkToken(token.id);
        // set user + auth token to global state
        dispatch(loadUser({ ...user, token: encodedToken }));

        // pushes user to the home page
        if (pathname === routes.login) {
          history.push(routes.profile);
        }
      } catch (error) {
        // dispatches logout action in case of error (user not logged in)
        dispatch(logoutAction());

        // grabs password reset token's content and expiration date
        const resetPasswordExpires = localStorage.getItem(localStorageKeys.pw_expires) || '';
        const resetPasswordToken = localStorage.getItem(localStorageKeys.pw_token) || '';
        // sets the previous data to global state
        dispatch(recoverPwSuccessAction({ resetPasswordExpires, resetPasswordToken }));
        // creates a route for user in case of password reset request
        const pwResetLink = routes.reset_password + '/' + resetPasswordToken;
        // does a path check to redirect user in case user is not authenticated
        if (pathname !== routes.login && pathname !== routes.recover_password && pathname !== pwResetLink) {
          // redirect user to login page in case of asking for a page that is only accessible if user is logged in
          localStorage.clear();
          history.push(routes.login);
        }
      }
      // grants access to user after all the previous logic is executed
      setCanAccess(true);
    })();
  }, []);

  return canAccess ? <>{children}</> : <LinearProgress />;
};

export default GuardRouter;
