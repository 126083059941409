import axios, { AxiosResponse } from 'axios';
import { localStorageKeys } from 'core/enums/local-storage-keys';
import { ErrorResponseData, HttpParamsType, RequestOptions } from 'core/models';
import { decrypt } from 'core/utils';
import { ResponseTypes } from './response-types';

export const getToken = () => {
  try {
    const decoded = localStorage.getItem(localStorageKeys.token) || '';
    const auth_token = decrypt(decoded);
    return auth_token;
  } catch (e) {
    const error = e as ErrorResponseData;
    console.log(error.message);
  }
};

const baseHttpService = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const post = async <T>(url: string, paramsData?: HttpParamsType): Promise<T> => {
  try {
    const auth_token = getToken();
    let headers = {};
    if (auth_token) {
      headers = { authentication: auth_token };
    }
    const options: RequestOptions = {
      params: paramsData && paramsData.queryParams,
      body: paramsData && paramsData.body,
      headers,
    };
    const { data } = await baseHttpService.post<T>(url, options.body, {
      params: options && options.params,
      headers: options && options.headers,
    });
    return data;
  } catch (err) {
    throw ErrorResponseData.mapFromApiValue(err as AxiosResponse);
  }
};

export const get = async <T>(url: string, paramsData?: HttpParamsType): Promise<T> => {
  try {
    const auth_token = getToken();
    let headers = {};
    if (auth_token) {
      headers = { authentication: auth_token };
    }
    const { data } = await baseHttpService.get<T>(url, {
      params: paramsData && paramsData.queryParams,
      headers,
    });
    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApiValue(error as AxiosResponse);
  }
};

export const put = async <T>(url: string, paramsData?: HttpParamsType): Promise<T> => {
  try {
    const auth_token = getToken();
    let headers = {};
    if (auth_token) {
      headers = { authentication: auth_token };
    }
    const options: RequestOptions = {
      params: paramsData && paramsData.queryParams,
      body: paramsData && paramsData.body,
      headers,
    };
    const { data } = await baseHttpService.put<T>(url, options.body, {
      params: options && options.params,
      headers: options && options.headers,
    });
    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApiValue(error as AxiosResponse);
  }
};

export const _delete = async <T>(url: string, paramsData?: HttpParamsType): Promise<T> => {
  try {
    const auth_token = getToken();
    let headers = {};
    if (auth_token) {
      headers = { authentication: auth_token };
    }
    const options: RequestOptions = {
      params: paramsData && paramsData.queryParams,
      body: paramsData && paramsData.body,
      headers,
    };

    const { data } = await baseHttpService.delete(url, {
      params: options && options.params,
      headers: options && options.headers,
    });

    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApiValue(error as AxiosResponse);
  }
};

export const download = async (url: string, paramsData?: HttpParamsType) => {
  try {
    const auth_token = getToken();
    let headers = {};
    if (auth_token) {
      headers = { authentication: auth_token };
    }
    const data = await baseHttpService.get(url, {
      params: paramsData && paramsData.queryParams,
      headers,
      responseType: ResponseTypes.Blob,
    });
    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApiValue(error as AxiosResponse);
  }
};
