import { PayloadAction } from '@reduxjs/toolkit';
import { rolesError } from 'core/enums';
import { routes } from 'core/enums/routes';
import { IDocs } from 'core/generated/fetchedData';
import { ErrorResponseData, ProfileDetails } from 'core/models';
import { history } from 'core/services/history.service';
import { addRole, deleteRole, updateRole } from 'core/services/role.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  addRoleAction,
  addRoleSuccessAction,
  deleteRoleAction,
  deleteRoleSuccessAction,
  updateRoleAction,
  updateRoleSuccessAction,
} from './role.actions';

function* addRoleEffect({ payload }: PayloadAction<ProfileDetails>): Generator<Promise<ProfileDetails> | PutEffect, void, ProfileDetails> {
  try {
    const newProfile = yield addRole({ body: ProfileDetails.mapToApiValue(payload) });
    yield put(addRoleSuccessAction(newProfile));
    yield put(openSnackBarAction({ message: 'profiles.role_added', severity: 'success' }));
  } catch (err) {
    const error = err as ErrorResponseData;
    let message: string;
    if (error.message === rolesError.SUPER_ADMIN_CREATION_ATTEMPT) {
      message = 'error.' + rolesError.SUPER_ADMIN_CREATION_ATTEMPT;
    } else {
      message = error.status ? 'error.server' : 'error.network';
    }
    yield put(openSnackBarAction({ message, severity: 'error' }));
  }
}

function* updateRoleEffect({ payload }: PayloadAction<ProfileDetails>): Generator<Promise<ProfileDetails> | PutEffect, void, ProfileDetails> {
  try {
    const newProfile = yield updateRole({ body: ProfileDetails.mapToApiValue(payload) });
    yield put(updateRoleSuccessAction(newProfile));
  } catch (err) {
    const error = err as ErrorResponseData;
    const msg = error.status ? 'error.server' : 'error.network';
    yield put(openSnackBarAction({ message: msg, severity: 'error' }));
  }
}

function* updateRolesSuccessEffect() {
  yield put(openSnackBarAction({ message: 'profiles.roles_updated', severity: 'success' }));
  history.push(routes.profiles);
}

function* deleteRoleEffect({
  payload,
}: PayloadAction<string>): Generator<Promise<IDocs<ProfileDetails[]>> | PutEffect, void, IDocs<ProfileDetails[]>> {
  try {
    const data = yield deleteRole(payload);
    yield put(deleteRoleSuccessAction(data));
  } catch (err) {
    const error = err as ErrorResponseData;
    const msg = error.status ? 'error.server' : 'error.network';
    yield put(openSnackBarAction({ message: msg, severity: 'error' }));
  }
}

export function* watchRole(): Generator {
  yield all([
    takeLatest(addRoleAction.type, addRoleEffect),
    takeLatest(updateRoleAction.type, updateRoleEffect),
    takeLatest(addRoleSuccessAction.type, updateRolesSuccessEffect),
    takeLatest(updateRoleSuccessAction.type, updateRolesSuccessEffect),
    takeLatest(deleteRoleAction.type, deleteRoleEffect),
  ]);
}
