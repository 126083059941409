export const error = {
  network: 'Check Your Connection and Try Again',
  server: 'A server error has occurred, please try again later',
  email_required: 'The E-mail is required',
  email_format: 'Please enter a correct E-mail',
  password_required: 'The password is required',
  loginFailed: 'Invalid email or password',
  invalid_token: 'Please Login',
  reset_token_invalid: 'Url is expired',
  sprint_period_intersect: 'Sprints must not share the same time period ',
  duplicated_model: '2 projects connot have the same title',
  super_admin_creation_attempt: 'There can only be one super admin!',
  disabled_account: 'Your account is disabled',
};
