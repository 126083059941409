import React from 'react';
import { FormContainer, ItemWrapper } from '../../shared/components';
import { validationSchema } from 'shared/validator';
import { FormikValues } from '../../shared/interface';
import { useDispatch } from 'react-redux';
import { loginAction } from './state/auth.actions';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { routes } from 'core/enums/routes';
import { useTranslation } from 'react-i18next';
import styles from './auth.module.scss';

const initialValues: FormikValues = {
  email: '',
  password: '',
};

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // dispatches login action with the given values
  const submitHandler = ({ email, password }: FormikValues) => {
    dispatch(loginAction({ email, password }));
  };

  return (
    <FormContainer
      initialValues={initialValues}
      validationSchema={validationSchema}
      submitType={'submit'}
      submitHandler={submitHandler}
      footerElement={
        <ItemWrapper>
          <Typography variant="subtitle2">
            {t('common.reset_password')}
            <Link to={routes.recover_password} className={styles.link}>
              {t('common.here')}
            </Link>
          </Typography>
        </ItemWrapper>
      }
    />
  );
};

export default Login;
