import React from 'react';
import { Autocomplete, Box, TextField, ThemeProvider } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { autoCompleteTheme } from 'shared/themes';

interface Props {
  id: string;
  label: string;
  labels: string[];
  handleChange: (value: string) => void;
  value?: string;
  inTableCell?: boolean;
}

const MuiAutoComplete: React.FC<Props> = ({ id, labels = [''], label, handleChange = () => {}, value = '', inTableCell = false }) => {
  labels.push('');
  return (
    <ThemeProvider theme={inTableCell ? autoCompleteTheme : {}}>
      <Autocomplete
        id={id}
        clearIcon={<ClearIcon sx={inTableCell ? { color: 'white' } : {}} />}
        options={labels}
        autoHighlight
        onChange={(_, v) => handleChange(v ?? '')}
        value={value}
        renderOption={(props, option) =>
          option ? (
            <Box component="li" {...props}>
              {option}
            </Box>
          ) : null
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth
              label={label}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};
const CustomAutoComplete = React.memo(MuiAutoComplete);
export { CustomAutoComplete };
