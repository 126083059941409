import CryptoJS from 'crypto-js';
import validateColor from 'validate-color';
import { localStorageKeys } from 'core/enums/local-storage-keys';
import { TeamMemberDetails, UserDetails } from 'core/models';
import { IRoute } from 'shared/interface';
import { routes } from 'core/enums/routes';
import { Permissions, resources, TaskStatus } from 'core/enums';
import { SectionDto } from 'core/generated/SectionDto';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortDataByField = (toBeSortedData: any[], field: string, up: boolean) => {
  const auxData = [...toBeSortedData];
  auxData.sort((a, b) => {
    if (a[field] === b[field]) return 0;
    const result = a[field] < b[field] ? -1 : 1;
    const res = up ? result : result * -1;
    return res;
  });
  return auxData;
};

export const encrypt = (data: string): string => {
  const encodedData = CryptoJS.AES.encrypt(data, process.env.REACT_APP_HASH_SECRET || '');
  return encodedData.toString();
};

export const decrypt = (data: string): string => {
  const encodedData = CryptoJS.AES.decrypt(data, process.env.REACT_APP_HASH_SECRET || '');
  return encodedData.toString(CryptoJS.enc.Utf8);
};
export const getPersistedTheme = () => {
  let theme;
  try {
    const stringifiedTheme = localStorage.getItem(localStorageKeys.theme);
    if (stringifiedTheme) {
      theme = JSON.parse(stringifiedTheme);
      const [color1, color2] = theme;
      if (!validateColor(color1) || !validateColor(color2)) {
        throw new Error();
      }
      return theme;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log(error.message);
  }
  return theme;
};

export const getUserRoutes = (user: UserDetails, allPages: IRoute[]) => {
  const userSections: string[] = user?.profile?.sections?.map(({ value }: { value: string }) => value);
  return allPages.filter(({ route }) => {
    if (route === routes.profile || route.includes(routes.chatbox)) {
      return true;
    }
    const found = userSections?.find((item: string) => {
      if (item === resources.PROFILES) {
        return route.includes('role') || route.includes(resources.PROFILES);
      } else if (item === resources.PROJECTS) {
        return route.includes('project') || route.includes('backlog') || route.includes('planning');
      } else {
        return route.includes(item);
      }
    });
    return !!found;
  });
};

export const isPermitted = (user: UserDetails, resource: string, permission: number) => {
  const sections: SectionDto[] = user.profile.sections;
  const section = sections.find(({ value }: SectionDto) => value === resource);
  if (!section) {
    return false;
  }
  if (section.access === Permissions.all) {
    return true;
  }
  if (permission === Permissions.edit) {
    return section.access === Permissions.edit;
  } else if (permission === Permissions.delete) {
    return section.access === Permissions.delete;
  }
  return false;
};

export const validateDuration = (duration: string): boolean => /^([0-4]w)?([0-6]d)?((([0-2][0-3])|([0-9]))h)?([0-5]?[0-9]m)?$/.test(duration);

export const taskStatusGenerator = (status: string) => {
  switch (status) {
    case TaskStatus.to_do:
      return [TaskStatus.need_clarification, TaskStatus.rejected, TaskStatus.in_progress];
    case TaskStatus.in_progress:
      return [TaskStatus.need_clarification, TaskStatus.rejected, TaskStatus.ready_for_test];
    case TaskStatus.need_clarification:
      return [TaskStatus.rejected, TaskStatus.to_do];
    case TaskStatus.rejected:
      return [TaskStatus.need_clarification];
    case TaskStatus.ready_for_test:
      return [TaskStatus.need_clarification, TaskStatus.need_to_be_fixed, TaskStatus.rejected, TaskStatus.done];
    case TaskStatus.done:
      return [TaskStatus.need_clarification, TaskStatus.rejected];
    default:
      return [];
  }
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}
export const durationToMinutes = (duration: string): number => {
  duration = duration.toUpperCase();
  const dayIndex = duration.indexOf('D');
  duration = dayIndex === -1 ? 'PT' + duration : 'P' + duration.slice(0, dayIndex + 1) + 'T' + duration.slice(dayIndex + 1);
  return moment.duration(duration).asMinutes();
};
export function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line no-useless-escape
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const handleMembersSelect = (participants: TeamMemberDetails[]) => {
  const lastAdded = participants.pop();
  // see if the last added participant is already in the list
  if (lastAdded) {
    const duplicate = participants.find((participant) => participant.id === lastAdded.id);
    // if the removed participant is a duplicate return the new list
    if (duplicate) {
      participants = participants.filter((participant) => participant.id !== duplicate.id);
      return participants;
    }
    return [...participants, lastAdded];
  } else {
    return [];
  }
};
