import { Button, ButtonGroup, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderButton } from '../UI/LoaderButton/button.component';

interface Props {
  handleCancel?: () => void;
  handleSubmit?: () => void;
  saveIsDisabled?: boolean;
  submit?: boolean;
  saving?: boolean;
  save?: boolean;
}

export const MuiBtnGrp: React.FC<Props> = ({
  save = false,
  saving = false,
  submit = false,
  handleSubmit = () => {},
  handleCancel = () => {},
  saveIsDisabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="flex-end" padding={2}>
      <ButtonGroup>
        {submit ? (
          <Button
            sx={save ? {} : { backgroundColor: 'red' }}
            endIcon={saving ? <LoaderButton /> : null}
            variant="contained"
            type="submit"
            disabled={saveIsDisabled || saving}
            onSubmit={() => {
              handleSubmit();
            }}
          >
            {t(`common.${save ? 'save' : 'delete'}`)}
          </Button>
        ) : (
          <Button
            sx={save ? {} : { backgroundColor: 'red' }}
            variant="contained"
            endIcon={saving ? <LoaderButton /> : null}
            disabled={saveIsDisabled}
            onClick={() => {
              handleSubmit();
            }}
          >
            {t(`common.${save ? 'save' : 'delete'}`)}
          </Button>
        )}

        <Button variant="contained" onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
