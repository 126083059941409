import { Box, Button, Card, Grid, ThemeProvider, Container } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'shared/interface';
import { ItemWrapper } from '../ItemWrapper/item-wrapper';
import logo from '../../../assets/static/pfe-logo.png';
import { getFormTheme } from 'shared/themes';
import { InputElement } from '../UI/InputElement/input.component';

interface Props {
  initialValues: FormikValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: any;
  submitHandler: (data: FormikValues) => void;
  submitType: string;
  footerElement?: JSX.Element;
  headerElement?: JSX.Element;
}

export const FormContainer: React.FC<Props> = ({
  initialValues,
  validationSchema,
  submitHandler,
  submitType,
  footerElement = null,
  headerElement = null,
}) => {
  const { values, touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    onSubmit: async (data) => submitHandler(data),
  });
  const fields = Object.keys(values);

  const { t } = useTranslation();
  return (
    <ThemeProvider theme={getFormTheme()}>
      <Container>
        <Grid item xs={11} sm={6} lg={4}>
          <Card>
            {headerElement}
            <Grid className="form" spacing={5} container component="form" onSubmit={handleSubmit}>
              <ItemWrapper>
                <Box component="img" src={logo} maxHeight={100} />
              </ItemWrapper>
              {fields.map((field, key) => (
                <ItemWrapper key={`field-${key}`}>
                  <InputElement
                    name={field}
                    label={t('login.' + field)}
                    type={field}
                    value={values[field]}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors[field]}
                    touched={touched[field]}
                  />
                </ItemWrapper>
              ))}
              <ItemWrapper>
                <Button disabled={isSubmitting} className="submit" type="submit" variant="contained" fullWidth>
                  {t('login.' + submitType)}
                </Button>
              </ItemWrapper>
              {footerElement}
            </Grid>
          </Card>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
