import { createTheme } from '@mui/material';
import { getPersistedTheme } from 'core/utils';
import { colors } from './shared-theme';

export const getNavbarTheme = (defaultTheme?: string[]) => {
  const theme = defaultTheme && defaultTheme.length ? defaultTheme : getPersistedTheme();

  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: { main: secondaryColor },
    },
    components: {
      MuiList: {
        styleOverrides: {
          root: {
            '&.notification_list': {
              minWidth: '450px',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.notification_time': {
              marginLeft: '5px',
            },
            '&.more_notifications_btn': {
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.notification_not_red_icon': {
              color: secondaryColor,
            },
          },
        },
      },
    },
  });
};
