import { authReducer } from 'modules/auth/state/auth.reducer';
import { backlogReducer } from 'modules/Backlog/state/backlog.reducer';
import { calendarReducer } from 'modules/Calendar/state/calendar.reducer';
import { chatboxReducer } from 'modules/Chat/state/chat.reducer';
import { notificationReducer } from 'modules/Notifications/state/notification.reducer';
import { profilesReducer } from 'modules/Profiles/state/profiles.reducer';
import { projectsReducer } from 'modules/ProjectsList/state/projects.reducer';
import { recommendationReducer } from 'modules/Recommendation/state/recommendations.reducer';
import { passwordReducer } from 'modules/ResetPassword/state/password.reducer';
import { snackbarReducer } from 'modules/snackbar/store/snackbar.reducer';
import { socketReducer } from 'modules/Socket/state/socket.reducer';
import { usersReducer } from 'modules/usersList/state/users.reducer';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  users: usersReducer,
  projects: projectsReducer,
  passwordReset: passwordReducer,
  profiles: profilesReducer,
  backlog: backlogReducer,
  calendar: calendarReducer,
  recommendations: recommendationReducer,
  chatbox: chatboxReducer,
  socket: socketReducer,
  notifications: notificationReducer,
});
