import React, { useState } from 'react';
import { Alert, Input } from 'shared/components';
import { AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Comment as CommentIcon } from '@mui/icons-material/';
import { Fab, Grid, Typography } from '@mui/material';
import { RecommendationDetails } from 'core/models';
import { useTranslation } from 'react-i18next';
import { createRecommendationAction } from './state/recommendation.actions';

const AddRecommendation = () => {
  const { t } = useTranslation();
  const { addingRecommendation } = useSelector((state: AppState) => state.recommendations);
  const [open, setOpen] = useState(false);
  const [recommendation, setRecommendation] = useState({} as RecommendationDetails);
  const dispatch = useDispatch();
  const label = t('recommendation.create_recommendation');

  return (
    <>
      <Alert
        save={true}
        saveIsDisabled={!recommendation.description}
        saving={addingRecommendation}
        open={open || addingRecommendation}
        handleOpen={() => {
          setOpen(!open);
        }}
        action={() => {
          dispatch(createRecommendationAction(recommendation));
        }}
        body={
          <Grid width="35vw" padding="5px 10px">
            <Typography padding="5px" gutterBottom variant="h6" align="center">
              {label}
            </Typography>
            <Input
              multiline={true}
              name={'description'}
              placeholder={t('recommendation.placeholder')}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecommendation({ ...recommendation, description: e.target.value })}
            />
          </Grid>
        }
      />
      <Fab className="recommendation_fab" color="primary" onClick={() => setOpen(true)}>
        <CommentIcon />
      </Fab>
    </>
  );
};

export default React.memo(AddRecommendation);
