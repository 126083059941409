import { RecommendationDto } from 'core/generated/Recommendation';
import { HttpParamsType, RecommendationDetails } from 'core/models';
import { apiUrlMatcher, ApiUrls, get, post, _delete } from './helpers';

export const createRecommendation = async (params: HttpParamsType<RecommendationDto>): Promise<RecommendationDetails[]> => {
  const data = await post<RecommendationDto[]>(apiUrlMatcher(ApiUrls.createRecommendation), params);
  return data.map((recommendation) => RecommendationDetails.mapFromApiValue(recommendation));
};

export const getRecommendations = async (): Promise<RecommendationDetails[]> => {
  const data = await get<RecommendationDto[]>(apiUrlMatcher(ApiUrls.getRecommendations));
  return data.map((recommendation) => RecommendationDetails.mapFromApiValue(recommendation));
};

export const removeRecommendation = async (id: string) => {
  const data = await _delete<RecommendationDto[]>(apiUrlMatcher(ApiUrls.removeRecommendation) + '/' + id);
  return data.map((recommendation) => RecommendationDetails.mapFromApiValue(recommendation));
};
