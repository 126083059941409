import ProfilesList from 'modules/Profiles/profiles.component';
import React, { useEffect } from 'react';

export const ProfilesPage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Roles managing page';
  }, []);
  return <ProfilesList />;
};
