import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData, TaskDetails } from 'core/models';

export const createTaskAction = createAction<TaskDetails>('create task');
export const createTaskSuccessAction = createAction<TaskDetails>('create task success');
export const createTaskFailureAction = createAction<ErrorResponseData>('create task failure');

export const updateTaskAction = createAction<TaskDetails>('update task');
export const updateTaskSuccessAction = createAction<TaskDetails>('update task success');
export const updateTaskFailureAction = createAction<ErrorResponseData>('update task failure');
