import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AppBar,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import Message from './message.component';
import { Info as InfoIcon, TagFaces as TagFacesIcon, Send as SendIcon } from '@mui/icons-material/';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { emptyMessages, getMessagesAction, sendMessageAction } from '../state/chat.actions';
import { MessageDetails } from 'core/models';
import { Alert, AvatarIcon, FileComponent, Input } from 'shared/components';
import { FileUpload } from 'shared/components/UI/FileUpload/file-upload.component';
import { useTranslation } from 'react-i18next';
import Picker from 'emoji-picker-react';
import { UsersList } from './chat-members.component';

const MessagesList: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const {
    chatbox: {
      messages: { docs, limit, total },
      messagesAreLoading,
      chatbox,
    },
  } = useSelector((state: AppState) => state);
  const [messageToSend, setMessageToSend] = useState<MessageDetails>({ chat: id } as MessageDetails);
  const [atTop, setAtTop] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [showChatDetails, setShowChatDetails] = useState<boolean>(false);
  const disableSend = !messageToSend.text && !messageToSend.files;
  const currentChat = chatbox.find((chat) => chat.id === id);

  useEffect(() => {
    id ? dispatch(getMessagesAction({ chatId: id, limit })) : dispatch(emptyMessages());
    // initiating the message on current chat id
    setMessageToSend({ chat: id } as MessageDetails);
  }, [id]);

  useEffect(() => {
    if (!atTop) {
      scrollRef.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }, [docs?.length]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const { scrollTop } = e.currentTarget;
    if (!scrollTop && id && total !== docs?.length) {
      setAtTop(true);
      dispatch(getMessagesAction({ chatId: id, limit: limit + 10 }));
    }
  };

  const handleSendMessage = useCallback(() => {
    dispatch(sendMessageAction(messageToSend));
    setMessageToSend({ chat: id } as MessageDetails);
    setAtTop(false);
    setShowEmojiPicker(false);
  }, [messageToSend]);

  const removeFile = (name: string) => {
    const oldFiles = messageToSend.files as File[];
    const newFiles = oldFiles.filter((file) => file.name !== name);
    setMessageToSend({ ...messageToSend, files: newFiles });
  };

  const onEmojiClick = (emoji: string) => {
    const { text } = messageToSend;
    setMessageToSend({ ...messageToSend, text: text ? text + emoji : emoji });
  };
  const handleInfoClick = () => {
    if (currentChat) {
      setShowChatDetails(true);
    }
  };
  return (
    <>
      <Stack display="flex" justifyContent="center">
        <AppBar className="message_list_menu">
          <Toolbar className="message_list_toolbar">
            <ListItemAvatar>
              <AvatarIcon name={currentChat?.name ?? ''} />
            </ListItemAvatar>
            <ListItemText
              disableTypography={true}
              primary={
                <Typography color="primary" variant="h6">
                  {currentChat?.name}
                </Typography>
              }
              secondary={
                <Typography color="gray">
                  {t('chatbox.members')}: {currentChat?.members?.length}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={handleInfoClick}>
                <InfoIcon className="info_icon" />
              </IconButton>
            </ListItemSecondaryAction>
          </Toolbar>
        </AppBar>
        <List className="messages_list" onScroll={(e) => handleScroll(e)}>
          {messagesAreLoading && <CircularProgress className="message_list_loader" />}
          {docs?.map((message) => (
            <Message message={message} key={message.id} />
          ))}
          <div ref={scrollRef} />
        </List>
        <Divider />
        <Stack direction="row" width="100%" padding={2} alignItems="center">
          <Stack className="input_container" component={'div'}>
            {messageToSend.files && messageToSend.files.length !== 0 && (
              <Grid container padding={2} spacing={2}>
                {Object.keys(messageToSend.files).map((_, i) => {
                  const { name, size } = messageToSend.files[i] as File;
                  return (
                    <Grid key={i} item>
                      <FileComponent
                        variant="card"
                        filename={name}
                        size={size}
                        cardHeaderAction={() => {
                          removeFile(name);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Input
              className="chatbox_input"
              value={messageToSend.text || ''}
              placeholder={t('chatbox.starter_text')}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessageToSend({ ...messageToSend, text: e.target.value })}
              endAdornment={
                <Stack direction="row">
                  <FileUpload
                    handleChange={(files) => {
                      setMessageToSend({ ...messageToSend, files: Array.from(files) });
                    }}
                  />
                  <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <TagFacesIcon />
                  </IconButton>
                  {showEmojiPicker && (
                    <Paper className="emoji_picker_container" elevation={5}>
                      <Picker
                        disableSkinTonePicker={true}
                        groupNames={{
                          smileys_people: 'yellow faces++',
                          animals_nature: 'cute dogs and also trees',
                          food_drink: 'milkshakes and more',
                          travel_places: 'I love trains',
                          activities: 'lets play a game',
                          objects: 'stuff',
                          symbols: 'more stuff',
                          flags: 'fun with flags',
                          recently_used: 'did I really use those?!',
                        }}
                        onEmojiClick={(_, { emoji }) => onEmojiClick(emoji)}
                      />
                    </Paper>
                  )}
                </Stack>
              }
            />
          </Stack>
          <IconButton className="send_icon" disabled={disableSend} onClick={() => handleSendMessage()}>
            <SendIcon />
          </IconButton>
        </Stack>
      </Stack>
      {/* chat details */}
      <Alert
        open={showChatDetails}
        handleOpen={() => {
          setShowChatDetails(false);
        }}
        hasAction={false}
        body={<UsersList hasAction={false} selectedChat={currentChat} />}
      />
    </>
  );
};

export default React.memo(MessagesList);
