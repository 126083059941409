import { createReducer, Action } from '@reduxjs/toolkit';
import { NotificationDetails } from 'core/models';
import {
  getNotificationsAction,
  getNotificationsErrorAction,
  getNotificationsSuccessAction,
  markAsReadSuccessAction,
  ReceivedNotificationAction,
} from './notification.actions';

interface NotificationState {
  notifications: NotificationDetails[];
  limit: number;
  total: number;
  loading: boolean;
}
const initialState: NotificationState = {
  notifications: [] as NotificationDetails[],
  limit: 10,
  total: 0,
  loading: false,
};

const _notificationReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getNotificationsAction, (state) => ({ ...state, loading: true }))
    .addCase(getNotificationsSuccessAction, (state, { payload: { docs, total, limit } }) => ({
      ...state,
      notifications: docs,
      total,
      limit,
      loading: false,
    }))
    .addCase(getNotificationsErrorAction, (state) => ({ ...state, loading: false }))

    .addCase(ReceivedNotificationAction, (state, { payload }) => ({
      ...state,
      total: state.total + 1,
      notifications: [payload, ...state.notifications],
    }))
    .addCase(markAsReadSuccessAction, (state, { payload: { docs, total, limit } }) => ({
      ...state,
      notifications: docs,
      total,
      limit,
      loading: false,
    })),
);

export const notificationReducer = (state: NotificationState | undefined, action: Action) => _notificationReducer(state, action);
