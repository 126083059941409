import { ProjectDto } from 'core/generated/ProjectDto';
import { TeamMemberDetails } from '../teamMember/team-member-details';
const api = process.env.REACT_APP_API_ENDPOINT || '';
interface I {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export interface ProjectDetails extends I {
  id: string;
  title: string;
  priority: number;
  deleted: boolean;
  lead: TeamMemberDetails;
  team: string[];
  type: string;
  avatar: string;
  documents: string[];
  createdAt?: string;
  updatedAt?: string;
}

export namespace ProjectDetails {
  export const mapToApiValue = ({ avatar, id, title, priority, lead, team, deleted, type, documents }: ProjectDetails): ProjectDto => ({
    _id: id,
    title,
    priority,
    avatar,
    lead: {
      _id: lead.id,
      firstName: lead.firstName,
      lastName: lead.lastName,
      email: lead.email,
      avatar: lead.avatar,
    },
    deleted,
    team,
    type,
    documents,
  });
  export const mapFromApiValue = ({
    avatar,
    _id,
    title,
    priority,
    lead,
    deleted,
    team,
    type,
    documents,
    createdAt,
    updatedAt,
  }: ProjectDto): ProjectDetails => ({
    id: _id,
    title,
    priority,
    avatar: avatar ? api + avatar : avatar,
    lead: {
      avatar: lead.avatar ? api + lead.avatar : avatar,
      id: lead._id,
      firstName: lead.firstName,
      lastName: lead.lastName,
      email: lead.email,
    },
    deleted,
    team,
    type,
    documents,
    createdAt,
    updatedAt,
  });
}
