import { I } from '../user/user-details';
import { SectionDto } from '../../generated/SectionDto';

export interface SectionDetails extends I {
  id?: string;
  value: string;
  access: number;
}
export namespace SectionDetails {
  export const mapFromApiValue = ({ _id, value, access }: SectionDto): SectionDetails => ({ id: _id, value, access });
  export const mapToApiValue = ({ id, value, access }: SectionDetails): SectionDto => ({ _id: id, value, access });
}
