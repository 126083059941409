import React, { Fragment } from 'react';
import { Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  list: {
    title: string;
    path?: string;
  }[];
}

export const HistoryList: React.FC<Props> = ({ list = [] }) => {
  return (
    <Typography>
      {list.map(({ title, path }, i) => (
        <Fragment key={i}>
          <MuiLink underline="hover" component={Link} to={path ? path : '#'}>
            {title}
          </MuiLink>
          {list.length - 1 !== i && ' / '}
        </Fragment>
      ))}
    </Typography>
  );
};
