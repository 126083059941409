import { EventDto } from 'core/generated/EventDTO';
import { I } from 'core/models';
import { TeamMemberDetails } from '../teamMember/team-member-details';

export interface EventDetails extends I {
  id: string;
  title: string;
  participants: TeamMemberDetails[];
  organizer: TeamMemberDetails;
  notes: string;
  // attribute defining repetition details of the event
  rRule?: string;
  dates: { startDate: Date; endDate: Date }[];
  createdAt?: string;
  updatedAt?: string;
}
export namespace EventDetails {
  export const mapFromApiValue = (event: EventDto): EventDetails => {
    const eventDetails = {} as EventDetails;
    Object.keys(event).forEach((key) => {
      if (event[key]) {
        if (key === '_id') {
          eventDetails['id'] = event['_id'];
        } else if (key === 'participants') {
          eventDetails['participants'] = event['participants'].map((participant) => TeamMemberDetails.mapFromApiValue(participant));
        } else if (key === 'organizer') {
          eventDetails['organizer'] = TeamMemberDetails.mapFromApiValue(event['organizer']);
        } else {
          eventDetails[key] = event[key];
        }
      }
    });
    return eventDetails;
  };
  export const mapToApiValue = (event: EventDetails): EventDto => {
    const eventDto = {} as EventDto;
    Object.keys(event).forEach((key) => {
      if (event[key]) {
        if (key === 'id') {
          eventDto['_id'] = event['id'];
        } else if (key === 'participants') {
          eventDto['participants'] = event['participants'].map((participant) => TeamMemberDetails.mapToApiValue(participant));
        } else if (key === 'organizer') {
          eventDto['organizer'] = TeamMemberDetails.mapToApiValue(event['organizer']);
        } else {
          eventDto[key] = event[key];
        }
      }
    });
    return eventDto;
  };
}
