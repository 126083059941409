import { createTheme } from '@mui/material';

export const getFormTheme = () => {
  return createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            height: '100vh',
            background: 'url(https://anypli.com/static/media/Intersection_request.17abf812.svg)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100vw',
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            '& .form': {
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '10px',
              position: 'relative',
            },
          },
        },
      },
    },
  });
};
