import { SectionDetails } from '../section/section-details';
import { I } from '../user/user-details';
import { ProfileDto } from '../../generated/ProfileDto';

export interface ProfileDetails extends I {
  id: string;
  title: string;
  description: string;
  sections: SectionDetails[];
  status: string;
}
export namespace ProfileDetails {
  export const mapFromApiValue = (profile: ProfileDto): ProfileDetails => {
    const profileDetails = {} as ProfileDetails;
    Object.keys(profile).forEach((key) => {
      if (profile[key]) {
        if (key === '_id') {
          profileDetails['id'] = profile['_id'];
        } else if (key === 'section') {
          profileDetails['section'] = profile.sections.map((section) => SectionDetails.mapFromApiValue(section));
        } else {
          profileDetails[key] = profile[key];
        }
      }
    });
    return profileDetails;
  };
  export const mapToApiValue = (profile: ProfileDetails): ProfileDto => {
    const profileDto = {} as ProfileDto;
    Object.keys(profile).forEach((key) => {
      if (profile[key]) {
        if (key === 'id') {
          profileDto['_id'] = profile['id'];
        } else if (key === 'section') {
          profileDto['section'] = profile.sections.map((section) => SectionDetails.mapToApiValue(section));
        } else {
          profileDto[key] = profile[key];
        }
      }
    });
    return profileDto;
  };
}
