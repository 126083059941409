import React, { useState } from 'react';
import { api } from 'core/constants';
import { Alert } from './Alert/alert.component';
import { ImageListItem } from '@mui/material';

interface Props {
  img: string;
}
const MuiImageListItemComponent: React.FC<Props> = ({ img }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ImageListItem onClick={() => setOpen(true)}>
        <img src={api + img} />
      </ImageListItem>
      <Alert
        open={open}
        handleOpen={() => {
          setOpen(!open);
        }}
        body={<img src={api + img} />}
        hasAction={false}
      />
    </>
  );
};

export const MuiImageListItem = React.memo(MuiImageListItemComponent);
