import { Card, Grid, Stack, Typography, Container, LinearProgress } from '@mui/material';
import { UserDetails } from 'core/models';
import { loadProfilesAction } from 'modules/Profiles/state/profiles.actions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InputElement, MuiBtnGrp } from 'shared/components';
import { AppState } from 'store';
import { getUser } from '../../core/services/user.service';
import { addUserAction, updateUserAction } from './state/user.actions';
import { navigate } from '../../core/services/history.service';
import { routes } from '../../core/enums/routes';

export const ManageUser: React.FC = () => {
  const dispatch = useDispatch();
  const {
    profiles: { profiles },
    users: { loading },
  } = useSelector((state: AppState) => state);
  const { t } = useTranslation();
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState({} as UserDetails);
  const [userLoading, setUserLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setUserLoading(true);
        dispatch(loadProfilesAction());
        if (id) {
          // getting user from db
          const user = await getUser(id);
          setUserDetails(user);
        }
        setUserLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails({ ...userDetails, [name]: value });
  };

  const filterProfile = (v: string) => {
    const profile = profiles.find(({ title }) => title === v)?.id;
    setUserDetails({ ...userDetails, profile });
  };

  const handleStatus = () => {
    setUserDetails({ ...userDetails, disabled: !userDetails?.disabled });
  };

  const handleSubmit = () => {
    // avatar can only be updated from profile page
    if (userDetails.avatar) {
      delete userDetails.avatar;
    }
    id ? dispatch(updateUserAction(userDetails)) : dispatch(addUserAction(userDetails));
  };
  const fields = [
    { name: 'firstName', type: 'text', label: t('user.firstName'), value: userDetails?.firstName, handleChange },
    { name: 'lastName', type: 'text', label: t('user.lastName'), value: userDetails?.lastName, handleChange },
    { name: 'email', type: 'email', label: t('login.email'), value: userDetails?.email, handleChange },
    { name: 'password', type: 'password', label: t('login.password'), value: userDetails?.password, handleChange },
    { name: 'department', type: 'text', label: t('user.department'), value: userDetails?.department, handleChange },
    { name: 'job_title', type: 'text', label: t('user.job_title'), value: userDetails?.job_title, handleChange },
    { name: 'role', type: 'text', label: t('user.role'), value: userDetails?.role, handleChange },
    {
      name: 'profile',
      type: 'select',
      label: t('user.profile'),
      value: userDetails?.profile?.title,
      handleChange: filterProfile,
      options: profiles.map(({ title }) => title),
      multiple: false,
      optionIsSimpleValue: true,
    },
    {
      name: 'status',
      type: 'switch',
      label: t(`user.${userDetails.disabled ? 'disabled' : 'enabled'}`),
      value: userDetails?.disabled,
      handleChange: handleStatus,
    },
  ];

  return userLoading ? (
    <LinearProgress />
  ) : (
    <Grid component={Container} padding="20px 0">
      <Card>
        <Stack padding="20px 30px">
          <Typography variant="h4" gutterBottom>
            {t('user.' + (id ? 'edit' : 'add') + '_user')}
          </Typography>
          <Grid container padding={5}>
            {fields.map((field, key) => (
              <Grid item key={key} xs={12} sm={10} md={5} lg={4} margin={2}>
                <InputElement {...field} />
              </Grid>
            ))}
          </Grid>
          <MuiBtnGrp saving={loading} submit={false} save={true} handleSubmit={() => handleSubmit()} handleCancel={() => navigate(routes.users)} />
        </Stack>
      </Card>
    </Grid>
  );
};
