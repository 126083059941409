export const login = {
  email: 'E-mail',
  password: 'Password',
  title: 'Sign In',
  submit: 'Login',
  recover_mail: 'Send recovery mail',
  password_updated: 'Password was updated successfully',
  set_password: 'Set password',
  back_to_login: 'Back to login',
};
