import { createTheme } from '@mui/material';
import { colors } from './shared-theme';

export const getChatboxTheme = (theme?: string[]) => {
  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;
  return createTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '& div': {
              '&.emoji_picker_container': {
                position: 'absolute',
                right: '0%',
                bottom: '105%',
                padding: '5px',
              },
              '&.message_body': {
                backgroundColor: colors.HOVER_COLOR,
                padding: '5px',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '0 10px 10px 10px',
                '&_self': {
                  backgroundColor: primaryColor,
                  color: 'white !important',
                  borderRadius: '10px 10px 0 10px',
                  padding: '5px',
                },
                '&_container': {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '5px',
                  '&_self': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '5px',
                  },
                },
              },
              '&.input_container': {
                backgroundColor: colors.HOVER_COLOR,
                flex: 1,
                border: '1px solid',
                borderColor: colors.GREY,
                borderRadius: '5px',
              },
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.chatbox_container': {
              maxHeight: '80vh',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '&.chatlist': {
              width: '100%',
              bgcolor: 'background.paper',
              borderRight: '1.5px solid',
              borderColor: primaryColor,
              height: '95vh',
              overflowY: 'auto',
            },
            '&.connected_users_list': {
              borderLeft: '1.5px solid',
              borderColor: primaryColor,
              overflowY: 'auto',
              height: '100%',
            },
            '&.messages_list': {
              height: '70vh',
              overflowY: 'auto',
              overflowX: 'hidden',
            },
            '&.chat_details': {
              minHeight: '50vh',
              width: '100%',
              overflowY: 'auto',
            },
          },
        },
      },
      MuiImageListItem: {
        styleOverrides: {
          root: {
            maxWidth: '200px',
            cursor: 'pointer',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            '&.message_list_loader': {
              position: 'absolute',
              right: '45%',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.file_download_btn': {
              color: colors.GREY_DARK,
              border: '1px solid',
              borderColor: colors.GREY_DARK,
              '&_self': {
                color: 'white',
                border: '1px solid white',
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: 'none',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.new_message_icon': {
              color: secondaryColor,
              fontSize: '15px',
              margin: '0 10px',
            },
            '&.avatar_icon': {
              transform: 'scale(2) translate(3px)',
              color: colors.GREY,
            },
            '&.member_action_icon': {
              transform: 'scale(1.3)',
              '&_remove': {
                color: 'red',
              },
              '&_add': {
                color: secondaryColor,
              },
            },
            '&.current_chat_tools': {
              color: colors.WHITE,
            },
            '&.info_icon': {
              color: secondaryColor,
              fontSize: '32px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.emoji_picker_container': {
              position: 'absolute',
              right: '0%',
              bottom: '105%',
              padding: '5px',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.current_chat': {
              background: `linear-gradient(to bottom,${primaryColor},${secondaryColor})`,
              color: colors.WHITE,
              transition: 'all 0s',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.send_icon': {
              color: secondaryColor,
            },
            '&.info_icon': {
              color: secondaryColor,
              fontSize: '20px',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            '&.message_list_menu': {
              position: 'relative',
              background: colors.WHITE,
              width: '100%',
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            '&.message_list_toolbar': {
              padding: '5px 10px 5px 20px',
              width: '100%',
            },
          },
        },
      },
    },
  });
};
