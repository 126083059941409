import { ProjectDetailsDTO } from 'core/generated/ProjectDetailsDTO';
import { HttpParamsType } from 'core/models';
import { ProjectDetails } from 'core/models/project-details/project-details';
import { ProjectDetails as LessProjectDetails } from 'core/models/project/project-details';
import { apiUrlMatcher, ApiUrls, get, post, put, _delete, download as downloadService } from './helpers';
import download from 'downloadjs';
import { ProjectDto } from 'core/generated/ProjectDto';

// loads all projects from the database based on a query string
export const loadProject = async (id: string): Promise<ProjectDetails> => {
  const data = await get<ProjectDetailsDTO>(apiUrlMatcher(ApiUrls.getProject) + '/' + id);
  return ProjectDetails.mapFromApiValue(data);
};
export const addProject = async (params: HttpParamsType<FormData>): Promise<ProjectDetails> => {
  const data = await post<ProjectDetailsDTO>(apiUrlMatcher(ApiUrls.addProject), params);
  return ProjectDetails.mapFromApiValue(data);
};

export const updateProject = async (params: HttpParamsType<FormData>): Promise<ProjectDetails> => {
  const projectId = params?.queryParams?.id;
  const data = await put<ProjectDetailsDTO>(apiUrlMatcher(ApiUrls.updateProject) + '/' + projectId, params);
  return ProjectDetails.mapFromApiValue(data);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadFile = async (apiFilename: string, filename: string): Promise<any> => {
  const file = await downloadService(apiFilename);
  download(file.data, filename, file.data.type);
};
export const uploadFiles = async (params: HttpParamsType<FormData>): Promise<ProjectDetails> => {
  const projectId = params?.queryParams?.id;
  const data = await put<ProjectDetailsDTO>(apiUrlMatcher(ApiUrls.uploadProjectDocs) + '/' + projectId, params);
  return ProjectDetails.mapFromApiValue(data);
};
export const deleteFile = async (params: HttpParamsType<string[]>): Promise<ProjectDetails> => {
  const projectId = params?.queryParams?.id;
  const data = await put<ProjectDetailsDTO>(apiUrlMatcher(ApiUrls.deleteProjectDocs) + '/' + projectId, params);
  return ProjectDetails.mapFromApiValue(data);
};

export const deleteProject = async (id: string): Promise<LessProjectDetails[]> => {
  const data = await _delete<ProjectDto[]>(apiUrlMatcher(ApiUrls.deleteProject) + '/' + id);
  return data.map((proj) => LessProjectDetails.mapFromApiValue(proj));
};

export const restoreProject = async (id: string): Promise<LessProjectDetails[]> => {
  const data = await _delete<ProjectDto[]>(apiUrlMatcher(ApiUrls.restoreProject) + '/' + id);
  return data.map((proj) => LessProjectDetails.mapFromApiValue(proj));
};
