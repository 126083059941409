export const colors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  PRIMARY_BLUE: '#00a3e0',
  LIGHT_BLUE: '#1dace3',
  HOVER_COLOR: '#0000000a',
  GREY: '#b9b8b8',
  GREY_DARK: '#696969',
  DEEP_RED: '#c3262d',
  BLUE_DARK: '#4e5d73',
  BG_COLOR: `linear-gradient(to right,#c3262d,#001a49)`,
  GOLD: '#ffc107',
};
