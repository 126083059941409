import { RecommendationDto } from 'core/generated/Recommendation';
import { I } from '../user/user-details';

export interface RecommendationDetails extends I {
  id: string;
  description: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export namespace RecommendationDetails {
  export const mapFromApiValue = (recommendation: RecommendationDto): RecommendationDetails => {
    const recommendationDetails = {} as RecommendationDetails;
    Object.keys(recommendation).forEach((key) => {
      if (recommendation[key]) {
        if (key === '_id') {
          recommendationDetails['id'] = recommendation['_id'];
        } else {
          recommendationDetails[key] = recommendation[key];
        }
      }
    });
    return recommendationDetails;
  };
  export const mapToApiValue = (recommendation: RecommendationDetails): RecommendationDto => {
    const recommendationDto = {} as RecommendationDto;
    Object.keys(recommendation).forEach((key) => {
      if (recommendation[key]) {
        if (key === 'id') {
          recommendationDto['_id'] = recommendation['id'];
        } else {
          recommendationDto[key] = recommendation[key];
        }
      }
    });
    return recommendationDto;
  };
}
