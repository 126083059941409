import { IconButton, Stack, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { CameraAlt as CameraAltIcon } from '@mui/icons-material';
import { AvatarIcon } from 'shared/components/Avatar/avatar.component';

interface Props {
  handleChange: (file: File) => void;
  name: string;
  avatar: string;
  toolTipText: string;
  width?: number;
  height?: number;
}

export const AvatarUpload: React.FC<Props> = ({ handleChange, name, avatar, toolTipText, width = 50, height = 50 }) => {
  const [previewAvatar, setPreviewAvatar] = useState<File | undefined | null | string>();

  const getAvatarPreview = () => URL.createObjectURL(previewAvatar as Blob);

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewAvatar(e.currentTarget.files?.item(0) as File);
    handleChange(e.currentTarget.files?.item(0) as File);
  };
  return (
    <Stack direction="row" justifyContent="space-around">
      {avatar && !previewAvatar && <AvatarIcon height={height} width={width} avatar={avatar} name={name} />}
      {previewAvatar && <AvatarIcon height={height} width={width} avatar={previewAvatar ? getAvatarPreview() : avatar} name={name} />}
      <Tooltip title={toolTipText}>
        <IconButton aria-label="upload picture" component="label">
          <input hidden accept="image/*" id="icon-button-file" name={'avatar'} type="file" onChange={(e) => handleAvatarChange(e)} />
          <CameraAltIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
