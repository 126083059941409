/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { AvatarGroup, CircularProgress, CssBaseline, Paper, Stack, Typography } from '@mui/material';
import { ViewState, EditingState, ChangeSet, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  WeekView,
  AppointmentTooltip,
  MonthView,
  Appointments,
  ViewSwitcher,
  Toolbar,
  DateNavigator,
  TodayButton,
  ConfirmationDialog,
  AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { getEventsAction, createEventAction, updateEventAction, deleteEventAction } from './state/calendar.actions';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, Link as MuiLink } from '@mui/material';
import { getCalendarTheme } from 'shared/themes';
import { AvatarIcon, MuiSelect } from 'shared/components';
import { EventDetails } from 'core/models';
import { loadUsersAction } from 'modules/usersList/state/users.actions';
import { handleMembersSelect } from 'core/utils';

const Calendar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    calendar: { events, loading },
    auth: {
      user: { theme, id },
    },
    users: { users },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch(loadUsersAction());
    dispatch(getEventsAction());
  }, []);

  function commitChanges({ added, changed, deleted }: ChangeSet) {
    if (added) {
      if (added.allDay) {
        added.endDate.setHours(18);
      }
      dispatch(createEventAction(added as EventDetails));
    }
    if (changed) {
      // fetching embedded event data from changed object
      const eventId = Object.keys(changed)[0];
      changed = changed[eventId];
      dispatch(updateEventAction({ id: eventId.split(' ')[0], ...changed } as EventDetails));
    }
    if (deleted) {
      const eventID = deleted.toString().split(' ')[0];
      dispatch(deleteEventAction(eventID));
    }
  }

  const Content = ({ appointmentData, ...restProps }: any) => {
    const { link, participants, organizer } = appointmentData as EventDetails;
    return (
      <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        <Stack paddingLeft={3.7}>
          {link && (
            <Stack direction="row" alignItems="center">
              <Typography className="tooltip_appointment">{t('profile.event_link')} :</Typography>
              <MuiLink target="_blank" underline="hover" component={'a'} href={link ? link : '#'}>
                {link?.length > 20 ? link?.slice(0, 20) + '...' : link}
              </MuiLink>
            </Stack>
          )}
          <Stack direction="row" alignItems="center">
            <Typography className="tooltip_appointment">{t('profile.organizer')} :</Typography>
            <AvatarIcon name={organizer.firstName + ' ' + organizer.lastName} avatar={organizer.avatar} />
          </Stack>
          {participants && participants.length && (
            <Stack direction="row" alignItems="center">
              <Typography className="tooltip_appointment">{t('profile.participants')} :</Typography>
              <AvatarGroup max={3}>
                {participants.map((participant, i) => {
                  const name = participant.firstName + ' ' + participant.lastName;
                  return <AvatarIcon key={i} name={name} avatar={participant.avatar} />;
                })}
              </AvatarGroup>
            </Stack>
          )}
        </Stack>
      </AppointmentTooltip.Content>
    );
  };

  const Header = ({ showCloseButton, showOpenButton, showDeleteButton, ...restProps }: any) => {
    const showbuttons = restProps.appointmentData.organizer.id === id;
    showOpenButton = showbuttons;
    showDeleteButton = showbuttons;
    showCloseButton = true;
    return (
      <AppointmentTooltip.Header
        showCloseButton={showCloseButton}
        showOpenButton={showOpenButton}
        showDeleteButton={showDeleteButton}
        {...restProps}
      />
    );
  };

  const layoutComponent = ({ appointmentData, onFieldChange, ...restProps }: any) => {
    return (
      <AppointmentForm.BasicLayout
        {...restProps}
        onFieldChange={onFieldChange}
        appointmentData={{ ...appointmentData, startDate: new Date(appointmentData.startDate), endDate: new Date(appointmentData.endDate) }}
      >
        <Stack padding="10px 0" spacing={2}>
          <AppointmentForm.TextEditor
            readOnly={false}
            type={'ordinaryTextEditor'}
            placeholder={t('profile.event_link') as string}
            value={appointmentData.link}
            onValueChange={(link) => onFieldChange({ link })}
          />
          <MuiSelect
            handleChange={(participants) => {
              onFieldChange({ participants: handleMembersSelect(participants) });
            }}
            name={'participants'}
            multiple={true}
            label={t('profile.participants')}
            options={users.filter((user) => user.id !== id)}
            value={appointmentData.participants ?? []}
          />
        </Stack>
      </AppointmentForm.BasicLayout>
    );
  };

  // mapping events into appointmentData array
  const data = events
    .map((event) =>
      event.dates.map((date) => ({ ...event, id: event.id + ' ' + date.startDate.toString(), startDate: date.startDate, endDate: date.endDate })),
    )
    .reduce((acc, current) => acc.concat(...current), []);

  return (
    <ThemeProvider theme={getCalendarTheme(theme)}>
      <CssBaseline />
      <Paper className="container">
        {loading ? (
          <CircularProgress className="calendar-loading" />
        ) : (
          <>
            <Scheduler data={data}>
              <ViewState defaultCurrentDate={new Date()} defaultCurrentViewName="Week" />
              <EditingState onCommitChanges={commitChanges} />

              <DayView startDayHour={8.5} endDayHour={18} />
              <WeekView startDayHour={8.5} endDayHour={18} />
              <MonthView />

              <IntegratedEditing />
              <ConfirmationDialog />

              <Appointments />
              <AppointmentTooltip contentComponent={Content} headerComponent={Header} />
              <AppointmentForm basicLayoutComponent={layoutComponent} />

              <Toolbar />
              <DateNavigator />
              <TodayButton />
              <ViewSwitcher />
            </Scheduler>
          </>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default Calendar;
