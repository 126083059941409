import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'modules/auth/state/auth.actions';
import { history } from 'core/services/history.service';
import { routes } from 'core/enums/routes';
import { localStorageKeys } from 'core/enums/local-storage-keys';
import { useTranslation } from 'react-i18next';
import { unsubscribe } from 'core/services/notification.service';

export const LogoutIconComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logout = () => {
    // removes auth token from local storage
    localStorage.removeItem(localStorageKeys.token);
    localStorage.removeItem(localStorageKeys.theme);
    // dispatches logout action
    dispatch(logoutAction());
    // unsubscribe from notifications
    unsubscribe();
    // pushes user to login page
    history.push(routes.login);
  };

  // className="container_btns_icon container_btns_logout"
  return (
    <Tooltip title={t('profile.logout').toString()}>
      <IconButton onClick={() => logout()}>
        <LogoutIcon className="menu_icon" />
      </IconButton>
    </Tooltip>
  );
};
