import { createReducer, Action } from '@reduxjs/toolkit';
import { UserDetails } from 'core/models';
import { cancelPreviewAction, previewThemeAction, updateProfileAction } from 'modules/Profile/state/profile.actions';
import { loadUser, loginAction, loginFailedAction, loginSuccessAction, logoutAction } from './auth.actions';

export interface AuthState {
  user: UserDetails;
  loading: boolean;
}

const initialState: AuthState = {
  loading: false,
  user: {} as UserDetails,
};

const _authReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(loginAction, (state: AuthState): AuthState => ({ ...state, loading: true }))
    .addCase(
      loginSuccessAction,
      (state, { payload }): AuthState => ({
        ...state,
        loading: false,
        user: payload,
      }),
    )
    .addCase(loginFailedAction, (state): AuthState => ({ ...state, loading: false }))
    .addCase(logoutAction, () => initialState)
    .addCase(loadUser, (state, { payload }) => ({ ...state, user: payload, loading: false }))
    .addCase(previewThemeAction, (state, { payload }) => ({ ...state, user: { ...state.user, theme: [...payload] } }))
    .addCase(cancelPreviewAction, (state, { payload }) => ({ ...state, user: { ...state.user, theme: [...payload] } }))
    .addCase(updateProfileAction, (state) => ({ ...state, loading: true }))
    .addDefaultCase((state): AuthState => state),
);

export const authReducer = (state: AuthState | undefined, action: Action): AuthState => _authReducer(state, action);
