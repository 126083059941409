/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { handleMembersSelect } from 'core/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarIcon } from '../Avatar/avatar.component';

interface Props {
  label: string;
  options: any[];
  handleChange: (value: any) => void;
  value: any;
  name: string;
  multiple?: boolean;
  optionIsName?: boolean;
  optionIsSimpleValue?: boolean;
  model?: string;
  required?: boolean;
  withIcon?: boolean;
}

export const MuiSelect: React.FC<Props> = ({
  name = '',
  value = '',
  label = '',
  options = [],
  handleChange = () => {},
  multiple = false,
  optionIsName = true,
  model = '',
  optionIsSimpleValue = false,
  required = false,
  // adds an icon to the select options in case of using a simple value
  withIcon = false,
}) => {
  const { t } = useTranslation();

  const parametredName: any = (val: any) => (optionIsName ? val.firstName + ' ' + val.lastName : t(model + '.' + val));

  value = value ? value : multiple ? [] : '';

  // get the value with the same reference in case the options  are objects and value is not empty
  if (!optionIsSimpleValue && !multiple) {
    const thereIsAValue = multiple ? value.length !== 0 : value !== '';
    if (thereIsAValue) {
      value = options.find((option) => option.id === value.id);
    }
  }

  const handleSelectingMultipleOptions = (multiOptions: any) => {
    // test if the options selected are object (specifically users)
    if (!optionIsSimpleValue) {
      handleChange(handleMembersSelect(multiOptions));
    }
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        multiline={true}
        multiple={multiple}
        value={value}
        name={name}
        required={required}
        label={label}
        displayEmpty={true}
        renderValue={(val) => {
          return (
            <Stack display="flex" flexDirection="row">
              {val ? (
                !multiple ? (
                  optionIsSimpleValue ? (
                    <MenuItem>
                      {withIcon && <AvatarIcon name={val} />}
                      {t(model + '.' + val)}
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <AvatarIcon name={parametredName(val)} avatar={val.avatar} />
                      <Typography paddingLeft="15px">{parametredName(val)}</Typography>
                    </MenuItem>
                  )
                ) : (
                  val?.map((v: any, i: number) => {
                    return optionIsSimpleValue ? (
                      <MenuItem key={i}>{t(model + '.' + val)}</MenuItem>
                    ) : (
                      <MenuItem key={i}>
                        <AvatarIcon name={parametredName(v)} avatar={v.avatar} />
                      </MenuItem>
                    );
                  })
                )
              ) : (
                ''
              )}
            </Stack>
          );
        }}
        onChange={(e) => (multiple ? handleSelectingMultipleOptions(e.target.value) : handleChange(e.target.value))}
      >
        {options.map((option: any, i: number) =>
          optionIsSimpleValue ? (
            <MenuItem key={i} value={option}>
              {withIcon && <AvatarIcon name={option} />}
              {t(model + '.' + option)}
            </MenuItem>
          ) : (
            <MenuItem key={i} value={option}>
              <AvatarIcon name={parametredName(option)} avatar={option.avatar} />
              <Typography paddingLeft="15px">{parametredName(option)}</Typography>
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  );
};
