import React, { useEffect } from 'react';
import Profile from 'modules/Profile/profile.components';

export const ProfilePage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'User profile page';
  }, []);
  return <Profile />;
};
