import { UserDTO } from 'core/generated/UserDto';
import { I } from '../user/user-details';
const api = process.env.REACT_APP_API_ENDPOINT;
export interface UsersDetails extends I {
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  avatar: string;
  job_title?: string;
  department?: string;
}

export namespace UsersDetails {
  export const mapFromApiValue = ({ email, avatar, firstName, lastName, _id, role, department, job_title }: UserDTO): UsersDetails => ({
    id: _id,
    email,
    firstName,
    lastName,
    role,
    avatar: avatar ? api + avatar : avatar,
    job_title,
    department,
  });
}
