import { PayloadAction } from '@reduxjs/toolkit';
import { NotificationContent, routes } from 'core/enums';
import { ErrorResponseData, NotificationDetails, TaskDetails } from 'core/models';
import { history } from 'core/services/history.service';
import { createTask, updateTask } from 'core/services/task.service';
import { createNotificationAction } from 'modules/Notifications/state/notification.actions';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  createTaskAction,
  createTaskFailureAction,
  createTaskSuccessAction,
  updateTaskAction,
  updateTaskFailureAction,
  updateTaskSuccessAction,
} from './task.actions';

function* createTaskEffect({ payload: task }: PayloadAction<TaskDetails>): Generator<Promise<TaskDetails> | PutEffect, void, TaskDetails> {
  try {
    const projectId = history.location.pathname.includes(routes.backlog) ? history.location.pathname.split('/')[2] : '';
    const data = yield createTask({ body: TaskDetails.mapToApiValue(task) });
    yield put(createTaskSuccessAction(data));
    yield put(openSnackBarAction({ message: 'task.task_created', severity: 'success' }));
    yield put(
      createNotificationAction({
        content: NotificationContent.ASSIGN,
        task: data,
        project: projectId,
      } as NotificationDetails),
    );
  } catch (error) {
    yield put(createTaskFailureAction(error as ErrorResponseData));
  }
}

function* updateTaskEffect({ payload: task }: PayloadAction<TaskDetails>): Generator<Promise<TaskDetails> | PutEffect, void, TaskDetails> {
  try {
    const projectId = history.location.pathname.includes(routes.backlog) ? history.location.pathname.split('/')[2] : '';
    const data = yield updateTask({ body: TaskDetails.mapToApiValue(task) });
    yield put(updateTaskSuccessAction(data));
    yield put(openSnackBarAction({ message: 'task.task_updated', severity: 'success' }));
    yield put(createNotificationAction({ content: NotificationContent.UPDATE, task: data, project: projectId } as NotificationDetails));
  } catch (error) {
    yield put(updateTaskFailureAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  console.log(error.message);
  yield put(openSnackBarAction({ message: error.status ? 'error.server' : 'error.network', severity: 'error' }));
}

export function* watchTask() {
  yield all([
    takeLatest(createTaskAction.type, createTaskEffect),
    takeLatest(updateTaskAction.type, updateTaskEffect),
    takeLatest(createTaskFailureAction.type, failEffect),
    takeLatest(updateTaskFailureAction.type, failEffect),
  ]);
}
