import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponseData } from 'core/models';
import { SprintDetails } from 'core/models/sprint/sprint-details';
import { createSprint, deleteSprint, loadBacklog, updateSprint } from 'core/services/sprints.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  loadBacklogAction,
  loadBacklogFailureAction,
  loadBacklogSuccessAction,
  createSprintFailureAction,
  createSprintAction,
  updateSprintFailureAction,
  updateSprintAction,
  deleteSprintFailureAction,
  deleteSprintSuccessAction,
  deleteSprintAction,
  updateSprintSuccessAction,
  createSprintSuccessAction,
} from './backlog.actions';

function* loadBacklogEffect({
  payload: { projectId },
}: PayloadAction<{ projectId: string }>): Generator<Promise<SprintDetails[]> | PutEffect, void, SprintDetails[]> {
  try {
    const data = yield loadBacklog(projectId);
    yield put(loadBacklogSuccessAction(data));
  } catch (error) {
    yield put(loadBacklogFailureAction(error as ErrorResponseData));
  }
}

function* createSprintEffect({
  payload: sprint,
}: PayloadAction<SprintDetails>): Generator<Promise<SprintDetails[]> | PutEffect, void, SprintDetails[]> {
  try {
    const data = yield createSprint({ body: SprintDetails.mapToApiValue(sprint) });
    yield put(createSprintSuccessAction(data));
    yield put(openSnackBarAction({ message: 'sprint.sprint_created', severity: 'success' }));
  } catch (error) {
    yield put(createSprintFailureAction(error as ErrorResponseData));
  }
}
function* updateSprintEffect({
  payload: sprint,
}: PayloadAction<SprintDetails>): Generator<Promise<SprintDetails[]> | PutEffect, void, SprintDetails[]> {
  try {
    const data = yield updateSprint({ body: SprintDetails.mapToApiValue(sprint) });
    yield put(updateSprintSuccessAction(data));
    yield put(openSnackBarAction({ message: 'sprint.sprint_updated', severity: 'success' }));
  } catch (error) {
    yield put(updateSprintFailureAction(error as ErrorResponseData));
  }
}

function* deleteSprintEffect({ payload: sprintId }: PayloadAction<string>): Generator<Promise<SprintDetails> | PutEffect, void, SprintDetails> {
  try {
    const data = yield deleteSprint(sprintId);
    yield put(deleteSprintSuccessAction(data));
    yield put(openSnackBarAction({ message: 'sprint.sprint_deleted', severity: 'success' }));
  } catch (error) {
    yield put(deleteSprintFailureAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  let message = error.message;
  if (!message) {
    message = error.status ? 'server' : 'network';
  }
  yield put(openSnackBarAction({ message: 'error.' + message, severity: 'error' }));
}
export function* watchBacklog() {
  yield all([
    takeLatest(deleteSprintAction.type, deleteSprintEffect),
    takeLatest(loadBacklogAction.type, loadBacklogEffect),
    takeLatest(createSprintAction.type, createSprintEffect),
    takeLatest(updateSprintAction.type, updateSprintEffect),
    takeLatest(loadBacklogFailureAction.type, failEffect),
    takeLatest(createSprintFailureAction.type, failEffect),
    takeLatest(updateSprintFailureAction.type, failEffect),
  ]);
}
