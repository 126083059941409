import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { MuiBtnGrp } from '../BtnGroup/btn-group.component';

interface Props {
  open: boolean;
  handleOpen?: () => void;
  message?: string;
  action?: () => void;
  hasAction?: boolean;
  body?: JSX.Element;
  save?: boolean;
  saving?: boolean;
  saveIsDisabled?: boolean;
}

const AlertComponent: React.FC<Props> = ({
  save = false,
  saving = false,
  body = null,
  hasAction = true,
  open,
  message = '',
  saveIsDisabled = false,
  action = () => {},
  handleOpen = () => {},
}) => {
  const handleSubmit = () => {
    action();
    handleOpen();
  };
  return (
    <Dialog open={open} onClose={handleOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      {message && <DialogTitle id="alert-dialog-title">{message}</DialogTitle>}
      {body}
      {hasAction && (
        <DialogActions>
          <MuiBtnGrp
            saveIsDisabled={saveIsDisabled}
            save={save}
            saving={saving}
            submit={false}
            handleCancel={() => handleOpen()}
            handleSubmit={() => handleSubmit()}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};
export const Alert = React.memo(AlertComponent);
