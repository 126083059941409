import { createReducer, Action } from '@reduxjs/toolkit';
import { RecommendationDetails } from 'core/models';
import {
  createRecommendationAction,
  createRecommendationFailedAction,
  createRecommendationSucceededAction,
  getRecommendationsAction,
  getRecommendationsFailedAction,
  getRecommendationsSuccessAction,
  removeRecommendationAction,
  removeRecommendationFailedAction,
  removeRecommendationSuccessAction,
} from './recommendation.actions';

interface RecommendationState {
  loading: boolean;
  recommendations: RecommendationDetails[];
  addingRecommendation: boolean;
  removingRecommendation: boolean;
}
const initialState: RecommendationState = {
  removingRecommendation: false,
  loading: false,
  addingRecommendation: false,
  recommendations: [] as RecommendationDetails[],
};

const _recommendationReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(createRecommendationAction, (state) => ({ ...state, addingRecommendation: true }))
    .addCase(createRecommendationSucceededAction, (state, { payload }) => ({ ...state, addingRecommendation: false, recommendations: payload }))
    .addCase(createRecommendationFailedAction, (state) => ({ ...state, addingRecommendation: false }))

    .addCase(getRecommendationsAction, (state) => ({ ...state, loading: true }))
    .addCase(getRecommendationsSuccessAction, (state, { payload }) => ({ ...state, loading: false, recommendations: payload }))
    .addCase(getRecommendationsFailedAction, (state) => ({ ...state, loading: false }))

    .addCase(removeRecommendationAction, (state) => ({ ...state, removingRecommendation: true }))
    .addCase(removeRecommendationSuccessAction, (state, { payload }) => ({ ...state, removingRecommendation: false, recommendations: payload }))
    .addCase(removeRecommendationFailedAction, (state) => ({ ...state, removingRecommendation: false })),
);

export const recommendationReducer = (state: RecommendationState | undefined, action: Action) => _recommendationReducer(state, action);
