import { TaskDto } from 'core/generated/TaskDTO';
import { HttpParamsType, TaskDetails } from 'core/models';
import { apiUrlMatcher, ApiUrls, post, put } from './helpers';

export const createTask = async (params: HttpParamsType<TaskDto>): Promise<TaskDetails> => {
  const data = await post<TaskDto>(apiUrlMatcher(ApiUrls.addTask), params);
  return TaskDetails.mapFromApiValue(data);
};
export const updateTask = async (params: HttpParamsType<TaskDto>): Promise<TaskDetails> => {
  const id = params.body?._id;
  const data = await put<TaskDto>(apiUrlMatcher(ApiUrls.updateTask) + '/' + id, params);
  return TaskDetails.mapFromApiValue(data);
};
