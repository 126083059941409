import { createTheme } from '@mui/material';
import { getPersistedTheme } from 'core/utils';
import { colors } from './shared-theme';

export const getCalendarTheme = (defaultTheme?: string[]) => {
  const theme = defaultTheme && defaultTheme.length ? defaultTheme : getPersistedTheme();

  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: { main: secondaryColor },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '& div': {
              '&.container': {
                border: '1px solid rgba(0,0,0,0.2)',
                borderRadius: '10px',
                width: '95%',
                height: '90vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            '&.calendar-loading': {},
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.tooltip_appointment': {
              paddingRight: '10px',
              color: colors.GREY,
            },
          },
        },
      },
    },
  });
};
