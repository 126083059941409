import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography } from '@mui/material';
import { InputElement, MuiBtnGrp } from 'shared/components';
import { TeamMemberDetails, ProjectDetails, TaskDetails } from 'core/models';
import { TaskTypes } from 'core/enums';
import { validateDuration } from 'core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskAction } from './state/task.actions';
import { SprintDetails } from 'core/models/sprint/sprint-details';
import { AppState } from 'store';

interface Props {
  project?: ProjectDetails;
  sprint?: SprintDetails;
  handleClose?: () => void;
}

const Task: React.FC<Props> = ({ project = {} as ProjectDetails, handleClose = () => {}, sprint = {} as SprintDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [task, setTask] = useState({} as TaskDetails);
  const { theTaskBeingCreated } = useSelector((state: AppState) => state.backlog);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (field: string, value: TeamMemberDetails | string) => {
    setTask({ ...task, [field]: value });
  };
  const handlePriorityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const priority = +e.target.value;
    if (priority < 0 || priority > 5) return;
    setTask({ ...task, priority });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(createTaskAction({ ...task, sprint: sprint.id }));
    handleClose();
  };

  const taskTypes = Object.values(TaskTypes);
  const fields = [
    { name: 'key', label: t('task.key'), value: task.key, type: 'text', handleChange },
    { name: 'title', label: t('task.title'), value: task.title, type: 'text', handleChange },
    { name: 'description', label: t('task.description'), value: task.description, type: 'text', handleChange, multiline: true },
    {
      name: 'type',
      label: t('task.type'),
      value: task.type,
      type: 'drop-down',
      handleChange: (v: string) => handleSelectChange('type', v),
      options: taskTypes,
      optionIsName: false,
      model: 'task',
      withIcon: true,
      optionIsSimpleValue: true,
    },
    {
      name: 'estimatedDuration',
      label: t('task.estimatedDuration'),
      placeholder: ['weeks', 'days', 'hours', 'minutes'].reduceRight((acc, curr) => t('common.' + curr) + ' ' + acc),
      value: task.estimatedDuration,
      type: 'text',
      handleChange,
    },
    { name: 'priority', label: t('task.priority'), value: task.priority, type: 'number', handleChange: handlePriorityChange, required: true },
    {
      name: 'assignee',
      label: t('task.assignee'),
      value: task.assignee,
      type: 'drop-down',
      options: project?.team,
      handleChange: (v: TeamMemberDetails) => handleSelectChange('assignee', v),
      required: true,
    },
    {
      name: 'reporter',
      label: t('task.reporter'),
      value: task.reporter,
      type: 'drop-down',
      required: true,
      options: project?.team,
      handleChange: (v: TeamMemberDetails) => handleSelectChange('reporter', v),
    },
  ];
  return (
    <Container component="form" onSubmit={handleSubmit} className="task_creator_container">
      <Typography variant="h4" gutterBottom align="center">
        {t('task.create_task')}
      </Typography>
      <Grid container spacing={2}>
        {fields.map((field, i) => (
          <Grid key={i} item xs={11}>
            <InputElement {...field} />
          </Grid>
        ))}
      </Grid>
      <MuiBtnGrp
        save={true}
        saving={theTaskBeingCreated?.sprint === sprint.id && !!sprint.id}
        submit={true}
        saveIsDisabled={!validateDuration(task.estimatedDuration)}
        handleCancel={handleClose}
      />
    </Container>
  );
};

export default Task;
