import { createReducer, Action } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';
import { connectAction, connectFailAction, connectSuccessAction, disconnectAction } from './socket.actions';

interface SocketState {
  socket: Socket;
  connecting: boolean;
}

const initialState = {
  socket: {} as Socket,
  connecting: true,
};
const _socketReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(connectAction, (state) => ({ ...state, connecting: true }))
    .addCase(connectSuccessAction, (state, { payload }) => ({ ...state, socket: payload, connecting: false }))
    .addCase(connectFailAction, (state) => ({ ...state, connecting: false }))

    .addCase(disconnectAction, (state) => {
      state.socket.off();
      state.socket.close();
      return initialState;
    }),
);

export const socketReducer = (state: SocketState | undefined, action: Action) => _socketReducer(state, action);
