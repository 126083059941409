import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData, EventDetails } from 'core/models';

export const getEventsAction = createAction('get events');
export const getEventsSuccessAction = createAction<EventDetails[]>('get events success');
export const getEventsFailAction = createAction<ErrorResponseData>('get events fail');

export const createEventAction = createAction<EventDetails>('create event');
export const createEventSuccessAction = createAction<EventDetails[]>('create event success');
export const createEventFailAction = createAction<ErrorResponseData>('create event fail');

export const updateEventAction = createAction<EventDetails>('update event');
export const updateEventSuccessAction = createAction<EventDetails[]>('update event success');
export const updateEventFailAction = createAction<ErrorResponseData>('update event fail');

export const deleteEventAction = createAction<string>('delete event');
export const deleteEventSuccessAction = createAction<EventDetails[]>('delete event success');
export const deleteEventFailAction = createAction<ErrorResponseData>('delete event fail');
