import { IDocs } from 'core/generated/fetchedData';
import { UserDTO } from 'core/generated/UserDto';
import { QueryData, UsersDetails } from 'core/models';
import { apiUrlMatcher, ApiUrls, get } from './helpers';

// loads all users from the database based on a query string
export const loadUsers = async (query?: QueryData): Promise<IDocs<UsersDetails[]>> => {
  const data = await get<IDocs<UserDTO[]>>(apiUrlMatcher(ApiUrls.getUsers), { queryParams: query });
  return { ...data, docs: data.docs.map((user) => UsersDetails.mapFromApiValue(user)) };
};
