/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getInputTheme } from 'shared/themes';

interface InputProps {
  name?: string;
  type?: string;
  value?: string;
  handleChange?: any;
  handleBlur?: any;
  error?: string;
  label?: string;
  touched?: boolean;
  placeholder?: string;
  multiline?: boolean;
  endAdornment?: JSX.Element;
  className?: string;
}

export const Input: React.FC<InputProps> = ({
  label,
  multiline,
  handleChange,
  handleBlur,
  type,
  value,
  touched,
  error,
  placeholder,
  name = '',
  className = '',
  endAdornment = null,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  // prevents copy/paste of password's field
  const handleCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (type === 'password') {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };
  return (
    <ThemeProvider theme={getInputTheme()}>
      <TextField
        className={className}
        id={name}
        name={name}
        multiline={multiline ? multiline : false}
        type={showPassword ? 'text' : type}
        placeholder={placeholder || ''}
        label={label}
        fullWidth={true}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        minRows={multiline ? 3 : 1}
        maxRows={4}
        helperText={(touched || error) && <span>{touched && error && t('error.' + error)}</span>}
        variant="outlined"
        InputProps={{
          endAdornment:
            type === 'password' ? (
              <InputAdornment position="end">
                <IconButton aria-label="password*" onClick={() => setShowPassword((prevValue: boolean) => !prevValue)}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ) : endAdornment ? (
              endAdornment
            ) : null,
        }}
        onCopy={handleCopyPaste}
        onCut={handleCopyPaste}
        onCutCapture={handleCopyPaste}
        onCopyCapture={handleCopyPaste}
        onPaste={handleCopyPaste}
        onPasteCapture={handleCopyPaste}
      />
    </ThemeProvider>
  );
};
