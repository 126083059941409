import { createTheme } from '@mui/material';
import { colors } from './shared-theme';

export const getBacklogTheme = (theme?: string[]) => {
  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;
  return createTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    },
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingTop: ' 10px',
            backgroundColor: 'white',
            '&.edit_sprint': {
              paddingBottom: ' 10px',
              borderRadius: '10px',
            },
            '&.task_creator_container': {
              borderRadius: '10px',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.page_title': {
              color: colors.GREY,
            },
            '&.task_key': {
              color: colors.GREY_DARK,
            },
            '&.date': {
              color: colors.GREY,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.create_issue_btn': {
              paddingTop: '10px',
            },
            '&.task_status_btn': {
              color: colors.GREY_DARK,
              background: colors.HOVER_COLOR,
              margin: '0 10px',
            },
            '&.duration': {
              color: colors.GREY_DARK,
              width: '20px',
              padding: '0',
              '&:hover': {
                backgroundColor: colors.HOVER_COLOR,
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.sprints_container': {
              background: colors.HOVER_COLOR,
              padding: '5px',
              margin: '10px 5px',
            },
            '&.MuiMenu-paper': {
              border: '2px solid',
              borderColor: colors.HOVER_COLOR,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            background: 'white',
            '&.sprint_list': {
              margin: '5px',
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: primaryColor,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            marginRight: '0 5px',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.arrow_forward_icon': {
              color: colors.GREY,
              fontSize: '15px',
              margin: '0 10px',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });
};
