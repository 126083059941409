import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponseData, UserDetails } from 'core/models';
import { UserDTO } from 'core/generated/UserDto';
import { updateUser } from 'core/services/user.service';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { updateProfileAction, updateProfileFailureAction } from './profile.actions';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { loadUser, logoutAction } from 'modules/auth/state/auth.actions';
import { localStorageKeys } from 'core/enums';

function* updateProfileEffect({ payload }: PayloadAction<UserDTO>): Generator<Promise<UserDetails> | PutEffect, void | string, UserDetails> {
  try {
    const data = UserDetails.mapToFormData(payload);
    const user = yield updateUser({ body: data, multiPart: true, queryParams: { id: payload._id } });
    const token = localStorage.getItem(localStorageKeys.token) || '';
    // update the theme value after a user successful update
    localStorage.setItem(localStorageKeys.theme, JSON.stringify(user.theme));
    if (!token) {
      yield put(logoutAction());
    }
    yield put(loadUser({ ...user, token }));
    yield put(openSnackBarAction({ message: 'profile.profile_updated', severity: 'success' }));
  } catch (error) {
    yield put(updateProfileFailureAction(error as ErrorResponseData));
  }
}
function* updateProfileFailureEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  yield put(
    openSnackBarAction({
      message: error.status ? 'error.server' : 'error.network',
      severity: 'error',
    }),
  );
}

export function* watchProfile() {
  yield all([takeLatest(updateProfileAction.type, updateProfileEffect), takeLatest(updateProfileFailureAction.type, updateProfileFailureEffect)]);
}
