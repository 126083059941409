import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { MessageDetails, TeamMemberDetails } from 'core/models';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Attachements, AvatarIcon, MuiImageList } from 'shared/components';
import { AppState } from 'store';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
interface Props {
  message: MessageDetails;
}

const Message: React.FC<Props> = ({ message: { sender, text, files, createdAt } }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: AppState) => state.auth);
  // test if sender is not a removed user
  if (!sender) {
    sender = {
      id: null,
      firstName: t('chatbox.removed_user'),
      lastName: t('chatbox.removed_user'),
    } as unknown as TeamMemberDetails;
  }

  const senderIsconnected = sender?.id === user.id;
  const senderName = sender?.firstName + ' ' + sender?.lastName;
  // // removing unnecessary characters from filename
  // files = (files as string[]).map((file) => file.replace('uploads/', ''));

  const images = (files as string[]).filter((file) => file.includes('.png') || file.includes('.jpg') || file.includes('.jpeg'));
  const attachments = (files as string[]).filter((file) => !images.includes(file));
  console.log(files);
  const timeAgo = moment(createdAt).fromNow();
  return (
    <ListItem>
      {!senderIsconnected && (
        <ListItemAvatar>
          {sender.id ? <AvatarIcon name={senderName} avatar={sender.avatar} /> : <AccountCircleIcon className="avatar_icon" />}
        </ListItemAvatar>
      )}
      <ListItemText
        disableTypography={true}
        primary={
          <Typography color="gray" align={senderIsconnected ? 'right' : 'left'}>
            {!senderIsconnected && sender.firstName + ','}
            {timeAgo}
          </Typography>
        }
        secondary={
          <div className={senderIsconnected ? 'message_body_container_self' : 'message_body_container'}>
            <div className={senderIsconnected ? 'message_body_self' : 'message_body'}>
              <Typography paragraph={true}>{text}</Typography>
              {attachments.length !== 0 && (
                <Attachements
                  fileStyles={'file_download_btn' + (senderIsconnected ? '_self' : '')}
                  documents={attachments}
                  simpleDisplay={true}
                  hasAction={false}
                />
              )}
              {images.length !== 0 && <MuiImageList images={images} />}
            </div>
          </div>
        }
      />
    </ListItem>
  );
};

export default React.memo(Message);
