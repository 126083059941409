import { createTheme } from '@mui/material';
import { colors } from './shared-theme';

export const getTheme = (theme?: string[]) => {
  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;
  return createTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: secondaryColor,
              borderRadius: '50px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: primaryColor,
              borderRadius: '50px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& div': {
              display: 'flex',
              alignItems: 'center',
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            '&.recommendation_fab': {
              position: 'absolute',
              bottom: '5%',
              right: '5%',
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.menu_icon': {
              '&:hover': {
                color: colors.WHITE,
              },
            },
            '&.star_icon': {
              color: colors.GOLD,
            },
            '&.folder_icon': {
              color: colors.DEEP_RED,
              transform: 'scale(1.3)',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            border: 'none',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.upload_btn': {
              color: colors.WHITE,
              fontWeight: '400',
            },
          },
        },
      },
    },
  });
};
