import { TaskDto } from 'core/generated/TaskDTO';
import { TeamMemberDetails } from '../teamMember/team-member-details';
import { I } from '../user/user-details';

export interface TaskDetails extends I {
  id: string;
  key: string;
  title: string;
  type: string;
  status: string;
  description: string;
  estimatedDuration: string;
  sprint?: string;
  duration: string;
  priority: number;
  assignee: TeamMemberDetails;
  progress: number;
  reporter: TeamMemberDetails;
  createdAt?: string;
  updatedAt?: string;
}
export namespace TaskDetails {
  export const mapFromApiValue = (task: TaskDto): TaskDetails => {
    const taskDetails = {} as TaskDetails;
    Object.keys(task).forEach((key: string) => {
      if (task[key]) {
        if (key === '_id') {
          taskDetails['id'] = task['_id'];
        } else if (key === 'assignee' || key === 'reporter') {
          taskDetails[key] = TeamMemberDetails.mapFromApiValue(task[key]);
        } else {
          taskDetails[key] = task[key];
        }
      }
    });
    return taskDetails;
  };

  export const mapToApiValue = (task: TaskDetails): TaskDto => {
    const taskDto = {} as TaskDto;
    Object.keys(task).forEach((key: string) => {
      if (task[key]) {
        if (key === 'id') {
          taskDto['_id'] = task['id'];
        } else if (key === 'assignee' || key === 'reporter') {
          taskDto[key] = TeamMemberDetails.mapToApiValue(task[key]);
        } else {
          taskDto[key] = task[key];
        }
      }
    });
    return taskDto;
  };
}
