import React, { useEffect } from 'react';
import RecommendationList from 'modules/Recommendation/recommendations-list.component';

export const RecommendationsPage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Recommendations page';
  }, []);
  return <RecommendationList />;
};
