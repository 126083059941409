import { AppBar, Typography, Toolbar, IconButton, Tooltip, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { AvatarIcon, LogoutIconComponent } from 'shared/components';
import logo from '../../assets/static/pfe-logo.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/enums/routes';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import Notifications from 'modules/Notifications/notification.component';
import { getNavbarTheme } from 'shared/themes';

const MuiAppBar: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: AppState) => state.auth);
  const mainMenu = [routes.projects, routes.users, routes.profiles, routes.recommendations].map((r) => r.replace('/', ''));
  const menuItems: string[] =
    user?.profile?.sections?.map(({ value }: { value: string }) => value).filter((item: string) => mainMenu.includes(item)) || [];
  return (
    <ThemeProvider theme={getNavbarTheme(user.theme)}>
      <AppBar position="static">
        <Toolbar variant="regular">
          <Link to="/">
            <Box component="img" src={logo} alt="logo" maxHeight={50} />
          </Link>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-around', flexGrow: 1 }}>
            {menuItems.map((item, i) => (
              <Link to={`/${item}`} key={'item-' + i}>
                <Typography>{t('menu.' + item)}</Typography>
              </Link>
            ))}
          </Box>
          <Box>
            <Notifications />
            <Link to={routes.profile}>
              <Tooltip title={t('profile.open_profile') as string}>
                <IconButton>
                  <AvatarIcon name={user.firstName + ' ' + user.lastName} avatar={user.avatar} width={30} height={30} />
                </IconButton>
              </Tooltip>
            </Link>
            <LogoutIconComponent />
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default React.memo(MuiAppBar);
