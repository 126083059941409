import React, { Fragment, useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { ChatDetails } from 'core/models';
import { AvatarIcon, Input, Alert, MuiSelect } from 'shared/components';
import { navigate } from 'core/services/history.service';
import { routes } from 'core/enums';
import { useTranslation } from 'react-i18next';
import { Add as AddIcon, FiberManualRecord as FiberManualRecordIcon, MoreVert as MoreVertIcon } from '@mui/icons-material/';
import { AppState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { createChatAction, setChatAsReadAction } from '../state/chat.actions';
import { useParams } from 'react-router-dom';
import { UsersList } from './chat-members.component';

const ChatList: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    auth: { user },
    users: { users },
    chatbox: { chatIsbeingCreated, chatbox },
  } = useSelector((state: AppState) => state);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [chatTocreate, setChatTocreate] = useState({} as ChatDetails);
  const [selectedChat, setSelectedChat] = useState<null | ChatDetails | undefined>(null);

  const showChatDetails = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, chatId: string) => {
    e.stopPropagation();
    setSelectedChat(chatbox.find((chat) => chat.id === chatId));
  };

  return (
    <>
      <List className="chatlist">
        <ListItem className="create_grp_btn_container">
          <Button startIcon={<AddIcon />} variant="contained" onClick={() => setOpen(true)}>
            {t('chatbox.create_group')}
          </Button>
        </ListItem>
        {chatbox.map((chat) => (
          <Fragment key={chat.id}>
            <ListItemButton
              className={chat.id === id ? 'current_chat' : ''}
              onClick={() => {
                navigate(routes.chatbox, chat.id);
                dispatch(setChatAsReadAction(chat.id));
              }}
            >
              <ListItemAvatar>
                <AvatarIcon name={chat.name} />
              </ListItemAvatar>
              <ListItemText primary={chat.name} />
              <ListItemSecondaryAction>
                {chat.notRead && <FiberManualRecordIcon className="new_message_icon" />}
                {chat.admin.id === user.id && (
                  <IconButton onClick={(e) => showChatDetails(e, chat.id)}>
                    <MoreVertIcon className={chat.id === id ? 'current_chat_tools' : ''} />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItemButton>
            <Divider />
          </Fragment>
        ))}
      </List>
      {/* create chat */}
      <Alert
        open={open || chatIsbeingCreated}
        save={true}
        saving={chatIsbeingCreated}
        saveIsDisabled={!chatTocreate.name}
        handleOpen={() => {
          setOpen(!open);
        }}
        action={() => {
          dispatch(createChatAction(chatTocreate));
          setChatTocreate({} as ChatDetails);
        }}
        body={
          <Stack padding={2} spacing={2} width="35vw" height="40vh" component="form">
            <Typography variant="h6" gutterBottom align="center" color="gray">
              {t('chatbox.create_group').toLocaleUpperCase()}!
            </Typography>
            <Input
              label={t('chatbox.group_name')}
              value={chatTocreate.name ?? ''}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setChatTocreate({ ...chatTocreate, name: e.target.value })}
            />
            <MuiSelect
              name="members"
              label={t('chatbox.select_members')}
              options={users.filter((el) => el.id !== user.id)}
              multiple={true}
              value={chatTocreate.members ?? []}
              handleChange={(members) => setChatTocreate({ ...chatTocreate, members })}
            />
          </Stack>
        }
      />
      {/* edit chat */}
      <Alert
        open={!!selectedChat}
        // save={true}
        // saving={chatIsbeingCreated}
        // saveIsDisabled={!chatTocreate.name}
        handleOpen={() => {
          setSelectedChat(null);
        }}
        hasAction={false}
        body={<UsersList selectedChat={selectedChat} />}
      />
    </>
  );
};

export default React.memo(ChatList);
