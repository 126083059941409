import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { SocketEvents } from 'core/enums';
import { UsersDetails } from 'core/models';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AvatarIcon } from 'shared/components';
import { AppState } from 'store';

const ConnectedUsersList: React.FC = () => {
  const {
    auth: { user },
    socket: { socket },
    users: { users },
  } = useSelector((state: AppState) => state);
  const { t } = useTranslation();

  const [connectUsers, setconnectUsers] = useState<UsersDetails[]>([] as UsersDetails[]);

  useEffect(() => {
    if (users.length) {
      socket.emit(SocketEvents.getConnectedUsers);
      socket.on(SocketEvents.getConnectedUsersSuccess, (payload) => {
        const usersList = users.filter(({ id }) => payload.find((userId: string) => id === userId) && id !== user.id);
        setconnectUsers(usersList);
      });
    }
    return () => {
      socket.off();
    };
  }, [users.length]);

  return (
    <List className="connected_users_list">
      <Typography color="gray" align="center">
        {t('chatbox.connected_users')}
      </Typography>
      {connectUsers?.map((connectedUser) => {
        const name = connectedUser.firstName + ' ' + connectedUser.lastName;
        return (
          <ListItem key={connectedUser.id}>
            <ListItemAvatar>
              <AvatarIcon hasBadge={true} name={name} avatar={connectedUser.avatar} />
            </ListItemAvatar>
            <ListItemText primary={name} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default React.memo(ConnectedUsersList);
