import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData, ProjectDetails } from 'core/models';
import { ProjectDetails as LessProjectDetails } from 'core/models/project/project-details';

export const addProjectAction = createAction<ProjectDetails>('add project');
export const updateProjectAction = createAction<ProjectDetails>('update project');
export const deleteProjectAction = createAction<string>('delete project');

export const deleteProjectSuccessAction = createAction<LessProjectDetails[]>('delete project success');
export const failAction = createAction<ErrorResponseData>('failed');

export const restoreProjectAction = createAction<string>('restore project');
export const restoreProjectSuccessAction = createAction<LessProjectDetails[]>('restore project success');

export const uploadDocsAction = createAction<ProjectDetails>('upload files');
export const removeDocsAction = createAction<string[]>('remove files');
export const downloadDocsAction = createAction<{ apiFilename: string; filename: string }>('download files');
