import { PayloadAction } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ErrorResponseData, QueryData, UsersDetails } from 'core/models';
import { loadUsers } from 'core/services/users.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { put, all, takeLatest, PutEffect } from 'redux-saga/effects';
import { loadUsersAction, loadUsersFailedAction, loadUsersSuccessAction } from './users.actions';

function* loadUsersEffect({ payload }: PayloadAction<QueryData>): Generator<Promise<IDocs<UsersDetails[]>> | PutEffect, void, IDocs<UsersDetails[]>> {
  try {
    const data = yield loadUsers(payload);
    yield put(loadUsersSuccessAction(data));
  } catch (error) {
    yield put(loadUsersFailedAction(error as ErrorResponseData));
  }
}

function* loadUsersFailedEffect(error: ErrorResponseData) {
  yield put(
    openSnackBarAction({
      message: error.message,
      severity: 'error',
    }),
  );
}

export function* watchUsers() {
  yield all([takeLatest(loadUsersAction.type, loadUsersEffect), takeLatest(loadUsersFailedAction.type, loadUsersFailedEffect)]);
}
