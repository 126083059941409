import { SprintDto } from 'core/generated/SprintDTO';
import { TaskDetails } from '../task/task-details';
import { I } from '../user/user-details';

export interface SprintDetails extends I {
  id: string;
  title: string;
  tasks: TaskDetails[];
  project: string;
  goal?: string;
  startDate?: Date;
  endDate?: Date;
  createdAt?: string;
  updatedAt?: string;
}
export namespace SprintDetails {
  export const mapFromApiValue = (sprint: SprintDto): SprintDetails => {
    const sprintDetails = {} as SprintDetails;
    Object.keys(sprint).forEach((key: string) => {
      if (sprint[key]) {
        if (key === '_id') {
          sprintDetails.id = sprint._id;
        } else if (key === 'tasks') {
          sprintDetails['tasks'] = sprint['tasks'].map((task) => TaskDetails.mapFromApiValue(task));
        } else {
          sprintDetails[key] = sprint[key];
        }
      }
    });
    return sprintDetails;
  };
  export const mapToApiValue = (sprint: SprintDetails): SprintDto => {
    const sprintDetails = {} as SprintDto;
    Object.keys(sprint).forEach((key: string) => {
      if (sprint[key]) {
        if (key === 'id') {
          sprintDetails._id = sprint.id;
        } else if (key === 'tasks') {
          sprintDetails['tasks'] = sprint['tasks'].map((task) => TaskDetails.mapToApiValue(task));
        } else {
          sprintDetails[key] = sprint[key];
        }
      }
    });
    return sprintDetails;
  };
}
