import { EventDto } from 'core/generated/EventDTO';
import { EventDetails, HttpParamsType } from 'core/models';
import { apiUrlMatcher, ApiUrls, get, post, put, _delete } from './helpers';

export const getEvents = async (): Promise<EventDetails[]> => {
  const data = await get<EventDto[]>(apiUrlMatcher(ApiUrls.getEvents));
  return data.map((event) => EventDetails.mapFromApiValue(event));
};

export const addEvent = async (params: HttpParamsType<EventDto>): Promise<EventDetails[]> => {
  const data = await post<EventDto[]>(apiUrlMatcher(ApiUrls.addEvent), params);
  return data.map((event) => EventDetails.mapFromApiValue(event));
};

export const updateEvent = async (params: HttpParamsType<EventDto>): Promise<EventDetails[]> => {
  const id = params.body?._id;
  const data = await put<EventDto[]>(apiUrlMatcher(ApiUrls.updateEvent) + '/' + id, params);
  return data.map((event) => EventDetails.mapFromApiValue(event));
};
export const deleteEvent = async (id: string): Promise<EventDetails[]> => {
  const data = await _delete<EventDto[]>(apiUrlMatcher(ApiUrls.deleteEvent) + '/' + id);
  return data.map((event) => EventDetails.mapFromApiValue(event));
};
