import { createReducer, Action } from '@reduxjs/toolkit';
import { EventDetails } from 'core/models';
import {
  createEventAction,
  createEventFailAction,
  createEventSuccessAction,
  deleteEventSuccessAction,
  getEventsAction,
  getEventsFailAction,
  getEventsSuccessAction,
  updateEventAction,
  updateEventFailAction,
  updateEventSuccessAction,
} from './calendar.actions';

interface CalendarState {
  events: EventDetails[];
  loading: boolean;
  eventIsBeingUpdated: boolean;
  eventIsBeingCreated: boolean;
}
const initialState: CalendarState = {
  events: [] as EventDetails[],
  loading: false,
  eventIsBeingUpdated: false,
  eventIsBeingCreated: false,
};

const _calendarReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getEventsAction, (state) => ({ ...state, loading: true }))
    .addCase(getEventsSuccessAction, (state, { payload }) => ({ ...state, loading: false, events: payload }))
    .addCase(getEventsFailAction, (state) => ({ ...state, loading: false }))

    .addCase(createEventAction, (state) => ({ ...state, eventIsBeingCreated: true }))
    .addCase(createEventSuccessAction, (state, { payload }) => ({ ...state, eventIsBeingCreated: false, events: payload }))
    .addCase(createEventFailAction, (state) => ({ ...state, eventIsBeingCreated: false }))

    .addCase(updateEventAction, (state) => ({ ...state, eventIsBeingUpdated: true }))
    .addCase(updateEventSuccessAction, (state, { payload }) => ({ ...state, eventIsBeingUpdated: false, events: payload }))
    .addCase(updateEventFailAction, (state) => ({ ...state, eventIsBeingUpdated: false }))

    .addCase(deleteEventSuccessAction, (state, { payload }) => ({ ...state, events: payload })),
);
export const calendarReducer = (state: CalendarState | undefined, action: Action) => _calendarReducer(state, action);
