import React from 'react';
import { FormikValues } from 'shared/interface';
import { emailValidationSchema } from 'shared/validator';
import { FormContainer } from 'shared/components';
import { useDispatch } from 'react-redux';
import { recoverPwAction } from './state/password.actions';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { Login as LoginIcon } from '@mui/icons-material/';
import { history } from 'core/services/history.service';
import { routes } from 'core/enums/routes';
import styles from './mailing.module.scss';
import { useTranslation } from 'react-i18next';

const initialValues: FormikValues = {
  email: '',
};
const Mail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // dispatches password recover action
  const submitHandler = (data: FormikValues) => {
    dispatch(recoverPwAction(data));
  };
  return (
    <FormContainer
      submitType="recover_mail"
      submitHandler={submitHandler}
      validationSchema={emailValidationSchema}
      initialValues={initialValues}
      headerElement={
        <Stack direction="row" justifyContent="flex-start">
          <Tooltip title={t('login.back_to_login').toString()}>
            <IconButton className={styles.icon_btn} onClick={() => history.push(routes.login)}>
              <LoginIcon className={styles.icon} />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    />
  );
};

export default Mail;
