import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData, QueryData } from 'core/models';
import { ProjectDetails } from 'core/models/project/project-details';

export const loadProjectsAction = createAction<QueryData | undefined>('load projects');
export const loadProjectsSuccessAction = createAction<{
  projects: ProjectDetails[];
  total: number;
}>('load projects success');
export const loadProjectsFailedAction = createAction<ErrorResponseData>('load projects failed');
