import { IDocs } from 'core/generated/fetchedData';
import { ProjectDto } from 'core/generated/ProjectDto';
import { QueryData } from 'core/models';
import { ProjectDetails } from 'core/models/project/project-details';
import { apiUrlMatcher, ApiUrls, get } from './helpers';

// loads all projects from the database based on a query string

export const loadProjects = async (query: QueryData): Promise<IDocs<ProjectDetails[]>> => {
  const data = await get<IDocs<ProjectDto[]>>(apiUrlMatcher(ApiUrls.getProjects), { queryParams: query });
  return { ...data, docs: data.docs.map((project) => ProjectDetails.mapFromApiValue(project)) };
};
