import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponseData, UserDetails } from 'core/models';
import { addUser, deleteUser, updateUser } from 'core/services/user.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { addUserAction, deleteUserAction, failAction, updateUserAction } from './user.actions';
import { loadUsersAction } from '../../usersList/state/users.actions';
import { navigate } from 'core/services/history.service';
import { routes } from 'core/enums/routes';

function* addUserEffect({ payload }: PayloadAction<UserDetails>): Generator<Promise<UserDetails> | PutEffect | void, void, UserDetails> {
  try {
    yield addUser({ body: UserDetails.mapToApiValue(payload) });
    yield put(openSnackBarAction({ message: 'user.add_user_success', severity: 'success' }));
    yield navigate(routes.users);
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* updateUserEffect({ payload }: PayloadAction<UserDetails>): Generator<Promise<UserDetails> | PutEffect | void, void, UserDetails> {
  try {
    yield updateUser({ body: UserDetails.mapToApiValue(payload), queryParams: { id: payload.id } });
    yield put(openSnackBarAction({ message: 'user.update_user_success', severity: 'success' }));
    yield navigate(routes.users);
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* deleteUserEffect({ payload }: PayloadAction<string>) {
  try {
    yield deleteUser(payload);
    yield put(loadUsersAction());
    yield put(openSnackBarAction({ message: 'user.user_remove_success', severity: 'success' }));
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  yield put(
    openSnackBarAction({
      message: error.status ? 'error.server' : 'error.network',
      severity: 'error',
    }),
  );
}

export function* watchUser() {
  yield all([
    takeLatest(addUserAction.type, addUserEffect),
    takeLatest(updateUserAction.type, updateUserEffect),
    takeLatest(deleteUserAction.type, deleteUserEffect),
    takeLatest(failAction.type, failEffect),
  ]);
}
