import Login from 'modules/auth/auth.component';
import React, { useEffect } from 'react';

export const LoginPage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Login page';
  }, []);
  return <Login />;
};
