import React from 'react';
import { ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography } from '@mui/material';
import { AvatarIcon } from '../Avatar/avatar.component';

export const ListItemLoader: React.FC = () => {
  return (
    <ListItemButton>
      <Skeleton>
        <ListItemAvatar>
          <AvatarIcon name={''} />
        </ListItemAvatar>
      </Skeleton>
      <ListItemText
        disableTypography={true}
        primary={
          <Typography>
            {<Skeleton />}
            <Typography color="gray" component="span" variant="body2" className="notification_time">
              <Skeleton />
            </Typography>
          </Typography>
        }
        secondary={<Skeleton />}
      />
    </ListItemButton>
  );
};
