import { SprintDto } from 'core/generated/SprintDTO';
import { HttpParamsType } from 'core/models';
import { SprintDetails } from 'core/models/sprint/sprint-details';
import { apiUrlMatcher, ApiUrls, get, post, put, _delete } from './helpers';

export const loadBacklog = async (id: string) => {
  const data = await get<SprintDto[]>(apiUrlMatcher(ApiUrls.getBacklog) + '/' + id);
  return data.map((sprint) => SprintDetails.mapFromApiValue(sprint));
};
export const createSprint = async (params: HttpParamsType<SprintDto>): Promise<SprintDetails[]> => {
  const data = await post<SprintDto[]>(apiUrlMatcher(ApiUrls.addSprint), params);
  return data.map((sprint) => SprintDetails.mapFromApiValue(sprint));
};
export const updateSprint = async (params: HttpParamsType<SprintDto>): Promise<SprintDetails[]> => {
  const id = params.body?._id;
  const data = await put<SprintDto[]>(apiUrlMatcher(ApiUrls.updateSprint) + '/' + id, params);
  return data.map((sprint) => SprintDetails.mapFromApiValue(sprint));
};
export const deleteSprint = async (id: string): Promise<SprintDetails> => {
  const data = await _delete<SprintDto>(apiUrlMatcher(ApiUrls.deleteSprint) + '/' + id);
  return SprintDetails.mapFromApiValue(data);
};
