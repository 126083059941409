import React, { Fragment, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Box,
  Typography,
  Button,
  ListItemSecondaryAction,
  MenuItem,
  Menu,
  IconButton,
  Divider,
} from '@mui/material';
import { ProjectDetails, TaskDetails } from 'core/models';
import { Alert, AvatarIcon, InputElement, MuiBtnGrp } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { taskStatusGenerator, validateDuration } from 'core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskAction } from 'modules/Task/state/task.actions';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { AppState } from '../../../store/index';

interface Props {
  task: TaskDetails;
  project: ProjectDetails;
}

export const TaskItem: React.FC<Props> = ({ project, task }) => {
  const { t } = useTranslation();
  const { theTaskBeingUpdated } = useSelector((state: AppState) => state.backlog);
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);

  const [editingTask, setEditingTask] = useState<boolean>(false);
  const [taskDetails, setTaskDetails] = useState<TaskDetails>({} as TaskDetails);
  const [assigneeAnchorEl, setAssigneeAnchorEl] = useState<null | HTMLElement>(null);
  const [taskStatusAnchorEl, setTaskStatusAnchorEl] = useState<null | HTMLElement>(null);

  const statusMenuIsOpen = Boolean(taskStatusAnchorEl);
  const assigneeMenuIsOpen = Boolean(assigneeAnchorEl);

  const estimatedDurationTooltipText = `
    ${t('task.estimatedDuration')}
    (w : ${t('common.weeks')},
    d : ${t('common.days')},
    h : ${t('common.hours')},
    m : ${t('common.minutes')})
  `;
  const theTaskIsBeingUpdated = !!theTaskBeingUpdated && theTaskBeingUpdated?.id === taskDetails?.id;
  const duractionFormat = ['weeks', 'days', 'hours', 'minutes'].reduceRight((acc, curr) => t('common.' + curr) + ' ' + acc);

  const fields = [
    {
      name: 'estimatedDuration',
      label: t('task.estimatedDuration'),
      placeholder: duractionFormat,
      value: taskDetails.estimatedDuration,
      type: 'text',
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaskDetails({ ...taskDetails, [e.target.name]: e.target.value });
      },
    },
    {
      name: 'duration',
      label: t('task.time_tracking'),
      placeholder: duractionFormat,
      value: taskDetails.duration,
      type: 'text',
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaskDetails({ ...taskDetails, [e.target.name]: e.target.value });
      },
    },
  ];

  const handleTaskStatusUpdate = (taskData: TaskDetails) => {
    dispatch(updateTaskAction(taskData));
    setTaskStatusAnchorEl(null);
  };
  const handleTaskAssigneeUpdate = (taskData: TaskDetails) => {
    dispatch(updateTaskAction(taskData));
    setAssigneeAnchorEl(null);
  };

  return (
    <Fragment>
      <ListItem sx={{ pl: 4 }}>
        <Tooltip title={t('task.' + task.type) as string} arrow>
          <ListItemIcon>
            <AvatarIcon name={task.type} />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={
            <Stack direction="row" spacing={1}>
              <Tooltip title={t('task.key') as string} arrow>
                <Box component="span">
                  <Typography className="task_key">{task.key?.toLocaleUpperCase()}</Typography>
                </Box>
              </Tooltip>
              <Tooltip title={t('task.title') as string} arrow>
                <Box component="span">{task.title}</Box>
              </Tooltip>
            </Stack>
          }
          secondary={
            <Tooltip title={estimatedDurationTooltipText} arrow>
              <Button
                className="duration"
                onClick={() => {
                  setTaskDetails(task);
                  setEditingTask(true);
                }}
              >
                {task.estimatedDuration}
              </Button>
            </Tooltip>
          }
        />
        <ListItemSecondaryAction>
          <Button
            id="status-button"
            className="task_status_btn"
            onClick={(e) => setTaskStatusAnchorEl(e.currentTarget)}
            aria-controls={statusMenuIsOpen ? 'status-menu' : undefined}
            aria-expanded={statusMenuIsOpen ? 'true' : undefined}
            endIcon={statusMenuIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {t('task.' + task.status)}
          </Button>
          <Menu
            id="status-menu"
            anchorEl={taskStatusAnchorEl}
            open={statusMenuIsOpen}
            MenuListProps={{ 'aria-labelledby': 'status-button' }}
            onClose={() => setTaskStatusAnchorEl(null)}
          >
            {taskStatusGenerator(task.status)?.map((status, k) => (
              <MenuItem
                onClick={() => {
                  handleTaskStatusUpdate({ ...task, status });
                }}
                key={k}
              >
                {t('task.' + status)}
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title={t('task.assignee') + ' : ' + task.assignee.firstName}>
            <IconButton
              id="assignee-button"
              className="task_assignee_btn"
              onClick={(e) => setAssigneeAnchorEl(e.currentTarget)}
              aria-controls={assigneeMenuIsOpen ? 'assignee-menu' : undefined}
              aria-expanded={assigneeMenuIsOpen ? 'true' : undefined}
            >
              <AvatarIcon avatar={task.assignee.avatar} name={task.assignee.firstName + ' ' + task.assignee.lastName} />
            </IconButton>
          </Tooltip>
          <Menu
            id="assignee-menu"
            anchorEl={assigneeAnchorEl}
            open={assigneeMenuIsOpen}
            MenuListProps={{ 'aria-labelledby': 'assignee-button' }}
            onClose={() => setAssigneeAnchorEl(null)}
          >
            {project?.team
              ?.filter((member) => member.id !== task.assignee.id)
              .map((member, k) => {
                const name = member.firstName + ' ' + member.lastName;
                return (
                  <MenuItem key={k} onClick={() => handleTaskAssigneeUpdate({ ...task, assignee: member, reporter: user })}>
                    <AvatarIcon name={name} avatar={member.avatar} />
                    <Typography>{name}</Typography>
                  </MenuItem>
                );
              })}
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      {/** diablog for handeling estimated duration change */}
      <Alert
        open={editingTask || theTaskIsBeingUpdated}
        handleOpen={() => {
          setEditingTask((p) => !p);
        }}
        hasAction={false}
        body={
          <Stack padding={2} width="35vw">
            <Stack padding={2} spacing={2}>
              {fields.map((field, i) => (
                <InputElement {...field} key={i} />
              ))}
            </Stack>
            <MuiBtnGrp
              handleCancel={() => {
                setEditingTask((p) => !p);
              }}
              handleSubmit={() => {
                dispatch(updateTaskAction(taskDetails));
                setEditingTask((p) => !p);
              }}
              saving={theTaskIsBeingUpdated}
              save={true}
              saveIsDisabled={
                !validateDuration(taskDetails?.estimatedDuration) || (taskDetails.duration ? !validateDuration(taskDetails?.duration) : false)
              }
            />
          </Stack>
        }
      />
    </Fragment>
  );
};
