/* eslint-disable prettier/prettier */
import { createReducer, Action } from '@reduxjs/toolkit';
import { closeSnackBarAction, openSnackBarAction } from './snackbar.actions';

interface SnackbarState {
  open: boolean;
  message: string;
  severity: string;
}
const initialState: SnackbarState = {
  open: false,
  message: '',
  severity: '',
};

const _snackbarReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(openSnackBarAction, (state, { payload }) => ({
      ...state,
      message: payload.message,
      severity: payload.severity,
      open: true,
    }))
    .addCase(closeSnackBarAction, (state) => ({ ...state, open: false })),
);

export const snackbarReducer = (state: SnackbarState | undefined, action: Action) => _snackbarReducer(state, action);
