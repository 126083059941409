import { PayloadAction } from '@reduxjs/toolkit';
import { routes } from 'core/enums/routes';
import { ErrorResponseData, ProjectDetails } from 'core/models';
import { ProjectDetails as LessProjectDetails } from 'core/models/project/project-details';
import { navigate } from 'core/services/history.service';
import { addProject, deleteProject, downloadFile, restoreProject, updateProject, uploadFiles } from 'core/services/project.service';
import { loadProjectsAction } from 'modules/ProjectsList/state/projects.actions';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  addProjectAction,
  deleteProjectAction,
  failAction,
  updateProjectAction,
  uploadDocsAction,
  downloadDocsAction,
  deleteProjectSuccessAction,
  restoreProjectAction,
  restoreProjectSuccessAction,
} from './project.actions';

function* addProjectEffect({ payload }: PayloadAction<ProjectDetails>): Generator<Promise<ProjectDetails> | PutEffect | void, void, ProjectDetails> {
  try {
    const formData = ProjectDetails.mapToFormData(ProjectDetails.mapToApiValue(payload));
    yield addProject({ body: formData, multiPart: true });
    yield put(openSnackBarAction({ message: 'project.project_add_success', severity: 'success' }));
    yield navigate(routes.projects);
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* updateProjectEffect({
  payload,
}: PayloadAction<ProjectDetails>): Generator<Promise<ProjectDetails> | PutEffect | void, void, ProjectDetails> {
  try {
    const formData = ProjectDetails.mapToFormData(ProjectDetails.mapToApiValue(payload));
    yield updateProject({ body: formData, multiPart: true, queryParams: { id: payload.id } });
    yield put(
      openSnackBarAction({
        message: 'project.project_update_success',
        severity: 'success',
      }),
    );

    yield navigate(routes.projects);
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* deleteProjectEffect({
  payload: projectId,
}: PayloadAction<string>): Generator<Promise<LessProjectDetails[]> | PutEffect, void, LessProjectDetails[]> {
  try {
    const data = yield deleteProject(projectId);
    yield put(deleteProjectSuccessAction(data));
    yield put(openSnackBarAction({ message: 'project.soft_delete_success', severity: 'success' }));
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* restoreProjectEffect({
  payload: projectId,
}: PayloadAction<string>): Generator<Promise<LessProjectDetails[]> | PutEffect, void, LessProjectDetails[]> {
  try {
    const data = yield restoreProject(projectId);
    yield put(restoreProjectSuccessAction(data));
    yield put(openSnackBarAction({ message: 'project.project_restore_success', severity: 'success' }));
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* uploadDocsEffect({
  payload: project,
}: PayloadAction<ProjectDetails>): Generator<Promise<ProjectDetails> | PutEffect, void, ProjectDetails> {
  try {
    const projectDto = ProjectDetails.mapToApiValue(project);
    const formData = ProjectDetails.mapToFormData(projectDto);
    yield uploadFiles({ body: formData, queryParams: { id: project.id }, multiPart: true });
    yield put(loadProjectsAction());
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* downloadDocsEffect({
  payload: { apiFilename, filename },
}: PayloadAction<{ apiFilename: string; filename: string }>): Generator<Promise<void> | PutEffect, void> {
  try {
    yield downloadFile(apiFilename, filename);
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  yield put(openSnackBarAction({ message: error.status ? 'error.server' : 'error.network', severity: 'error' }));
}

export function* watchProject() {
  yield all([
    takeLatest(downloadDocsAction.type, downloadDocsEffect),
    takeLatest(uploadDocsAction.type, uploadDocsEffect),
    takeLatest(addProjectAction.type, addProjectEffect),
    takeLatest(updateProjectAction.type, updateProjectEffect),
    takeLatest(deleteProjectAction.type, deleteProjectEffect),
    takeLatest(restoreProjectAction.type, restoreProjectEffect),
    takeLatest(failAction.type, failEffect),
  ]);
}
