import { PayloadAction } from '@reduxjs/toolkit';
import { IDocs } from 'core/generated/fetchedData';
import { ChatDetails, ErrorResponseData, MessageDetails } from 'core/models';
import { createChat, getChatbox, getMessages, sendMessage, updateChat } from 'core/services/chatbox.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  createChatAction,
  createChatFailAction,
  createChatSuccessAction,
  getChatsAction,
  getChatsFailAction,
  getChatsSuccessAction,
  getMessagesAction,
  getMessagesSuccessAction,
  getMessagesFailAction,
  sendMessageAction,
  sendMessageFailAction,
  sendMessageSuccessAction,
  updateChatAction,
  updateChatSuccessAction,
  updateChatFailAction,
} from './chat.actions';
import { connectUsersToChatsAction, sendMessageIRTAction } from 'modules/Socket/state/socket.actions';
import { createPushNotificationAction } from 'modules/Notifications/state/notification.actions';

function* getChatsEffect(): Generator<Promise<ChatDetails[]> | PutEffect, void, ChatDetails[]> {
  try {
    const data = yield getChatbox();
    yield put(getChatsSuccessAction(data));
    // connect the current user to his chats
    yield put(connectUsersToChatsAction(data));
  } catch (error) {
    yield put(getChatsFailAction(error as ErrorResponseData));
  }
}

function* updateChatEffect({ payload: chat }: PayloadAction<ChatDetails>): Generator<Promise<ChatDetails[]> | PutEffect, void, ChatDetails[]> {
  try {
    yield put(openSnackBarAction({ message: 'chatbox.update_chat', severity: 'info' }));
    const data = yield updateChat({ body: ChatDetails.mapToApiValue(chat) });
    yield put(updateChatSuccessAction(data));
    yield put(openSnackBarAction({ message: 'chatbox.update_success', severity: 'success' }));
  } catch (error) {
    yield put(updateChatFailAction(error as ErrorResponseData));
  }
}

function* createChatEffect({ payload: chat }: PayloadAction<ChatDetails>): Generator<Promise<ChatDetails[]> | PutEffect, void, ChatDetails[]> {
  try {
    const data = yield createChat({ body: ChatDetails.mapToApiValue(chat) });
    yield put(createChatSuccessAction(data));
  } catch (error) {
    yield put(getChatsFailAction(error as ErrorResponseData));
  }
}

function* getMessagesEffect({
  payload: { chatId, limit },
}: PayloadAction<{ chatId: string; limit: number }>): Generator<Promise<IDocs<MessageDetails[]>> | PutEffect, void, IDocs<MessageDetails[]>> {
  try {
    const data = yield getMessages({ body: chatId, queryParams: { limit } });
    yield put(getMessagesSuccessAction(data));
  } catch (error) {
    yield put(getMessagesFailAction(error as ErrorResponseData));
  }
}

function* sendMessageEffect({
  payload: message,
}: PayloadAction<MessageDetails>): Generator<Promise<MessageDetails> | PutEffect, void, MessageDetails> {
  try {
    const messageDto = MessageDetails.mapToApiValue(message);
    const formData = MessageDetails.mapToFormData(messageDto);
    const data = yield sendMessage({ body: formData });
    yield put(
      createPushNotificationAction({
        message: data.sender.firstName + ': ' + (data.text ? (data.text.length > 10 ? data.text.slice(0, 10) + '...' : data.text) : ''),
        chatId: data.chat,
        senderId: data.sender.id,
      }),
    );
    // send data in real time
    // yield socket.emit(SocketEvents.sendMessageInRoom, data);
    yield put(sendMessageIRTAction(data));
    yield put(sendMessageSuccessAction(data));
  } catch (error) {
    yield put(sendMessageFailAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  console.log(error.message);
  yield put(openSnackBarAction({ message: error.status ? 'error.server' : 'error.network', severity: 'error' }));
}

export function* watchChatbox() {
  yield all([
    takeLatest(updateChatAction.type, updateChatEffect),
    takeLatest(getChatsAction.type, getChatsEffect),
    takeLatest(createChatAction.type, createChatEffect),
    takeLatest(getMessagesAction.type, getMessagesEffect),
    takeLatest(sendMessageAction.type, sendMessageEffect),
    takeLatest(createChatFailAction.type, failEffect),
    takeLatest(getChatsFailAction.type, failEffect),
    takeLatest(sendMessageFailAction.type, failEffect),
  ]);
}
