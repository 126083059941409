import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData, UserDetails } from 'core/models';

export const addUserAction = createAction<UserDetails>('add user');
export const addUserSuccessAction = createAction('add user success');
export const addUserFailAction = createAction('add user Fail');

export const removeUserAction = createAction<string>('remove user');
export const updateUserAction = createAction<UserDetails>('update user');
export const deleteUserAction = createAction<string>('delete user');
export const failAction = createAction<ErrorResponseData>('failed');
