import { LoginDTO } from '../../generated/LoginDto';

export interface SigninData {
  email: string;
  password: string;
}

export namespace SigninData {
  export const mapToApiValue = ({ email, password }: SigninData): LoginDTO => ({
    email,
    password,
  });
}
