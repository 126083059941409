import { createReducer, Action } from '@reduxjs/toolkit';
import { ProfileDetails } from 'core/models/profile/profile-details';
import { addRoleSuccessAction, deleteRoleAction, deleteRoleSuccessAction } from 'modules/Role/state/role.actions';
import { loadProfilesAction, loadProfilesFailAction, loadProfilesSuccessAction } from './profiles.actions';

export interface ProfilesState {
  loading: boolean;
  deleting: boolean;
  profiles: ProfileDetails[];
  total: number;
}

const initialState = {
  total: 0,
  deleting: false,
  loading: false,
  profiles: [] as ProfileDetails[],
};

const _profilessReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(loadProfilesAction, (state) => ({ ...state, loading: true }))
    .addCase(loadProfilesSuccessAction, (state, { payload }) => ({
      ...state,
      loading: false,
      total: payload.total,
      profiles: payload.profiles,
    }))
    .addCase(loadProfilesFailAction, (state) => ({
      ...state,
      loading: false,
    }))
    .addCase(addRoleSuccessAction, (state, { payload }) => ({
      ...state,
      profiles: [...state.profiles, payload],
    }))
    .addCase(deleteRoleAction, (state) => ({ ...state, deleting: true }))
    .addCase(deleteRoleSuccessAction, (state, { payload: { docs, total } }) => ({
      ...state,
      profiles: docs,
      deleting: false,
      total,
    }))
    .addDefaultCase((state) => state),
);

export const profilesReducer = (state: ProfilesState | undefined, action: Action): ProfilesState => _profilessReducer(state, action);
