import { createTheme } from '@mui/material';
import { getPersistedTheme } from 'core/utils';
import { colors } from './shared-theme';

export const getItemWrapperTheme = () => {
  const theme = getPersistedTheme();

  const primaryColor = theme && theme[0] ? theme[0] : colors.DEEP_RED;
  const secondaryColor = theme && theme[1] ? theme[1] : colors.BLUE_DARK;

  return createTheme({
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            background: primaryColor,
            '&:hover': {
              background: secondaryColor,
            },
          },
        },
      },
    },
  });
};
