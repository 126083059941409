import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './store.reducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './store.effects';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(middlewares),
});
export type AppState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);
