import Planning from 'modules/Planning/planning.component';
import React, { useEffect } from 'react';

export const PlanningPage = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Project planning page';
  }, []);
  return <Planning />;
};
