export const profiles = {
  status: 'Status',
  title: 'Title',
  description: 'Description',
  access: 'Access',
  edit: 'Edit',
  delete: 'Delete',
  section: 'Section',
  role: 'Role',
  active: 'Active',
  disabled: 'Disabled',
  role_added: 'Profile Added Successfully',
  roles_updated: 'Profiles Updated Successfully',
  confirm_delete: 'Are you sure you want to delete this profile?',
  list: 'Profiles list',
  edit_profile: 'Edit profile',
  add_profile: 'Add profile',
};
