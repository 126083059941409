import { TeamMemberDto } from 'core/generated/TeamMemberDto';

const api = process.env.REACT_APP_API_ENDPOINT || '';

export interface TeamMemberDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
}
export namespace TeamMemberDetails {
  export const mapFromApiValue = ({ _id, firstName, lastName, email, avatar }: TeamMemberDto): TeamMemberDetails => ({
    id: _id,
    firstName,
    lastName,
    email,
    avatar: avatar ? api + avatar : avatar,
  });
  export const mapToApiValue = ({ id, firstName, lastName, email, avatar }: TeamMemberDetails): TeamMemberDto => ({
    _id: id,
    firstName,
    lastName,
    email,
    avatar,
  });
}
