import Users from 'modules/usersList/users-list.component';
import React, { useEffect } from 'react';

export const List: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Users list page';
  }, []);
  return <Users />;
};
