export enum roles {
  BASIC = 'basic',
  CHEF = 'chef',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
}
export enum rolesError {
  SUPER_ADMIN_CREATION_ATTEMPT = 'super_admin_creation_attempt',
  SUPER_ADMIN_DELETE_ATTEMPT = 'super_admin_delete_attempt',
  SUPER_ADMIN_UPDATE_ATTEMPT = 'super_admin_update_attempt',
}
