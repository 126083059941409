import React from 'react';
import { routes } from 'core/enums/routes';
import {
  BacklogPage,
  ChatboxPage,
  List,
  LoginPage,
  ProfilePage,
  ProfilesPage,
  ProjectPage,
  ProjectsPage,
  RecoverPw,
  RemovedProjectsPage,
  ResetPassword,
  RolePage,
} from 'pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import GuardRouter from 'shared/guard';
import Layout from 'modules/Layout/layout.component';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { ManageUserPage } from 'pages/manage-user';
import { getUserRoutes } from 'core/utils';
import { PlanningPage } from 'pages/planning';
import { RecommendationsPage } from 'pages/recommendations';

const RoutesList: React.FC = () => {
  const { user } = useSelector((state: AppState) => state.auth);
  const { resetPasswordToken, resetPasswordExpires } = useSelector((state: AppState) => state.passwordReset);
  const pwResetLink = routes.reset_password + '/' + resetPasswordToken;
  const linkIsExpired = new Date(resetPasswordExpires).getTime() < Date.now();

  const allPages = [
    { route: routes.profile, element: <ProfilePage /> },
    { route: routes.recommendations, element: <RecommendationsPage /> },
    {
      route: routes.users,
      element: <List />,
    },
    {
      route: routes.projects,
      element: <ProjectsPage />,
    },
    {
      route: routes.removedProjects,
      element: <RemovedProjectsPage />,
    },
    {
      route: routes.project,
      element: <ProjectPage />,
    },
    {
      route: `${routes.project}/:id`,
      element: <ProjectPage />,
    },
    {
      route: `${routes.backlog}/:id`,
      element: <BacklogPage />,
    },
    {
      route: routes.profiles,
      element: <ProfilesPage />,
    },
    {
      route: `${routes.role}/:id`,
      element: <RolePage />,
    },
    {
      route: routes.role,
      element: <RolePage />,
    },
    {
      route: `${routes.user}/:id`,
      element: <ManageUserPage />,
    },
    {
      route: routes.user,
      element: <ManageUserPage />,
    },
    {
      route: `${routes.planning}/:projectId/:sprintId`,
      element: <PlanningPage />,
    },
    {
      route: routes.chatbox,
      element: <ChatboxPage />,
    },
    {
      route: `${routes.chatbox}/:id`,
      element: <ChatboxPage />,
    },
  ];

  const userRoutes = getUserRoutes(user, allPages);

  const authPages: {
    route: routes | string;
    element: JSX.Element;
  }[] = [
    {
      route: routes.login,
      element: <LoginPage />,
    },
    {
      route: routes.recover_password,
      element: <RecoverPw />,
    },
    {
      route: routes.other,
      element: <Navigate replace to={user.token ? routes.projects : routes.login} />,
    },
  ];
  // check if password reset link is still valid, if yes it will be added to the pages that will be displayed
  if (!linkIsExpired) {
    authPages.push({
      route: pwResetLink,
      element: <ResetPassword />,
    });
  }
  return (
    <GuardRouter>
      <Routes>
        {user.token
          ? userRoutes.map(({ route, element }, key) => <Route key={`route-${key}`} path={route} element={<Layout>{element}</Layout>} />)
          : authPages.map(({ route, element }, key) => <Route key={`auth-route-${key}`} path={route} element={element} />)}
        <Route path={routes.other} element={<Navigate to={routes.profile} />} />
      </Routes>
    </GuardRouter>
  );
};

export default React.memo(RoutesList);
