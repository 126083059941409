import { createAction } from '@reduxjs/toolkit';
import { ChatDetails, ErrorResponseData, MessageDetails } from 'core/models';
import { Socket } from 'socket.io-client';

export const connectAction = createAction<string>('user connected');
export const connectSuccessAction = createAction<Socket>('user connected successfully');
export const connectFailAction = createAction<ErrorResponseData>(' user failed to connect');

export const sendMessageIRTAction = createAction<MessageDetails>('send message irl');
export const connectUsersToChatsAction = createAction<ChatDetails[]>('connect users to chats');

export const disconnectAction = createAction('user disconnected');
