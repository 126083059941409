import React from 'react';
import { Star as StarIcon } from '@mui/icons-material';

interface Props {
  starsNumber: number;
}

// returns star icons components based on the number given
export const StarGenerator: React.FC<Props> = ({ starsNumber }) => {
  return (
    <>
      {Array(starsNumber)
        .fill(null)
        .map((_, key) => (
          <StarIcon className={'star_icon'} key={'star-' + key} />
        ))}{' '}
    </>
  );
};
