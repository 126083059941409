import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData } from 'core/models';
import { SprintDetails } from 'core/models/sprint/sprint-details';

export const loadBacklogAction = createAction<{ projectId: string }>('load backlog');
export const loadBacklogSuccessAction = createAction<SprintDetails[]>('load backlog success');
export const loadBacklogFailureAction = createAction<ErrorResponseData>('load backlog failure');

export const createSprintAction = createAction<SprintDetails>('create sprint');
export const createSprintSuccessAction = createAction<SprintDetails[]>('create sprint success');
export const createSprintFailureAction = createAction<ErrorResponseData>('failed to create sprint');

export const updateSprintAction = createAction<SprintDetails>('update sprint');
export const updateSprintSuccessAction = createAction<SprintDetails[]>('update sprint success');
export const updateSprintFailureAction = createAction<ErrorResponseData>('update sprint failure');

export const deleteSprintAction = createAction<string>('delete sprint action');
export const deleteSprintFailureAction = createAction<ErrorResponseData>('delete sprint failure');
export const deleteSprintSuccessAction = createAction<SprintDetails>('delete sprint success');
