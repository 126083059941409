export enum SocketEvents {
  userConnected = 'user_connected',
  getConnectedUsers = 'get_connected_users',
  getConnectedUsersSuccess = 'get_connected_users_success',
  disconnect = 'disconnect',
  sendMessage = 'send_message',
  joinRoom = 'join_room',
  sendMessageInRoom = 'send_message_in_room',
  receiveMessageInRoom = 'receive_message_in_room',
  joinRoomSuccess = 'join_room_success',
  connectToRooms = 'connect_to_rooms',
  sendNofitication = 'send_nofitication',
  receiveNofitication = 'receive_nofitication',
}
