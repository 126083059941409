import { PayloadAction } from '@reduxjs/toolkit';
import { localStorageKeys } from 'core/enums/local-storage-keys';
import { routes } from 'core/enums/routes';
import { ErrorResponseData, SigninData, UserDetails } from 'core/models';
import { authenticate } from 'core/services/auth.service';
import { navigate } from 'core/services/history.service';
import { subscribe } from 'core/services/notification.service';
import { encrypt } from 'core/utils';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { takeLatest, all, put, PutEffect } from 'redux-saga/effects';
import { loginAction, loginFailedAction, loginSuccessAction } from './auth.actions';

function* loginEffect({ payload }: PayloadAction<SigninData>): Generator<Promise<UserDetails> | PutEffect | void, void | string, UserDetails> {
  try {
    const user = yield authenticate({
      body: SigninData.mapToApiValue(payload),
    });
    const encodedToken = encrypt(user.token);
    localStorage.setItem(localStorageKeys.theme, JSON.stringify(user.theme));
    localStorage.setItem(localStorageKeys.token, encodedToken);
    yield put(loginSuccessAction(user));
    yield subscribe(user.id);
  } catch (error) {
    yield put(loginFailedAction(error as ErrorResponseData));
  }
}

function* loginSuccessEffect(): Generator {
  navigate(routes.profile);
  yield put(openSnackBarAction({ message: 'common.hello', severity: 'success' }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loginFailEffect(error: any) {
  const message = error?.payload?.message === 'disabled_account' ? 'error.disabled_account' : undefined;
  yield put(openSnackBarAction({ message: message ? message : 'error.' + error.type, severity: 'error' }));
}

export function* watchLogin(): Generator {
  yield all([
    takeLatest(loginAction.type, loginEffect),
    takeLatest(loginSuccessAction.type, loginSuccessEffect),
    takeLatest(loginFailedAction.type, loginFailEffect),
  ]);
}
