/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DateRangePicker, DateTimePicker } from '@mui/lab';
import { Box, FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import { CustomAutoComplete } from 'shared/components/CustomAutoComplete/autoComplete.component';
import { MuiSelect } from 'shared/components/MuiSelect/select.component';
import { AvatarUpload } from '../AvatarUpload/avatar-upload.component';
import { FileUpload } from '../FileUpload/file-upload.component';
import { Input } from '../Input/input.component';
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  label?: string;
  type: string;
  options?: any[];
  handleChange?: any;
  handleBlur?: any;
  error?: string;
  touched?: boolean;
  placeholder?: string;
  multiline?: boolean;
  name?: string;
  toolTipText?: string;
  multiple?: boolean;
  optionIsName?: boolean;
  model?: string;
  avatarWidth?: number;
  avatarHeight?: number;
  required?: boolean;
  withIcon?: boolean;
  optionIsSimpleValue?: boolean;
}

export const InputElement: React.FC<Props> = ({
  label = '',
  value = '',
  type = '',
  handleChange = () => {},
  handleBlur = () => {},
  touched = false,
  error = '',
  multiline = false,
  multiple = false,
  name = '',
  options = [],
  toolTipText = '',
  optionIsName = true,
  model = '',
  placeholder = '',
  avatarWidth = 50,
  avatarHeight = 50,
  required = false,
  optionIsSimpleValue = false,
  withIcon = false,
}) => {
  const { t } = useTranslation();
  switch (type) {
    case 'text':
    case 'password':
    case 'email':
    case 'number':
      return (
        <Input
          name={name}
          value={value as string}
          type={type}
          multiline={multiline}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={label}
          error={error}
          touched={touched}
          placeholder={placeholder}
        />
      );
    case 'select':
      return <CustomAutoComplete value={value as string} labels={options} label={label} id={name} handleChange={handleChange} />;
    case 'switch':
      return <FormControlLabel control={<Switch checked={!value} onClick={handleChange} />} label={label} />;
    case 'avatar':
      return (
        <AvatarUpload
          toolTipText={toolTipText}
          avatar={value as string}
          name={name}
          handleChange={handleChange}
          width={avatarWidth}
          height={avatarHeight}
        />
      );
    case 'drop-down':
      return (
        <MuiSelect
          multiple={multiple}
          label={label}
          options={options}
          required={required}
          handleChange={handleChange}
          value={value}
          name={name}
          optionIsName={optionIsName}
          model={model}
          withIcon={withIcon}
          optionIsSimpleValue={optionIsSimpleValue}
        />
      );
    case 'file':
      return <FileUpload handleChange={handleChange} />;
    case 'time':
      return <DateTimePicker value={value} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />;
    case 'date_range':
      return (
        <DateRangePicker
          value={value}
          startText={t('common.start_date')}
          endText={t('common.end_date')}
          onChange={(v) => handleChange(v)}
          disableCloseOnSelect={true}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}>
                <ArrowRightAltIcon />
              </Box>
              <TextField {...endProps} />
            </>
          )}
        />
      );
    case 'duration':
      return (
        <Stack direction="row" alignItems="center">
          <DateTimePicker
            label={t('common.start_date')}
            value={value.startDate}
            onChange={(v) => handleChange.setStartDate(v)}
            renderInput={(params) => <TextField {...params} />}
          />
          <Box sx={{ mx: 2 }}>
            <ArrowRightAltIcon />
          </Box>
          <DateTimePicker
            label={t('common.end_date')}
            value={value.endDate}
            onChange={(v) => handleChange.setEndDate(v)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      );
    default:
      return null;
  }
};
