import { createTheme } from '@mui/material';

export const autoCompleteTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            color: 'white',
          },
        },
      },
    },
  },
});
