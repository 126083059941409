import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData, RecommendationDetails } from 'core/models';

export const createRecommendationAction = createAction<RecommendationDetails>('create recommendation');
export const createRecommendationSucceededAction = createAction<RecommendationDetails[]>('create recommendation success');
export const createRecommendationFailedAction = createAction<ErrorResponseData>('create recommendation failure');

export const getRecommendationsAction = createAction('get recommendations');
export const getRecommendationsSuccessAction = createAction<RecommendationDetails[]>('get recommendations success');
export const getRecommendationsFailedAction = createAction<ErrorResponseData>('get recommendations failure');

export const removeRecommendationAction = createAction<string>('remove recommendations');
export const removeRecommendationSuccessAction = createAction<RecommendationDetails[]>('remove recommendations success');
export const removeRecommendationFailedAction = createAction<ErrorResponseData>('remove recommendations failure');
