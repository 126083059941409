import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormContainer } from 'shared/components';
import { FormikValues } from 'shared/interface';
import { passwordValidationSchema } from 'shared/validator';
import { AppState } from 'store';
import { resetPwAction } from './state/password.actions';

const initialValues: FormikValues = {
  password: '',
};

const ResetPW = () => {
  const dispatch = useDispatch();
  const { resetPasswordToken } = useSelector((state: AppState) => state.passwordReset);
  // dispatches password reset action
  const submitHandler = ({ password }: FormikValues) => {
    dispatch(resetPwAction({ password, resetPasswordToken }));
  };
  return (
    <FormContainer
      validationSchema={passwordValidationSchema}
      initialValues={initialValues}
      submitHandler={submitHandler}
      submitType="set_password"
    />
  );
};

export default ResetPW;
