import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponseData, RecommendationDetails } from 'core/models';
import { createRecommendation, getRecommendations, removeRecommendation } from 'core/services/recommendation.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  createRecommendationAction,
  createRecommendationFailedAction,
  createRecommendationSucceededAction,
  getRecommendationsAction,
  getRecommendationsFailedAction,
  getRecommendationsSuccessAction,
  removeRecommendationSuccessAction,
  removeRecommendationFailedAction,
  removeRecommendationAction,
} from './recommendation.actions';

function* createRecommendationEffect({
  payload,
}: PayloadAction<RecommendationDetails>): Generator<Promise<RecommendationDetails[]> | PutEffect, void, RecommendationDetails[]> {
  try {
    const data = yield createRecommendation({ body: RecommendationDetails.mapToApiValue(payload) });
    yield put(createRecommendationSucceededAction(data));
    yield put(openSnackBarAction({ message: 'recommendation.create_recommendation_success', severity: 'success' }));
  } catch (error) {
    yield put(createRecommendationFailedAction(error as ErrorResponseData));
  }
}

function* getRecommendationsEffect(): Generator<Promise<RecommendationDetails[]> | PutEffect, void, RecommendationDetails[]> {
  try {
    const data = yield getRecommendations();
    yield put(getRecommendationsSuccessAction(data));
  } catch (error) {
    yield put(getRecommendationsFailedAction(error as ErrorResponseData));
  }
}

function* removeRecommendationEffect({
  payload: recommendationId,
}: PayloadAction<string>): Generator<Promise<RecommendationDetails[]> | PutEffect, void, RecommendationDetails[]> {
  try {
    yield put(openSnackBarAction({ message: 'recommendation.is_being_deleted', severity: 'info' }));
    const data = yield removeRecommendation(recommendationId);
    yield put(removeRecommendationSuccessAction(data));
    yield put(openSnackBarAction({ message: 'recommendation.delete_success', severity: 'success' }));
  } catch (error) {
    yield put(removeRecommendationFailedAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  console.log(error.message);
  yield put(openSnackBarAction({ message: error.status ? 'error.server' : 'error.network', severity: 'error' }));
}

export function* watchRecommendation() {
  yield all([
    takeLatest(createRecommendationAction.type, createRecommendationEffect),
    takeLatest(getRecommendationsAction.type, getRecommendationsEffect),
    takeLatest(removeRecommendationAction.type, removeRecommendationEffect),
    takeLatest(removeRecommendationFailedAction.type, failEffect),
    takeLatest(getRecommendationsFailedAction.type, failEffect),
    takeLatest(createRecommendationFailedAction.type, failEffect),
  ]);
}
