import { Action, createReducer } from '@reduxjs/toolkit';
import { UsersDetails } from 'core/models';
import { addUserAction, addUserFailAction, addUserSuccessAction } from 'modules/User/state/user.actions';
import { loadUsersAction, loadUsersFailedAction, loadUsersSuccessAction } from './users.actions';

export interface UsersState {
  loading: boolean;
  total: number;
  users: UsersDetails[];
}

const usersState: UsersState = {
  loading: false,
  users: [] as UsersDetails[],
  total: 0,
};

const _usersReducer = createReducer(usersState, (builder) =>
  builder
    .addCase(loadUsersAction, (state) => ({ ...state, loading: true }))
    .addCase(
      loadUsersSuccessAction,
      (state, { payload }): UsersState => ({
        ...state,
        loading: false,
        users: payload.docs,
        total: payload.total,
      }),
    )
    .addCase(loadUsersFailedAction, (state) => ({ ...state, loading: false }))
    .addCase(addUserAction, (state) => ({ ...state, loading: true }))
    .addCase(addUserSuccessAction, (state) => ({ ...state, loading: false }))
    .addCase(addUserFailAction, (state) => ({ ...state, loading: false }))
    .addDefaultCase((state) => state),
);

export const usersReducer = (state: UsersState | undefined, action: Action) => _usersReducer(state, action);
