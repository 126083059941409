import React from 'react';
import { CssBaseline, Grid, LinearProgress, ThemeProvider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getChatboxTheme } from 'shared/themes';
import { AppState } from 'store';
import ChatList from './components/chatlist.component';
import MessagesList from './components/messagesList.component';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConnectedUsersList from './components/connectedUsersList.component';

const Chatbox: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    chatbox: { loading },
    auth: {
      user: { theme },
    },
  } = useSelector((state: AppState) => state);

  return loading ? (
    <LinearProgress />
  ) : (
    <ThemeProvider theme={getChatboxTheme(theme)}>
      <CssBaseline />
      <Grid container className="chatbox_container">
        <Grid item xs={12} md={3}>
          <ChatList />
        </Grid>
        <Grid item xs={12} md={6}>
          {id ? (
            <MessagesList />
          ) : (
            <Typography paddingTop="20px" color="gray" variant="h5" align="center">
              {t('chatbox.welcome_message')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <ConnectedUsersList />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default React.memo(Chatbox);
