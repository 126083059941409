import Role from 'modules/Role/role.component';
import React, { useEffect } from 'react';

export const RolePage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Role editing page';
  }, []);
  return <Role />;
};
