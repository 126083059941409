import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData } from 'core/models';
import { FormikValues } from 'shared/interface';

export const recoverPwAction = createAction<FormikValues>('recover password');
export const recoverPwSuccessAction = createAction<FormikValues>('recover password success');

export const resetPwAction = createAction<FormikValues>('reset password');
export const resetPwSuccessAction = createAction('reset password Success');

export const failAction = createAction<ErrorResponseData>('failed action');
