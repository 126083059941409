import { LoginDTO } from 'core/generated/LoginDto';
import { UserDTO } from 'core/generated/UserDto';
import { HttpParamsType, UserDetails } from 'core/models';
import { FormikValues } from 'shared/interface';
import { apiUrlMatcher } from './helpers';
import { ApiUrls } from './helpers/api-url';
import { post, get } from './helpers/base-http';

// performs login  functionality
export const authenticate = async (params: HttpParamsType<LoginDTO>): Promise<UserDetails> => {
  const user = await post<UserDTO>(apiUrlMatcher(ApiUrls.Auth), params);
  return UserDetails.mapFromApiValue(user);
};

// fetches user details based on given token
export const checkToken = async (id: string): Promise<UserDetails> => {
  const user = await get<UserDTO>(apiUrlMatcher(ApiUrls.profile) + '/' + id);
  return UserDetails.mapFromApiValue(user);
};

// sends a  password recover request to the API
export const recoverPw = async (params: HttpParamsType<FormikValues>): Promise<FormikValues> => {
  const result = await post<FormikValues>(apiUrlMatcher(ApiUrls.recoverPw), params);
  return result;
};
// sends a password reset request to the API
export const resetPw = async (params: HttpParamsType<FormikValues>): Promise<void> => {
  await post<string>(apiUrlMatcher(ApiUrls.resetPw), params);
};
