import React, { useEffect, useRef, useState } from 'react';
import { Card, Container, Grid, LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Alert, Attachements, InputElement, MuiBtnGrp } from 'shared/components';
import { navigate } from 'core/services/history.service';
import { useParams } from 'react-router-dom';
import { deleteFile, loadProject } from 'core/services/project.service';
import { AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { roles, routes } from 'core/enums';
import { loadUsersAction } from 'modules/usersList/state/users.actions';
import { addProjectAction, updateProjectAction } from './state/project.actions';
import { uploadFiles } from 'core/services/project.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { TeamMemberDetails, ProjectDetails } from 'core/models';
import styles from './project.module.scss';

const Project: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { users } = useSelector((state: AppState) => state.users);
  const [projectData, setProjectData] = useState<ProjectDetails>({} as ProjectDetails);
  const [loading, setLoading] = useState(true);
  const projectLeads = users.filter((user) => user.role === roles.CHEF);
  const toBeDeletedFile = useRef<string>();
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          const project = await loadProject(id);
          if (project) {
            setProjectData(project);
          }
        }
        dispatch(loadUsersAction());
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error.message);
      }
    })();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    id ? dispatch(updateProjectAction(projectData)) : dispatch(addProjectAction(projectData));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectData({ ...projectData, [e.target.name]: e.target.value });
  };
  const handleTypeChange = (v: string) => {
    setProjectData({ ...projectData, type: v });
  };
  const handleAvatarChange = (avatar: File) => {
    setProjectData({ ...projectData, avatar });
  };

  const handleLeadChange = (lead: TeamMemberDetails) => {
    setProjectData({ ...projectData, lead });
  };

  const handleTeamChange = (team: TeamMemberDetails[]) => {
    setProjectData({ ...projectData, team });
  };
  const handlePriorityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const priority = +e.target.value;
    if (priority < 0 || priority > 5) return;
    setProjectData({ ...projectData, priority });
  };
  const handleFileRemove = (filename: string) => {
    toBeDeletedFile.current = filename;
    setOpenAlert(true);
  };
  const removeFile = async () => {
    if (toBeDeletedFile.current) {
      const data = await deleteFile({ body: [toBeDeletedFile.current], queryParams: { id: projectData.id } });
      setProjectData(data);
      dispatch(openSnackBarAction({ message: 'common.file_removed', severity: 'success' }));
    }
  };

  const handleFileUpload = async (files: FileList) => {
    const projectDto = ProjectDetails.mapToApiValue({ ...projectData, documents: files });
    const formData = ProjectDetails.mapToFormData(projectDto);
    const project = await uploadFiles({ body: formData, queryParams: { id: projectData.id }, multiPart: true });
    setProjectData(project);
    dispatch(openSnackBarAction({ message: 'common.file_added', severity: 'success' }));
  };

  const fields = [
    {
      name: 'avatar',
      type: 'avatar',
      avatarWidth: 100,
      avatarHeight: 100,
      label: t('project.avatar'),
      value: projectData?.avatar,
      handleChange: handleAvatarChange,
      toolTipText: t('project.edit_avatar').toString(),
    },
    { name: 'title', type: 'text', label: t('project.title'), value: projectData?.title, handleChange },
    { name: 'description', type: 'text', label: t('project.description'), value: projectData?.description, handleChange },
    {
      name: 'type',
      type: 'select',
      label: t('project.type'),
      value: projectData?.type,
      handleChange: handleTypeChange,
      options: ['client', 'intern'],
      required: true,
    },
    {
      name: 'lead',
      type: 'drop-down',
      label: t('project.lead'),
      value: projectData?.lead,
      handleChange: handleLeadChange,
      required: true,
      options: projectLeads?.map((lead) => ({
        id: lead.id,
        firstName: lead.firstName,
        lastName: lead.lastName,
        avatar: lead.avatar,
        email: lead.email,
      })),
    },
    {
      name: 'team',
      required: true,
      type: 'drop-down',
      label: t('project.team'),
      value: projectData?.team,
      handleChange: handleTeamChange,
      multiple: true,
      // remove lead from members list if selected
      options: users?.filter((member) => member.id !== projectData?.lead?.id),
      multiline: true,
    },
    { name: 'priority', type: 'number', label: t('project.priority'), value: +projectData?.priority || 0, handleChange: handlePriorityChange },
  ];
  // in case of no id provided meaning the user will create a new project the upload files component won't be necessary
  // so only the component with inputs will be returned
  // for that sizes other than xs won't be necessary
  const gridItemProps = { xs: 12, item: true, ...(id ? { sm: 10, md: 5 } : {}) };
  return loading ? (
    <LinearProgress />
  ) : (
    <Container>
      <Card className={styles.card}>
        <Grid component="form" onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
          <Typography variant="h4" gutterBottom>
            {t('project.' + (id ? 'edit' : 'add') + '_project')}
          </Typography>
          <Grid container>
            <Grid {...gridItemProps}>
              <Grid container padding={5} spacing={3}>
                {fields.map((field) => (
                  <Grid item key={field.label} xs={12}>
                    <InputElement {...field} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {id && (
              <Grid {...gridItemProps} className={styles.attachements}>
                <Attachements
                  uploadVariant="button"
                  handleFileUpload={handleFileUpload}
                  handleFileRemove={handleFileRemove}
                  documents={projectData.documents as string[]}
                />
              </Grid>
            )}
          </Grid>
          {/* <Stack direction="row" justifyContent="flex-end" spacing={2} paddingTop={2}>
            <ButtonGroup>
              <Button variant="contained" type="submit" onClick={() => handleSubmit()}>
                {t('common.save')}
              </Button>
              <Button variant="contained" onClick={() => navigate(routes.projects)}>
                {t('common.cancel')}
              </Button>
            </ButtonGroup>
          </Stack> */}
          <MuiBtnGrp handleCancel={() => navigate(routes.projects)} submit={true} save={true} />
        </Grid>
        <Alert
          action={async () => {
            await removeFile();
          }}
          open={openAlert}
          handleOpen={() => {
            setOpenAlert(!openAlert);
          }}
          message={t('project.confirm_delete_file') as string}
        />
      </Card>
    </Container>
  );
};

export default Project;
