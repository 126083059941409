import { common } from './en/common';
import { error } from './en/error';
import { login } from './en/login';
import { user } from './en/user';
import { project } from './en/project';
import { menu } from './en/menu';
import { profile } from './en/profile';
import { profiles } from './en/profiles';
import { sprint } from './en/sprint';
import { task } from './en/task';
import { recommendation } from './en/recommendation';
import { chatbox } from './en/chatbox';
import { notification } from './en/notification';

const en = {
  common,
  error,
  login,
  user,
  project,
  menu,
  profile,
  profiles,
  sprint,
  task,
  recommendation,
  chatbox,
  notification,
};

export default en;
