export const project = {
  projects: 'Projects',
  title: 'Title',
  priority: 'Priority',
  lead: 'Project Lead',
  createdAt: 'Creation Date',
  type: 'Type',
  description: 'Description',
  actions: 'Actions',
  edit: 'Edit Project',
  select_type: 'Select Type',
  set_priority: 'Set Priority',
  all_types: 'All Types',
  intern: 'Intern',
  client: 'Client',
  team: 'Team members',
  add_project: 'Add project +',
  confirm_delete: 'Are you sure you want to delete this project?',
  soft_delete_success: 'Project successfully deleted',
  project_add_success: 'Project successfully created',
  project_update_success: 'Project successfully updated',
  project_restore_success: 'Project successfully restored',
  list: 'Projects list',
  removed_list: 'Removed projects list',
  edit_project: 'Edit project',
  edit_avatar: 'Edit Project avatar ',
  avatar: 'Avatar ',
  attachments: 'Attachments',
  no_attachments: 'No attachments',
  remove_file: 'Remove attachment',
  download_file: 'Download attachment',
  upload_files: 'Upload attachments',
  confirm_delete_file: 'Are you sure you want to delete this file',
  backlog: 'Backlog',
  create_sprint: 'Create sprint',
  edit_sprint: 'Edit sprint',
  create_issue: 'Create issue',
  view_removed_projects: 'View removed projects',
  restore: 'Restore project',
};
