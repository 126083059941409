/* eslint-disable @typescript-eslint/prefer-for-of */
import React, { ReactElement, useEffect, useState } from 'react';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { loadBacklogAction } from 'modules/Backlog/state/backlog.actions';
import { loadProjectsAction } from 'modules/ProjectsList/state/projects.actions';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { durationToMinutes, stringToColor } from 'core/utils';
import { routes } from 'core/enums';
import { AvatarIcon, HistoryList, MuiSelect } from 'shared/components/';
import { useTranslation } from 'react-i18next';
import styles from './planning.module.scss';
import 'gantt-task-react/dist/index.css';
import { TeamMemberDetails } from 'core/models';
import { workingHours } from 'core/constants';
import { colors } from 'shared/themes/';

const Planning: React.FC = () => {
  const { t } = useTranslation();
  const { sprintId, projectId } = useParams();
  const [filtredAssignees, setFiltredAssignees] = useState<TeamMemberDetails[]>([]);

  const dispatch = useDispatch();
  const {
    auth: {
      user: { theme },
    },
    backlog: { sprints, loading },
    projects: { projects },
  } = useSelector((state: AppState) => state);
  const sprint = sprints?.find(({ id }) => id === sprintId);
  const project = projects.find(({ id }) => id === projectId);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.QuarterDay);

  const TaskTooltip = ({ task: subTask }: { task: Task }): ReactElement => {
    const taskOriginalId = subTask.id.split(' ')[0];
    const task = sprint?.tasks.find(({ id }) => id === taskOriginalId);
    if (!task) return <></>;
    const assigneeName = task?.assignee.firstName + ' ' + task?.assignee.lastName;
    const reporterName = task?.reporter.firstName + ' ' + task?.reporter.lastName;
    const workDonePercentage = 100 * (durationToMinutes(task?.duration ?? '0') / durationToMinutes(task?.estimatedDuration));
    const atts = [
      { key: 'assignee', value: assigneeName, icon: <AvatarIcon name={assigneeName} avatar={task?.assignee.avatar} /> },
      { key: 'reporter', value: reporterName, icon: <AvatarIcon name={reporterName} avatar={task?.reporter.avatar} /> },
      {
        key: 'estimatedDuration',
        value: task?.estimatedDuration,
        icon: <Box sx={{ width: '2vh', height: '2vh', background: theme[0], borderRadius: '50%' }} />,
      },
      {
        key: 'time_tracking',
        value: task?.duration,
        icon: <Box sx={{ width: '2vh', height: '2vh', borderRadius: '50%', background: theme[1] }} />,
      },
      {
        key: 'sprint',
        value: sprint?.title,
      },
    ];
    return (
      <Card sx={{ width: '32vw', padding: '10px', overflowY: 'auto' }}>
        <CardHeader avatar={<AvatarIcon name={task.title} />} titleTypographyProps={{ variant: 'h6' }} title={task.title} />
        <CardContent>
          <Stack>
            <Typography color="gray">{t('common.details')} </Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemText />
              </ListItem>
              {atts.map((att) => (
                <ListItem key={att.key}>
                  <ListItemText primary={t('task.' + att.key)} />
                  <ListItemSecondaryAction>
                    <Stack display="flex" alignItems="center" flexDirection="row" padding={1} justifyContent="space-between">
                      <Typography paddingRight="10px">{att.value} </Typography>
                      {att.icon && att.icon}
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Stack>
        </CardContent>
        <CardActionArea>
          <Box sx={{ width: '100%', height: '2vh', background: theme[0] }}>
            <Box sx={{ width: `${workDonePercentage}%`, height: '2vh', background: theme[1] }} />
          </Box>
        </CardActionArea>
      </Card>
    );
  };

  useEffect(() => {
    if (projectId) {
      dispatch(loadBacklogAction({ projectId }));
      dispatch(loadProjectsAction());
    }
  }, []);

  useEffect(() => {
    const assigneesData = getAssignees();
    if (assigneesData && assigneesData.length) {
      setFiltredAssignees(assigneesData);
    }
  }, [sprint?.tasks?.length]);

  const getAssignees = () => {
    const assigneeIds: string[] = [];
    return sprint?.tasks
      ?.filter(({ assignee }) => {
        if (!assigneeIds.includes(assignee.id)) {
          assigneeIds.push(assignee.id);
          return true;
        }
        return false;
      })
      ?.map(({ assignee }) => assignee);
  };

  const getTasks = (): Task[] | undefined => {
    const data = sprint?.tasks;
    if (data && data.length) {
      const sprintStartDate = moment(sprint.startDate).add(workingHours.start, 'h').toDate();
      const lastDates = new Map<string, Date>();
      const filtredAssigneeIds = filtredAssignees.map(({ id }) => id);
      const TasksData: (Task | Task[])[] = data
        .filter((task) => filtredAssigneeIds.includes(task.assignee.id))
        .map((task) => {
          const lastDate = lastDates.get(task.assignee.id);
          const estimatedDuration = durationToMinutes(task.estimatedDuration);

          const currentDate = lastDate ?? sprintStartDate;
          const taskStartDate = moment(currentDate).toDate();

          const name = task.assignee.firstName + ' ' + task.assignee.lastName;
          const currentDay = moment(taskStartDate).set({ hours: 0, minutes: 0, seconds: 0 }).toDate();

          // working hours
          const pauseHour = moment(currentDay).add(workingHours.pause);
          const endHour = moment(currentDay).add(workingHours.end);

          const subTask = {
            id: task.id,
            name: task.title,
            type: 'task',
            progress: task.progress || 0,
            styles: { backgroundColor: stringToColor(name), progressColor: '#f7f7f7d4' },
          };

          const subTasks = [];
          const subTaskStartDate = moment(taskStartDate);
          const end = moment(taskStartDate);

          for (let minutesSpent = 0; minutesSpent < estimatedDuration + 1; minutesSpent += 30) {
            const subTaskId = task.id + ' ' + minutesSpent;
            // checking if the task start date is saturday
            if (subTaskStartDate.day() === 6) {
              // resetting working time for after the weekend
              subTaskStartDate.add(2, 'day').set(workingHours.start);
              end.add(2, 'day').set(workingHours.start);
              pauseHour.add(2, 'day').set(workingHours.pause);
              endHour.add(2, 'day').set(workingHours.end);
            }
            // checking if the task start date is sunday
            if (subTaskStartDate.day() === 0) {
              // resetting working time for after the weekend
              subTaskStartDate.add(1, 'day').set(workingHours.start);
              end.add(1, 'day').set(workingHours.start);
              pauseHour.add(1, 'day').set(workingHours.pause);
              endHour.add(1, 'day').set(workingHours.end);
            }
            if (minutesSpent === estimatedDuration) {
              subTasks.push({
                ...subTask,
                id: subTaskId,
                start: subTaskStartDate.toDate(),
                end: end.toDate(),
              } as Task);
              break;
            }
            if (end.isSame(pauseHour)) {
              subTasks.push({
                ...subTask,
                id: subTaskId,
                start: subTaskStartDate.toDate(),
                end: end.toDate(),
              } as Task);
              subTaskStartDate.set(workingHours.resume);
              end.set(workingHours.resume);
            } else if (!subTaskStartDate.isSame(end) && end.isSame(endHour)) {
              subTasks.push({
                ...subTask,
                id: subTaskId,
                start: subTaskStartDate.toDate(),
                end: end.toDate(),
              } as Task);
              subTaskStartDate.add(1, 'day').set(workingHours.start);
              end.add(1, 'day').set(workingHours.start);
              pauseHour.add(1, 'day');
              endHour.add(1, 'day');
            }
            end.add(30, 'minutes');
          }

          lastDates.set(task.assignee.id, end.toDate());

          return subTasks;
        });
      const tasksArray: Task[] = [];
      // check if result is an array of subTasks
      if (Array.isArray(TasksData[0])) {
        const subTasksMatrix = TasksData as Task[][];
        for (let i = 0; i < subTasksMatrix.length; i++) {
          for (let j = 0; j < subTasksMatrix[i].length; j++) {
            tasksArray.push(subTasksMatrix[i][j] as Task);
          }
        }
      } else {
        for (let i = 0; i < TasksData.length; i++) {
          tasksArray.push(TasksData[i] as Task);
        }
      }
      return tasksArray;
    }
  };
  const dummyTasks = Array(3)
    .fill(null)
    .map(
      (_, i) =>
        ({
          id: i.toString(),
          name: '',
          type: 'task',
          progress: 0,
          styles: { backgroundColor: '#f7f7f7d4' },
          start: new Date(),
          end: new Date(),
        } as Task),
    );
  const tasks = getTasks();
  const tasksData = tasks && tasks.length ? tasks : dummyTasks;

  const historyList = [
    {
      title: t('project.projects'),
      path: routes.projects,
    },
    {
      title: project?.title || '',
      path: routes.backlog + '/' + projectId,
    },
    {
      title: t('sprint.planning'),
    },
  ];
  return loading ? (
    <LinearProgress />
  ) : (
    <Box className={styles.container}>
      <Stack spacing={3} padding={2}>
        <HistoryList list={historyList} />
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {t('sprint.planning')}
          </Typography>
          <div>
            <MuiSelect
              name={t('sprint.view_mode') as string}
              label={t('sprint.view_mode') as string}
              options={Object.values(ViewMode).filter((mode) => mode !== ViewMode.Hour)}
              model={'common'}
              value={viewMode}
              optionIsSimpleValue={true}
              handleChange={(value: ViewMode) => {
                setViewMode(value);
              }}
            />
          </div>
        </Stack>
      </Stack>
      <Grid container width="100vw">
        <Grid item xs={12} overflow="auto">
          <Gantt ganttHeight={450} tasks={tasksData} viewMode={viewMode} TooltipContent={TaskTooltip} todayColor={colors.DEEP_RED} />
        </Grid>
      </Grid>
      <Stack padding={2}>
        <Stack direction="column" justifyContent="flex-end">
          <Typography variant="h5" gutterBottom>
            {t('task.assignees')}
          </Typography>
          {getAssignees()?.map((assignee, i) => {
            const name = assignee?.firstName + ' ' + assignee?.lastName;
            const checked = !!filtredAssignees.find(({ id }) => assignee.id === id);
            return (
              <Stack key={i} direction="row" alignItems="center">
                <Checkbox
                  checked={checked}
                  onClick={() => {
                    if (checked) {
                      setFiltredAssignees([...filtredAssignees.filter(({ id }) => id !== assignee.id)]);
                    } else {
                      setFiltredAssignees([...filtredAssignees, assignee]);
                    }
                  }}
                />
                <Typography>{name} </Typography>
                <Box sx={{ backgroundColor: stringToColor(name), width: '20px', height: '20px', borderRadius: '50%', marginLeft: '10px' }} />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};

export default React.memo(Planning);
