/* eslint-disable @typescript-eslint/no-explicit-any */
// import { AxiosResponse } from 'axios';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface QueryData {
  [key: string]: string | number | boolean;
}
export interface HttpParamsType<T = unknown> {
  queryParams?: QueryData;
  body?: T;
  authenticationRequired?: boolean;
  multiPart?: boolean;
}
export interface RequestOptions {
  headers?: any;
  params?: any;
  body?: any;
}

export interface ErrorResponseData {
  status: number | undefined;
  code: number | undefined;
  message: string;
  title: string;
  type: string;
}

export namespace ErrorResponseData {
  export function mapFromApiValue(httpError: any): ErrorResponseData {
    return {
      status: httpError?.response?.status,
      code: httpError?.response?.data?.code,
      message: httpError?.response?.data?.message || 'Erreur Http',
      title: httpError?.response?.data?.name || "Une erreur s'est produite !",
      type: httpError?.response?.data?.type,
    };
  }
}
