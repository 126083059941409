import { createReducer, Action } from '@reduxjs/toolkit';
import { TaskDetails } from 'core/models';
import { SprintDetails } from 'core/models/sprint/sprint-details';
import {
  createTaskAction,
  createTaskFailureAction,
  createTaskSuccessAction,
  updateTaskAction,
  updateTaskFailureAction,
  updateTaskSuccessAction,
} from 'modules/Task/state/task.actions';
import {
  createSprintAction,
  createSprintFailureAction,
  createSprintSuccessAction,
  deleteSprintAction,
  deleteSprintFailureAction,
  deleteSprintSuccessAction,
  loadBacklogAction,
  loadBacklogFailureAction,
  loadBacklogSuccessAction,
  updateSprintAction,
  updateSprintFailureAction,
  updateSprintSuccessAction,
} from './backlog.actions';

interface BacklogState {
  loading: boolean;
  sprints: SprintDetails[];
  theSprintBeingUpdated: SprintDetails | null;
  theTaskBeingUpdated: TaskDetails | null;
  theSprintBeingCreated: SprintDetails | null;
  theTaskBeingCreated: TaskDetails | null;
  sprintBeingRemoved: boolean;
}

const initialState: BacklogState = {
  loading: false,
  sprintBeingRemoved: false,
  theTaskBeingUpdated: null,
  theSprintBeingUpdated: null,
  theSprintBeingCreated: null,
  theTaskBeingCreated: null,
  sprints: [] as SprintDetails[],
};

const _backlogReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(loadBacklogAction, (state) => ({ ...state, loading: true }))
    .addCase(loadBacklogSuccessAction, (state, { payload }) => ({ ...state, loading: false, sprints: payload }))
    .addCase(loadBacklogFailureAction, (state) => ({ ...state, loading: false }))

    .addCase(createSprintAction, (state, { payload }) => ({ ...state, theSprintBeingCreated: payload }))
    .addCase(createSprintSuccessAction, (state, { payload }) => ({ ...state, theSprintBeingCreated: null, sprints: payload }))
    .addCase(createSprintFailureAction, (state) => ({ ...state, theSprintBeingCreated: null }))

    .addCase(updateSprintAction, (state, { payload }) => ({ ...state, theSprintBeingUpdated: payload }))
    .addCase(updateSprintSuccessAction, (state, { payload }) => ({ ...state, sprints: payload, theSprintBeingUpdated: null }))
    .addCase(updateSprintFailureAction, (state) => ({ ...state, sprintIsBeingUpdated: false }))

    .addCase(deleteSprintAction, (state) => ({ ...state, sprintBeingRemoved: true }))
    .addCase(deleteSprintSuccessAction, (state, { payload }) => ({
      ...state,
      sprintBeingRemoved: false,
      sprints: [...state.sprints.filter((sprint) => sprint.id !== payload.id)],
    }))
    .addCase(deleteSprintFailureAction, (state) => ({ ...state, sprintBeingRemoved: false }))

    .addCase(createTaskAction, (state, { payload }) => ({ ...state, theTaskBeingCreated: payload }))
    .addCase(createTaskSuccessAction, (state, { payload }) => ({
      ...state,
      theTaskBeingCreated: null,
      sprints: state.sprints.map((sprint) => (sprint.id === payload.sprint ? { ...sprint, tasks: [...sprint.tasks, payload] } : sprint)),
    }))
    .addCase(createTaskFailureAction, (state) => ({ ...state, theTaskBeingCreated: null }))

    .addCase(updateTaskAction, (state, { payload }) => ({ ...state, theTaskBeingUpdated: payload }))
    .addCase(updateTaskSuccessAction, (state, { payload }) => ({
      ...state,
      theTaskBeingUpdated: null,
      sprints: state.sprints.map((sprint) => {
        const index = sprint.tasks.findIndex((task) => task.id === payload.id);
        if (index === -1) return sprint;
        const tasks = [...sprint.tasks];
        tasks[index] = payload;
        return { ...sprint, tasks };
      }),
    }))
    .addCase(updateTaskFailureAction, (state) => ({ ...state, theTaskBeingUpdated: null }))
    .addDefaultCase((state) => state),
);

export const backlogReducer = (state: BacklogState | undefined, action: Action) => _backlogReducer(state, action);
