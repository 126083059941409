import { Button, Grid, IconButton, Tooltip, Typography, Link as MuiLink, Stack } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert as DeleteAlert,
  Alert as AttachementsAlert,
  AvatarIcon,
  CustomAutoComplete,
  CustomTable,
  SortIcon,
  Attachements,
  StarGenerator,
} from 'shared/components';
import { AppState } from 'store';
import { loadProjectsAction } from './state/projects.actions';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  AttachFile as AttachFileIcon,
  FolderDelete as FolderDeleteIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { isPermitted } from 'core/utils';
import { routes } from 'core/enums/routes';
import { navigate, history } from 'core/services/history.service';
import moment from 'moment';
import { QueryData } from 'core/models';
import { sort } from 'core/enums/sorters';
import { deleteProjectAction, restoreProjectAction } from 'modules/Project/state/project.actions';
import { Permissions, resources } from 'core/enums';
import { Link } from 'react-router-dom';

const Projects: React.FC = () => {
  const isRemovedProjectsPage = history.location.pathname === routes.removedProjects + '/';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: AppState) => state.auth);
  const { projects, loading, total, deletingProject } = useSelector((state: AppState) => state.projects);
  const [searchData, setsearchData] = useState<QueryData>({ page: 0, limit: 5 });
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [toBeDeletedProjectId, setToBeDeletedProjectId] = useState<string>('');
  const projectsTypes = ['client', 'intern'];
  const [projectId, setProjectId] = useState<string>('');
  const pageTitle = isRemovedProjectsPage ? t('project.removed_list') : t('project.list');
  // loads projects from the database based on search data (search query)
  const loadProjects = (query?: QueryData) => {
    dispatch(loadProjectsAction(query));
  };
  // reloads projects from the database whenever search data changes
  useEffect(() => {
    loadProjects({ ...searchData, deleted: isRemovedProjectsPage });
  }, [searchData]);
  // set search data object in  case user wants to sort a certain field
  const setSorting = (field: string) => {
    const aux = { ...searchData };
    const removedField = field === 'createdAt' ? 'priority' : 'createdAt';
    if (aux[removedField]) {
      delete aux[removedField];
    }
    if (aux[field]) {
      aux[field] = aux[field] === sort.ascending ? sort.descending : sort.ascending;
    } else {
      aux[field] = sort.descending;
    }
    setsearchData(aux);
  };

  const filterProjects = (value: string) => {
    setsearchData({ ...searchData, type: value });
  };

  // creates table labels
  const getLabels = () => [
    t('project.avatar'),
    t('project.title'),
    <>
      {t('project.priority')}
      <SortIcon handleClick={() => setSorting('priority')} />
    </>,
    t('project.lead'),
    <>
      {t('project.createdAt')}
      <SortIcon handleClick={() => setSorting('createdAt')} />
    </>,
    <>
      <CustomAutoComplete inTableCell={true} handleChange={filterProjects} id="type-select" label={t('project.all_types')} labels={projectsTypes} />
    </>,
    t('common.actions'),
  ];
  // maps projects data from global state to the data that will be displayed
  const getData = () =>
    projects.map((proj) => ({
      id: proj.id,
      avatar: <AvatarIcon name={proj.title} avatar={proj.avatar} />,
      title: (
        <MuiLink underline="hover" component={Link} to={routes.backlog + '/' + proj.id}>
          {proj.title}
        </MuiLink>
      ),
      priority: <StarGenerator starsNumber={proj.priority} />,
      lead: proj.lead.firstName,
      createdAt: moment(proj.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
      type: t('common.' + proj.type),
      actions: isRemovedProjectsPage ? (
        <Tooltip title={t('project.restore') as string}>
          <IconButton onClick={() => dispatch(restoreProjectAction(proj.id))}>
            <RestoreFromTrashIcon className={'folder_icon'} />
          </IconButton>
        </Tooltip>
      ) : (
        <Fragment>
          <Tooltip title={t('project.attachments') as string}>
            <IconButton
              onClick={() => {
                setProjectId(proj.id);
              }}
            >
              <AttachFileIcon />
            </IconButton>
          </Tooltip>
          {isPermitted(user, resources.PROJECTS, Permissions.edit) && (
            <IconButton onClick={() => navigate(routes.project, proj.id)}>
              <EditIcon className="edit_icon" />
            </IconButton>
          )}
          {isPermitted(user, resources.PROJECTS, Permissions.delete) && (
            <IconButton
              onClick={() => {
                setToBeDeletedProjectId(proj.id);
                setOpenAlert(true);
              }}
            >
              <DeleteIcon className="delete_icon" />
            </IconButton>
          )}
        </Fragment>
      ),
    }));

  return (
    <Grid container paddingTop={5}>
      <Stack direction="row" justifyContent="space-between" width="100%" padding="0 20px">
        <Typography paddingLeft="50px" variant="h4">
          {pageTitle}
        </Typography>
        {!isRemovedProjectsPage && (
          <Tooltip title={t('project.view_removed_projects') as string}>
            <IconButton onClick={() => navigate(routes.removedProjects)}>
              <FolderDeleteIcon className={'folder_icon'} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <CustomTable
        headBtn={
          isPermitted(user, resources.PROJECTS, Permissions.edit) && (
            <Button variant="contained" onClick={() => navigate(routes.project)}>
              {t('project.' + 'add_project')}
            </Button>
          )
        }
        loading={loading}
        data={getData()}
        labels={getLabels()}
        tableOptions={searchData}
        handleChange={setsearchData}
        total={total}
      />
      <DeleteAlert
        message={t('project.confirm_delete')}
        open={openAlert || deletingProject}
        saving={deletingProject}
        save={false}
        handleOpen={() => {
          setOpenAlert(!openAlert);
        }}
        action={() => {
          dispatch(deleteProjectAction(toBeDeletedProjectId));
        }}
      />
      <AttachementsAlert
        body={<Attachements hasAction={false} documents={projects.find((proj) => proj.id === projectId)?.documents as string[]} />}
        open={!!projectId}
        handleOpen={() => {
          setProjectId('');
        }}
        hasAction={false}
      />
    </Grid>
  );
};

export default React.memo(Projects);
