export const common = {
  hello: 'Welcome!',
  no_data: 'No data found',
  details: 'Details',
  search: 'Search',
  create_project: 'Create a project',
  user_search: 'Search users...',
  project_search: 'Search projects...',
  actions: 'Actions',
  intern: 'Intern',
  client: 'Client',
  chef: 'Chef',
  basic: 'Basic',
  hr: 'Hr',
  admin: 'Admin',
  'All types': 'All Types',
  save: 'Save',
  saving: 'Saving...',
  cancel: 'Cancel',
  preview: 'Preview',
  password_updated: 'Password Successfully Updated',
  reset_password: 'Forgot password ? Click',
  here: 'Here',
  email_sent: 'Email was sent successfully',
  active: 'Active',
  disabled: 'Disabled',
  delete: 'Delete',
  file_added: 'File(s) added successfully',
  file_removed: 'File removed successfully',
  start_date: 'Start Date',
  end_date: 'End Date',
  weeks: 'Weeks',
  days: 'Days',
  hours: 'Hours',
  minutes: 'Minutes',
  'Quarter Day': 'Quarter Day',
  Hour: 'Hour',
  Day: 'Day',
  'Half Day': 'Half Day',
  Week: 'Week',
  Month: 'Month',
};
