import React, { useEffect, useState } from 'react';
import {
  Badge,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { routes, SocketEvents } from 'core/enums';
import { AppState } from 'store';
import { MessageDetails, QueryData } from 'core/models';
import { Notifications as NotificationsIcon, Forum as ForumIcon, FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';
import { navigate } from 'core/services/history.service';
import { sendMessageSuccessAction, setChatAsNotReadAction } from 'modules/Chat/state/chat.actions';
import { useTranslation } from 'react-i18next';
import { getNotificationsAction, markAsReadAction, ReceivedNotificationAction } from './state/notification.actions';
import { AvatarIcon, ListItemLoader } from 'shared/components';
import moment from 'moment';
import { updateTaskSuccessAction } from 'modules/Task/state/task.actions';

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [messageCount, setMessageCount] = useState(0);
  const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = useState<null | HTMLElement>(null);
  const {
    socket: { socket },
    notifications: { notifications, total, limit, loading },
  } = useSelector((state: AppState) => state);

  const chatId = pathname.includes(routes.chatbox) ? id : undefined;
  const notReadNotificationsNumber = notifications.reduce((acc, current) => (acc += current.read ? 0 : 1), 0);
  const openNotificationMenu = Boolean(notificationMenuAnchorEl);

  useEffect(() => {
    dispatch(getNotificationsAction({ limit }));
    socket.on(SocketEvents.receiveNofitication, (payload) => {
      dispatch(updateTaskSuccessAction(payload.task));
      dispatch(ReceivedNotificationAction(payload));
    });
    return () => {
      socket.off();
    };
  }, []);

  useEffect(() => {
    socket.on(SocketEvents.receiveMessageInRoom, (payload: MessageDetails) => {
      setMessageCount((prev) => prev + 1);
      dispatch(setChatAsNotReadAction(payload.chat));
      if (chatId === payload.chat) {
        dispatch(sendMessageSuccessAction(payload));
      }
    });
    return () => {
      socket.off();
    };
  }, [chatId]);

  const readNotification = (notifId: string) => {
    dispatch(markAsReadAction([notifId]));
  };

  const handleCheck = (checked: boolean) => {
    const query: QueryData = { limit };
    if (checked) {
      query.unread = true;
    }
    dispatch(getNotificationsAction(query));
  };
  return (
    <>
      <Tooltip title={t('chatbox.chatbox') as string}>
        <IconButton
          onClick={() => {
            if (!pathname.includes(routes.chatbox)) {
              navigate(routes.chatbox);
            }
            setMessageCount(0);
          }}
        >
          <Badge badgeContent={messageCount} color="secondary" max={10}>
            <ForumIcon className="menu_icon" />
          </Badge>
        </IconButton>
      </Tooltip>

      {/* notifications menu */}
      <IconButton
        id="notifications-menu-button"
        aria-controls={notificationMenuAnchorEl ? 'notifications-menu' : undefined}
        aria-expanded={notificationMenuAnchorEl ? 'true' : undefined}
        onClick={(e) => setNotificationMenuAnchorEl(e.currentTarget)}
      >
        <Badge badgeContent={notReadNotificationsNumber} color="secondary" max={10}>
          <NotificationsIcon className="menu_icon" />
        </Badge>
      </IconButton>
      <Menu
        id="notifications-menu"
        anchorEl={notificationMenuAnchorEl}
        open={openNotificationMenu}
        MenuListProps={{ 'aria-labelledby': 'notifications-menu-button' }}
        onClose={() => setNotificationMenuAnchorEl(null)}
      >
        <List className="notification_list">
          <ListItem>
            <ListItemText primaryTypographyProps={{ variant: 'h6' }} primary={t('notification.notifications')} />
            <ListItemSecondaryAction>
              <FormControlLabel
                control={<Switch defaultChecked={false} onChange={(e) => handleCheck(e.target.checked)} />}
                label={t('notification.show_unread_notifications') as string}
              />
            </ListItemSecondaryAction>
          </ListItem>
          {loading ? (
            Array(notifications?.length ?? 3)
              .fill(null)
              .map((_, i) => <ListItemLoader key={i} />)
          ) : notifications.length ? (
            notifications.map(
              ({
                task: {
                  reporter: { firstName, lastName, avatar },
                  type,
                  title,
                  key,
                  status,
                },
                ...notification
              }) => {
                const timeAgo = moment(notification.createdAt).fromNow();
                const name = firstName + ' ' + lastName;
                return (
                  <ListItemButton
                    key={notification.id}
                    onClick={() => {
                      readNotification(notification.id);
                      setNotificationMenuAnchorEl(null);
                      navigate(routes.backlog, notification.project);
                    }}
                  >
                    <ListItemAvatar>
                      <AvatarIcon name={name} avatar={avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography={true}
                      primary={
                        <Typography>
                          {name + ' ' + t('notification.' + notification.content)}
                          <Typography color="gray" component="span" variant="body2" className="notification_time">
                            {timeAgo}
                          </Typography>
                        </Typography>
                      }
                      secondary={
                        <Stack>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <AvatarIcon height={20} width={20} name={type} />
                            <Typography>{title}</Typography>
                          </Stack>
                          <Typography color="gray">{(key + ' ' + status).toUpperCase()}</Typography>
                        </Stack>
                      }
                    />
                    <ListItemSecondaryAction>
                      {!notification.read && <FiberManualRecordIcon className={'notification_not_red_icon'} />}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                );
              },
            )
          ) : (
            <Typography variant="h6" color="gray" align="center" gutterBottom>
              {t('notification.no_notifications')}
            </Typography>
          )}
          <Divider />
          <br />
          {notifications.length === total ? (
            <Typography color="gray" align="center">
              {t('notification.no_more_notifications')}
            </Typography>
          ) : (
            <Typography
              align="center"
              color="primary"
              className="more_notifications_btn"
              onClick={() => dispatch(getNotificationsAction({ limit: limit + 10 }))}
            >
              {t('notification.more_notifications')}
            </Typography>
          )}
        </List>
      </Menu>
    </>
  );
};

export default React.memo(Notifications);
