import React, { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';

interface Props {
  handleClick: () => void;
}

export const SortIcon: React.FC<Props> = ({ handleClick }) => {
  const [showAscIcon, setShowAscIcon] = useState(true);

  return (
    <IconButton
      size="small"
      onClick={() => {
        handleClick();
        setShowAscIcon((prev) => !prev);
      }}
    >
      {showAscIcon ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};
