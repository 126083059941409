export const sprint = {
  title: 'Title',
  goal: 'Goal',
  startDate: 'Start date',
  dueDate: 'Due date',
  confirm_delete: 'Are you sure you want to delete this sprint?',
  sprint_created: 'Sprint successfully created',
  sprint_updated: 'Sprint successfully updated',
  sprint_deleted: 'Sprint successfully deleted',
  edit_sprint: 'Edit sprint',
  delete_sprint: 'Delete sprint',
  view_planning: 'View planning',
  view_mode: 'View mode',
  planning: 'Planning',
  no_planning: 'No planning available yet',
};
