export const chatbox = {
  chatbox: 'Chatbox',
  create_group: 'Create new group',
  welcome_message: 'Click on conversation and start chatting!',
  select_members: 'Select members to join',
  group_name: 'Group Name',
  members: 'members',
  connected_users: 'Connected users',
  starter_text: 'Aa',
  sent_message: 'sent a message',
  remove_member: 'Remove member',
  removed_user: 'Removed user',
  add_member: 'Add member',
  update_chat: 'Updating...',
  update_success: 'chatbox updated successfully',
  member: 'Member',
  not_member: 'Not a member',
  admin: 'Group Admin',
};
