import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Box,
  ThemeProvider,
  TablePagination,
  debounce,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTableTheme } from 'shared/themes';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { QueryData } from 'core/models';
import { Search } from '../Search/search.component';
import styles from './table.module.scss';
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  labels: (string | JSX.Element)[];
  loading: boolean;
  handleChange?: (data: QueryData) => void;
  tableOptions?: QueryData;
  total: number;
  hasSearch?: boolean;
  hasPagination?: boolean;
  headBtn?: JSX.Element | null | false;
}

export const CustomTable: React.FC<Props> = ({
  data,
  labels,
  loading,
  handleChange = () => {},
  tableOptions = {} as QueryData,
  total,
  hasSearch = true,
  hasPagination = true,
  headBtn = null,
}) => {
  const { t } = useTranslation();
  const { theme } = useSelector((state: AppState) => state.auth.user);

  const handleSearchChange = debounce((value: string) => {
    handleChange({ ...tableOptions, search: value, page: 0 });
  }, 500);

  const handlePaginationChange = debounce((key: string, value: number) => {
    handleChange({ ...tableOptions, [key]: value });
  }, 500);

  return (
    <ThemeProvider theme={getTableTheme(theme)}>
      <Box className={styles.container}>
        <Stack className={styles.table_tools_container} direction="row" justifyContent="flex-end">
          {hasSearch && <Search setsearchData={handleSearchChange} value={tableOptions.search?.toString()} />}
          {headBtn && headBtn}
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {labels?.map((label, key) => (
                  <TableCell align="center" key={key} variant="head">
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : data.length ? (
                data.map((dataElement, rowIndex) => (
                  <TableRow key={'table-row-' + rowIndex}>
                    {Object.keys(dataElement).map(
                      (field, cellIndex) =>
                        field !== 'id' && (
                          <TableCell key={'table-cell-' + cellIndex} align="center">
                            {field === 'avatar' ? dataElement[field] : <Typography> {dataElement[field]}</Typography>}
                          </TableCell>
                        ),
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} className="no_data_cell">
                    <Typography className="no_data"> {t('common.no_data')}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {hasPagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              rowsPerPage={+tableOptions.limit || 5}
              page={+tableOptions.page || 0}
              component="div"
              onPageChange={(_ev, value) => handlePaginationChange('page', value)}
              onRowsPerPageChange={(event) => handlePaginationChange('limit', +event.target.value)}
              count={total}
            />
          )}
        </TableContainer>
      </Box>
    </ThemeProvider>
  );
};
