import { PayloadAction } from '@reduxjs/toolkit';
import { localStorageKeys } from 'core/enums/local-storage-keys';
import { ErrorResponseData } from 'core/models';
import { recoverPw, resetPw } from 'core/services/auth.service';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { all, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { FormikValues } from 'shared/interface';
import { failAction, recoverPwAction, recoverPwSuccessAction, resetPwAction, resetPwSuccessAction } from './password.actions';

function* recoverPwEffect({ payload }: PayloadAction<FormikValues>): Generator<Promise<FormikValues> | PutEffect, void, FormikValues> {
  try {
    const { resetPasswordExpires, resetPasswordToken } = yield recoverPw({ body: { email: payload.email } });
    localStorage.setItem(localStorageKeys.pw_token, resetPasswordToken);
    localStorage.setItem(localStorageKeys.pw_expires, resetPasswordExpires);
    yield put(recoverPwSuccessAction({ resetPasswordExpires, resetPasswordToken }));
    yield put(openSnackBarAction({ message: 'common.email_sent', severity: 'success' }));
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* resetPwEffect({ payload }: PayloadAction<FormikValues>): Generator<Promise<void> | PutEffect, void, FormikValues> {
  try {
    yield resetPw({ body: payload });
    localStorage.removeItem(localStorageKeys.pw_expires);
    localStorage.removeItem(localStorageKeys.pw_token);
    yield put(resetPwSuccessAction());
    yield put(openSnackBarAction({ message: 'common.password_updated', severity: 'success' }));
  } catch (error) {
    yield put(failAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  yield put(
    openSnackBarAction({
      message: error.status ? 'error.server' : 'error.network',
      severity: 'error',
    }),
  );
}

export function* watchPassword() {
  yield all([
    takeLatest(recoverPwAction.type, recoverPwEffect),
    takeLatest(resetPwAction.type, resetPwEffect),
    takeLatest(failAction.type, failEffect),
  ]);
}
