export const apiUrl = '/';

export enum ApiUrls {
  Auth = 'login',
  getUsers = 'user',
  getUser = 'user',
  addUser = 'user',
  deleteUser = 'user',
  updateUser = 'user',
  profile = 'profile',
  recoverPw = 'recover',
  resetPw = 'reset',

  deleteProject = 'project',
  restoreProject = 'project/restore',
  getProjects = 'project',
  getProject = 'project',
  addProject = 'project',
  updateProject = 'project',

  downlaodProjectDocs = 'project/docs',
  uploadProjectDocs = 'project/docs',
  deleteProjectDocs = 'project/remove/docs',

  getProfiles = 'role',
  addProfile = 'role',
  updateProfile = 'role',
  deleteProfile = 'role',

  getBacklog = 'planning',

  addSprint = 'sprint',
  updateSprint = 'sprint',
  deleteSprint = 'sprint',

  addTask = 'task',
  updateTask = 'task',

  getEvents = 'event',
  addEvent = 'event',
  updateEvent = 'event',
  deleteEvent = 'event',

  createRecommendation = 'recommendation',
  getRecommendations = 'recommendation',
  removeRecommendation = 'recommendation',

  getChats = 'chat',
  createChat = 'chat',
  updateChat = 'chat',

  getMessages = 'message',
  sendMessage = 'message',

  getNotifications = 'notification',
  createNotification = 'notification',
  markAsRead = 'notification',

  subscribeToPushNotifications = 'notification/subscribe',
}
