import { Box, Button, ButtonGroup, Container, CssBaseline, Grid, IconButton, Paper, Stack, ThemeProvider, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarIcon, EditableField, MuiBtnGrp } from 'shared/components';
import styles from './profile.module.scss';
import { updateProfileAction } from './state/profile.actions';
import { UserDetails } from 'core/models';
import {
  Web as WebIcon,
  CameraAlt as CameraAltIcon,
  EditLocation as EditLocationIcon,
  Email as EmailIcon,
  Work as WorkIcon,
  ColorLens as ColorLensIcon,
  Badge as BadgeIcon,
  VpnKey as VpnKeyIcon,
} from '@mui/icons-material/';
import { ChromePicker } from 'react-color';
import { previewThemeAction, cancelPreviewAction } from './state/profile.actions';
import { AppState } from 'store';
import { getPersistedTheme } from 'core/utils';
import { defaultTheme } from 'core/constants';
import { getProfileTheme } from 'shared/themes';
import { FieldData } from 'shared/interface';
import CustomCalendar from 'modules/Calendar/calendar.component';

interface UserInfo {
  [key: string]: {
    id: string;
    icon: JSX.Element;
  }[];
}

const userInfo: UserInfo = {
  about: [
    { id: 'firstName', icon: <BadgeIcon /> },
    { id: 'lastName', icon: <BadgeIcon /> },
    { id: 'job_title', icon: <WorkIcon /> },
    { id: 'department', icon: <WebIcon /> },
    { id: 'location', icon: <EditLocationIcon /> },
  ],
  contact: [
    { id: 'email', icon: <EmailIcon /> },
    { id: 'password', icon: <VpnKeyIcon /> },
  ],
};

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state: AppState) => state.auth);
  const [avatar, setAvatar] = useState<File | null | undefined>();
  const [aboutData, setAboutData] = useState<FieldData>({} as FieldData);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [themeColors, setThemeColors] = useState<{ [key: string]: string }>({
    primary_color: user.theme[0],
    secondary_color: user.theme[1],
  });
  const name = user.firstName + ' ' + user.lastName;

  const getAvatarPreview = () => URL.createObjectURL(avatar as Blob);
  const clearData = () => setAboutData({} as FieldData);
  useEffect(() => {
    if (!loading) {
      clearData();
      setAvatar(null);
    }
  }, [loading]);

  const update = async () => {
    const currentTheme = Object.values(themeColors);
    let data = { ...user, theme: currentTheme };
    // check if the avatar field is gonna be updated else it won't be passed in the api call
    if (avatar) {
      data = { ...data, avatar };
    } else {
      // remove the avatar field from the data in case its not going to be updated
      delete data.avatar;
    }
    // checks if a field in the about section has been updated
    // if yes it will be added to the data that is going to be updated
    if (aboutData.id) {
      data = { ...data, [aboutData.id]: aboutData.value };
    }
    dispatch(updateProfileAction(UserDetails.mapToApiValue(data)));

    if (showColorPicker) {
      setShowColorPicker(false);
    }
  };
  const handlePreview = () => {
    const previewTheme = Object.values(themeColors);
    dispatch(previewThemeAction(previewTheme));
  };

  const cancelPreview = () => {
    if (showColorPicker) {
      dispatch(cancelPreviewAction(getPersistedTheme() ?? defaultTheme));
    }
    setShowColorPicker(!showColorPicker);
  };

  return (
    <ThemeProvider theme={getProfileTheme(user.theme)}>
      <CssBaseline />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Stack alignItems="center" direction="column" justifyContent="center" padding={1} width="100%">
              <Tooltip title={t('profile.edit_profile_pic').toString()}>
                <IconButton aria-label="upload picture" component="label" className={styles.photo_edit_btn}>
                  <input hidden accept="image/*" id="icon-button-file" type="file" onChange={(e) => setAvatar(e.currentTarget.files?.item(0))} />
                  <CameraAltIcon className={styles.photo_icon} />
                  <AvatarIcon height={150} width={150} avatar={avatar ? getAvatarPreview() : user.avatar} name={name} />
                </IconButton>
              </Tooltip>
              {avatar && <MuiBtnGrp save={true} submit={false} handleSubmit={() => update()} saving={loading} handleCancel={() => setAvatar(null)} />}
              <Typography variant="h4" className={styles.name}>
                {name}
              </Typography>
              <Container className="user_info">
                {Object.keys(userInfo).map((section) => (
                  <Box key={section}>
                    <Typography variant="h6" gutterBottom className="section_title">
                      {t(`profile.${section}`)}
                    </Typography>
                    {userInfo[section].map((el, i) => (
                      <EditableField
                        user={user}
                        loading={loading}
                        setField={setAboutData}
                        field={aboutData}
                        // field element
                        el={el}
                        key={'field-' + i}
                        update={update}
                        clearData={clearData}
                      />
                    ))}
                  </Box>
                ))}
              </Container>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack justifyContent="center" alignItems="center">
              <Stack id="theme_edit" justifyContent="center" alignItems="center" spacing={2}>
                <Button className="edit_theme_btn" variant="contained" onClick={() => cancelPreview()}>
                  <ColorLensIcon className="theme_icon" /> {showColorPicker ? t('common.cancel') : t('profile.theme_edit')}
                </Button>
                {showColorPicker && (
                  <Stack direction="column" justifyContent="center" alignItems="center">
                    <Grid container spacing={2} width="100%">
                      {Object.keys(themeColors).map((field, key) => (
                        <Grid key={key} item xs={11} sm={6}>
                          <Tooltip title={t('profile.edit_' + field) as string}>
                            <div>
                              <ChromePicker
                                color={themeColors[field]}
                                onChange={(newColor) => setThemeColors({ ...themeColors, [field]: newColor.hex })}
                              />
                            </div>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                    <ButtonGroup variant="contained" className="theme_action_btns">
                      <Button onClick={() => update()}>{t('common.save')}</Button>
                      <Button onClick={() => handlePreview()}>{t('common.preview')}</Button>
                    </ButtonGroup>
                  </Stack>
                )}
              </Stack>
              <CustomCalendar />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};

export default React.memo(Profile);
