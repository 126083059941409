import { Box, Checkbox, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Permissions, profileStatus, resources } from 'core/enums';
import { routes } from 'core/enums/routes';
import { ProfileDetails } from 'core/models/profile/profile-details';
import { history } from 'core/services/history.service';
import { loadProfilesAction } from 'modules/Profiles/state/profiles.actions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomTable, Input, ItemWrapper, MuiBtnGrp } from 'shared/components';
import { AppState } from 'store';
import { addRoleAction, updateRoleAction } from './state/role.actions';

const Role: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { profiles, loading } = useSelector((state: AppState) => state.profiles);
  const [roleData, setRoleData] = useState<ProfileDetails>({} as ProfileDetails);
  const textFields = ['title', 'description'];

  useEffect(() => {
    if (id) {
      dispatch(loadProfilesAction());
      const data = profiles.find((profile) => profile.id === id);
      if (data) {
        setRoleData(data);
      }
    }
  }, [profiles.length]);

  const getLabels = () => {
    const actionLabels = ['access', 'edit', 'delete'].map((label, i) => <Typography key={i}>{t(`profiles.${label}`)}</Typography>);
    return [`${t('profiles.role')}/${t('profiles.section')}`, ...actionLabels];
  };
  const getData = () => {
    const data = Object.values(resources).map((resource) => {
      const access = roleData?.sections?.find(({ value }) => value === resource)?.access || 0;
      return {
        resource,
        access: <Checkbox onClick={() => handleCellClick(resource, Permissions.access)} checked={access >= Permissions.access} />,
        edit: (
          <Checkbox
            disabled={access < Permissions.access}
            onClick={() => handleCellClick(resource, Permissions.edit)}
            checked={access === Permissions.edit || access === Permissions.all}
          />
        ),
        delete: (
          <Checkbox
            disabled={access < Permissions.access}
            onClick={() => handleCellClick(resource, Permissions.delete)}
            checked={access === Permissions.delete || access === Permissions.all}
          />
        ),
      };
    });
    return data;
  };
  const handleCellClick = (resource: string, permission: number) => {
    const sections = roleData?.sections?.length ? [...roleData.sections] : [];
    const index = sections.findIndex(({ value }) => value === resource);
    if (index === -1) {
      sections.push({ value: resource, access: permission });
    } else {
      let access = sections[index].access;
      if (!access) {
        access = permission;
      } else if (access === permission) {
        access = access === Permissions.access ? 0 : access - (permission - Permissions.access);
      } else if (permission > access) {
        access += permission - Permissions.access;
      } else if (permission < access) {
        access = access === Permissions.all ? access - (permission - Permissions.access) : access + (permission - Permissions.access);
      }
      sections[index] = { ...sections[index], access };
    }
    setRoleData({ ...roleData, sections });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    id ? dispatch(updateRoleAction(roleData)) : dispatch(addRoleAction(roleData));
  };

  return (
    <Box component="form" sx={{ padding: '20px' }} onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
      <Typography variant="h4" gutterBottom>
        {t('profiles.' + (id ? 'edit' : 'add') + '_profile')}
      </Typography>

      <Grid container spacing={2}>
        {textFields.map((field, i) => (
          <ItemWrapper key={i}>
            <Input
              value={roleData[field] ?? ''}
              placeholder={field}
              label={field}
              multiline={field === 'description'}
              handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setRoleData({ ...roleData, [field]: e.target.value })}
            />
          </ItemWrapper>
        ))}
      </Grid>
      <CustomTable loading={loading} labels={getLabels()} data={getData()} hasSearch={false} hasPagination={false} total={0} />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
        <FormControlLabel
          control={
            <Switch
              checked={roleData?.status === profileStatus.ACTIVE}
              onClick={() =>
                setRoleData({ ...roleData, status: roleData.status === profileStatus.ACTIVE ? profileStatus.DISABLED : profileStatus.ACTIVE })
              }
            />
          }
          label={t(`profiles.${roleData?.status ?? profileStatus.DISABLED}`) as string}
        />
        <MuiBtnGrp save={true} submit={true} handleCancel={() => history.push(routes.profiles)} />
      </Box>
    </Box>
  );
};

export default Role;
