import { ManageUser } from 'modules/User/manageUser.component';
import React, { useEffect } from 'react';

export const ManageUserPage: React.FC = () => {
  // setting page title for SEO
  useEffect(() => {
    document.title = 'Manage page';
  }, []);
  return <ManageUser />;
};
