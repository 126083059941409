export enum routes {
  login = '/login',
  users = '/users',
  user = '/user',
  profile = '/profile',
  projects = '/projects',
  removedProjects = '/projects/removed',
  project = '/project',
  backlog = '/backlog',
  planning = '/planning',
  events = '/events',
  profiles = '/profiles',
  role = '/role',
  recover_password = '/recover_password',
  reset_password = '/reset_password',
  recommendations = '/recommendations',
  chatbox = '/chatbox',
  other = '*',
}
