import React, { useState } from 'react';
import {
  ListItemButton,
  ListItemText,
  List,
  ListItemIcon,
  Collapse,
  ListItemSecondaryAction,
  Stack,
  Typography,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { SprintDetails } from 'core/models/sprint/sprint-details';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  ArrowForward as ArrowForwardIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';
import { Alert } from 'shared/components';
import { useTranslation } from 'react-i18next';
import Task from 'modules/Task/task.component';
import { ProjectDetails } from 'core/models';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { SprintEdit } from 'modules/Backlog/components/edit-sprint.component';
import { deleteSprintAction } from 'modules/Backlog/state/backlog.actions';
import { navigate } from 'core/services/history.service';
import { routes } from 'core/enums';
import { AppState } from '../../store';
import { TaskItem } from './components/task.component';

interface Props {
  sprint: SprintDetails;
  project: ProjectDetails;
}

const Sprint: React.FC<Props> = ({ sprint = {} as SprintDetails, project = {} as ProjectDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { theSprintBeingUpdated, sprintBeingRemoved, theTaskBeingCreated } = useSelector((state: AppState) => state.backlog);

  const [open, setOpen] = useState(false);

  const [sprintId, setSprintId] = useState<string>();

  const [sprintMenuAnchorEl, setSprintMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [editingSprint, setEditingSprint] = useState<boolean>(false);

  const [creatingTask, setCreatingTask] = useState<boolean>(false);

  const sprintMenuIsOpen = Boolean(sprintMenuAnchorEl);
  const openDeleteConfirmation = Boolean(sprintId);

  const startDate = moment(sprint.startDate).format('MMMM Do');
  const endDate = moment(sprint.endDate).format('MMMM Do');

  return (
    <>
      <List className="sprint_list">
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemIcon>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ListItemIcon>
          <ListItemText
            className="sprint_title"
            primary={
              <Stack direction="row" spacing={2}>
                <Typography>{sprint.title}</Typography>
                <Stack direction="row" alignItems="center">
                  <Typography className="date">{startDate}</Typography>
                  <ArrowForwardIcon className="arrow_forward_icon" />
                  <Typography className="date">{endDate}</Typography>
                </Stack>
                <Typography className="date">
                  {t('task.issues')} : {sprint.tasks.length}
                </Typography>
              </Stack>
            }
          />
          <ListItemSecondaryAction>
            <Button
              id="sprint-menu-button"
              onClick={(e) => {
                e.stopPropagation();
                setSprintMenuAnchorEl(e.currentTarget);
              }}
              aria-controls={sprintMenuAnchorEl ? 'sprint-menu' : undefined}
              aria-expanded={sprintMenuAnchorEl ? 'true' : undefined}
              endIcon={<MoreHorizIcon />}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
        <Menu
          id="sprint-menu"
          anchorEl={sprintMenuAnchorEl}
          open={sprintMenuIsOpen}
          MenuListProps={{ 'aria-labelledby': 'sprint-menu-button' }}
          onClose={() => setSprintMenuAnchorEl(null)}
        >
          <MenuItem disabled={!sprint?.tasks?.length} onClick={() => navigate(routes.planning, project.id + '/' + sprint.id)}>
            {t('sprint.view_planning')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setEditingSprint(true);
              setSprintMenuAnchorEl(null);
            }}
          >
            {t('sprint.edit_sprint')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSprintId(sprint.id);
            }}
          >
            <Button>{t('sprint.delete_sprint')}</Button>
          </MenuItem>
        </Menu>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sprint?.tasks?.map((task, i) => (
              <TaskItem task={task} project={project} key={i} />
            ))}
            <ListItemButton onClick={() => setCreatingTask(true)}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={t('project.create_issue') as string} />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      {/** dialog for handeling creating a new task */}
      <Alert
        open={creatingTask || theTaskBeingCreated?.sprint === sprint.id}
        handleOpen={() => {
          setCreatingTask(!creatingTask);
        }}
        hasAction={false}
        body={
          <Task
            project={project}
            sprint={sprint}
            handleClose={() => {
              setCreatingTask((p) => !p);
            }}
          />
        }
      />
      {/* dialog for editing sprint */}
      <Alert
        open={editingSprint || theSprintBeingUpdated?.id === sprint.id}
        handleOpen={() => {
          setEditingSprint(!editingSprint);
        }}
        hasAction={false}
        body={
          <SprintEdit
            toBeEditedSprint={sprint}
            handleClose={() => {
              setEditingSprint(false);
            }}
          />
        }
      />
      {/* dialog for deleting sprint */}
      <Alert
        open={openDeleteConfirmation}
        handleOpen={() => {
          if (sprintBeingRemoved) {
            setSprintId(undefined);
          }
        }}
        body={
          <Typography variant="h6" padding="15px">
            {t('sprint.confirm_delete')}
          </Typography>
        }
        saving={sprintBeingRemoved}
        saveIsDisabled={sprintBeingRemoved}
        action={() => {
          if (sprintId) {
            dispatch(deleteSprintAction(sprintId));
          }
        }}
      />
    </>
  );
};

export default Sprint;
