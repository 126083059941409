import { ChatDto } from 'core/generated/ChatDTO';
import { IDocs } from 'core/generated/fetchedData';
import { MessageDTO } from 'core/generated/MessageDTO';
import { HttpParamsType, MessageDetails } from 'core/models';
import { ChatDetails } from 'core/models/chat/chat-details';
import { apiUrlMatcher, ApiUrls, get, post, put } from './helpers';

export const getChatbox = async (): Promise<ChatDetails[]> => {
  const data = await get<ChatDto[]>(apiUrlMatcher(ApiUrls.getChats));
  return data.map((chat) => ChatDetails.mapFromApiValue(chat));
};

export const createChat = async (params: HttpParamsType<ChatDto>): Promise<ChatDetails[]> => {
  const data = await post<ChatDto[]>(apiUrlMatcher(ApiUrls.createChat), params);
  return data.map((chat) => ChatDetails.mapFromApiValue(chat));
};

export const updateChat = async (params: HttpParamsType<ChatDto>): Promise<ChatDetails[]> => {
  const chatId = params.body?._id;
  const data = await put<ChatDto[]>(apiUrlMatcher(ApiUrls.updateChat) + '/' + chatId, params);
  return data.map((chat) => ChatDetails.mapFromApiValue(chat));
};

export const getMessages = async (params: HttpParamsType<string>): Promise<IDocs<MessageDetails[]>> => {
  const chatId = params.body;
  const data = await get<IDocs<MessageDTO[]>>(apiUrlMatcher(ApiUrls.getMessages) + '/' + chatId, { queryParams: params.queryParams });
  return { ...data, docs: data.docs.map((message) => MessageDetails.mapFromApiValue(message)) };
};
export const sendMessage = async (params: HttpParamsType<FormData>): Promise<MessageDetails> => {
  const data = await post<MessageDTO>(apiUrlMatcher(ApiUrls.sendMessage), params);
  return MessageDetails.mapFromApiValue(data);
};
