import { all, put, takeEvery, select, SelectEffect, PutEffect } from 'redux-saga/effects';
import { connectAction, connectSuccessAction, connectFailAction, sendMessageIRTAction, connectUsersToChatsAction } from './socket.actions';
import io, { Socket } from 'socket.io-client';
import { ChatDetails, ErrorResponseData, MessageDetails, NotificationDetails } from 'core/models';
import { openSnackBarAction } from 'modules/snackbar/store/snackbar.actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { SocketEvents } from 'core/enums';
import { AppState } from 'store';
import { createNotificationSuccessAction } from 'modules/Notifications/state/notification.actions';

const socketUrl = process.env.REACT_APP_SOCKET_ENDPOINT || '';

function* connectionEffect({ payload: userId }: PayloadAction<string>) {
  try {
    const socket = io(socketUrl);
    socket.emit(SocketEvents.userConnected, { userId });
    yield put(connectSuccessAction(socket));
  } catch (error) {
    yield put(connectFailAction(error as ErrorResponseData));
  }
}

function* sendMessageIRTEffect({ payload: message }: PayloadAction<MessageDetails>): Generator<SelectEffect | PutEffect | Socket, void, Socket> {
  try {
    const socket = yield select((state: AppState) => state.socket.socket);
    // send data in real time
    yield socket.emit(SocketEvents.sendMessageInRoom, message);
  } catch (error) {
    yield put(connectFailAction(error as ErrorResponseData));
  }
}

function* sendNotificationIRTEffect({
  payload: notification,
}: PayloadAction<NotificationDetails>): Generator<SelectEffect | PutEffect | Socket, void, Socket> {
  try {
    const socket = yield select((state: AppState) => state.socket.socket);
    // send data in real time
    yield socket.emit(SocketEvents.sendNofitication, notification);
  } catch (error) {
    yield put(connectFailAction(error as ErrorResponseData));
  }
}

function* connectUsersToChatsEffect({ payload: chats }: PayloadAction<ChatDetails[]>): Generator<SelectEffect | PutEffect | Socket, void, Socket> {
  try {
    const socket = yield select((state: AppState) => state.socket.socket);
    yield socket.emit(SocketEvents.connectToRooms, { rooms: chats.map(({ id }) => id) });
  } catch (error) {
    yield put(connectFailAction(error as ErrorResponseData));
  }
}

function* failEffect({ payload: error }: PayloadAction<ErrorResponseData>) {
  console.log(error.message);
  yield put(openSnackBarAction({ message: 'error.network', severity: 'error' }));
}

export function* watchSocketConnection() {
  yield all([
    takeEvery(connectAction.type, connectionEffect),
    takeEvery(sendMessageIRTAction.type, sendMessageIRTEffect),
    takeEvery(connectUsersToChatsAction.type, connectUsersToChatsEffect),
    takeEvery(createNotificationSuccessAction.type, sendNotificationIRTEffect),
    takeEvery(connectFailAction.type, failEffect),
  ]);
}
