// eslint-disable-next-line @typescript-eslint/no-shadow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { AppState } from 'store';
import { Alert, AlertColor, IconButton, Slide, SlideProps, Snackbar } from '@mui/material';
import { closeSnackBarAction } from './store/snackbar.actions';
import { useTranslation } from 'react-i18next';

const SnackBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, message, severity } = useSelector((state: AppState) => state.snackbar);
  // closing snackbar dispatching an action
  const handleClose = () => dispatch(closeSnackBarAction());

  type TransitionProps = Omit<SlideProps, 'direction'>;

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const TransitionLeft = (props: TransitionProps) => <Slide {...props} direction="left" />;
  return open ? (
    <Snackbar open={open} message={message} action={action} autoHideDuration={6000} onClose={handleClose} TransitionComponent={TransitionLeft}>
      <Alert severity={severity as AlertColor} variant="filled" onClose={handleClose}>
        {t(message)}
      </Alert>
    </Snackbar>
  ) : null;
};

export default SnackBar;
