import { UserDTO } from '../../generated/UserDto';
const api = process.env.REACT_APP_API_ENDPOINT;
export interface I {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export interface UserDetails extends I {
  id: string;
  token: string;
  email: string;
  password?: string;
  firstName: string;
  theme: string[];
  lastName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  avatar: any;
  location?: string;
  department?: string;
  job_title?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  subscriptions?: Object;
  disabled: boolean;
  role: string;
}

export namespace UserDetails {
  export const mapFromApiValue = (userDto: UserDTO): UserDetails => {
    const user = {} as UserDetails;
    user.disabled = userDto.disabled;
    Object.keys(userDto).forEach((key) => {
      if (userDto[key]) {
        if (key === '_id') {
          user['id'] = userDto[key];
        } else if (key === 'avatar') {
          user['avatar'] = api + userDto[key];
        } else {
          user[key] = userDto[key];
        }
      }
    });
    return user;
  };

  export const mapToApiValue = (user: UserDetails): UserDTO => {
    const userDto = {} as UserDTO;
    userDto.disabled = user.disabled;
    Object.keys(user).forEach((key) => {
      if (user[key]) {
        if (key === 'id') {
          userDto._id = user[key];
        } else {
          userDto[key] = user[key];
        }
      }
    });
    return userDto;
  };

  export const mapToFormData = (data: UserDTO): FormData => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'profile') {
        formData.append(key, data[key]._id);
      } else {
        formData.append(key, data[key]);
      }
    });
    return formData;
  };
}
