export const recommendation = {
  create_recommendation: 'Give feedback!',
  create_recommendation_success: 'Thanks for your feedback!',
  anonymos: 'Anonymos',
  recommendation_number: 'Recommendation number #',
  delete: 'Remove recommendation',
  confirm_delete: 'Are you sure you want to delete this recommendation?',
  delete_success: 'Recommendation removed successfully',
  is_being_deleted: 'Recommendation is being removed...',
  placeholder: 'Hello!...',
};
