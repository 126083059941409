import { Button, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttachFile as AttachFileIcon, UploadFile as UploadFileIcon } from '@mui/icons-material/';

interface Props {
  handleChange: (files: FileList) => void;
  variant?: 'button' | 'icon_button';
}

export const FileUpload: React.FC<Props> = ({ variant = 'icon_btn', handleChange = () => {} }) => {
  const { t } = useTranslation();
  const uploadComponent = (
    <input
      hidden
      multiple
      name={'avatar'}
      type="file"
      onChange={(e) => {
        handleChange(e.target.files as FileList);
      }}
    />
  );
  return variant === 'button' ? (
    <Button className="upload_btn" endIcon={<UploadFileIcon />} variant="contained" aria-label="upload file" component="label">
      {uploadComponent}
      {t('project.upload_files')}
    </Button>
  ) : (
    <Tooltip title={t('project.upload_files') as string}>
      <IconButton aria-label="upload file" component="label">
        {uploadComponent} <AttachFileIcon />
      </IconButton>
    </Tooltip>
  );
};
