import { webpushPublicKey } from 'core/constants';
import { IDocs } from 'core/generated/fetchedData';
import { NotificationDTO } from 'core/generated/NotificationDTO';
import { HttpParamsType, NotificationDetails, QueryData, UserDetails } from 'core/models';
import { urlBase64ToUint8Array } from 'core/utils';
import { PushNotificationDetails } from 'shared/interface';
import { apiUrlMatcher, ApiUrls, get, post, put } from './helpers';
import { updateUser } from './user.service';

export const getNotifications = async (query: QueryData): Promise<IDocs<NotificationDetails[]>> => {
  const data = await get<IDocs<NotificationDTO[]>>(apiUrlMatcher(ApiUrls.getNotifications), { queryParams: query });
  return { ...data, docs: data.docs.map((notification) => NotificationDetails.mapFromApiValue(notification)) };
};

export const createNotification = async (params: HttpParamsType<NotificationDTO>): Promise<NotificationDetails> => {
  const data = await post<NotificationDTO>(apiUrlMatcher(ApiUrls.createNotification), params);
  return NotificationDetails.mapFromApiValue(data);
};

export const markAsRead = async (params: HttpParamsType<string[]>): Promise<IDocs<NotificationDetails[]>> => {
  const data = await put<IDocs<NotificationDTO[]>>(apiUrlMatcher(ApiUrls.markAsRead), params);
  return { ...data, docs: data.docs.map((notification) => NotificationDetails.mapFromApiValue(notification)) };
};

// subscribe to push notifications service
export const subscribe = (id: string) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(async (register) => {
      register.pushManager.getSubscription().then((subscription) => {
        // checks if user is already subscribed
        if (subscription) {
          console.log('user already subscribed');
          updateUser({
            body: UserDetails.mapToApiValue({ subscription } as unknown as UserDetails),
            queryParams: { id },
          }).then(() => console.log('subscription updated'));
        } else {
          // subscribe the user in case he is not subscribed
          register.pushManager
            // userVisibleOnly option set to true to ensure every coming message has a matching and visible notification
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(webpushPublicKey),
            })
            .then((newSubscription) => {
              console.log('user subscribed');
              updateUser({
                body: UserDetails.mapToApiValue({ subscription: newSubscription } as unknown as UserDetails),
                queryParams: { id },
              }).then(() => console.log('subscription updated'));
            })
            .catch(function (e) {
              if (Notification.permission === 'denied') {
                console.warn('Permission for notifications was denied');
              } else {
                console.error('Unable to subscribe to push', e);
              }
            });
        }
      });
    });
  }
};
// unsubscribe from notifications
export const unsubscribe = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((register) =>
      register.pushManager.getSubscription().then((sub) => {
        if (sub) {
          sub
            .unsubscribe()
            .then(() => console.log('user unsubscribed'))
            .catch((err) => console.log(err));
        }
      }),
    );
  }
};

// send push notification service
export const sendNofitication = async (notification: PushNotificationDetails) => {
  await post(apiUrlMatcher(ApiUrls.subscribeToPushNotifications), { body: notification });
};
