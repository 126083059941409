import { styled } from '@mui/material/styles';
import { Avatar, Typography, Badge } from '@mui/material';
import { stringToColor } from 'core/utils';
import React from 'react';

interface Props {
  avatar?: string | File | null;
  name: string;
  height?: number;
  width?: number;
  hasBadge?: boolean;
}

const stringAvatar = (name: string, width?: number, height?: number) => {
  const sx: { [key: string]: string | number } = { bgcolor: stringToColor(name) };
  if (width && height) {
    sx.width = width;
    sx.height = height;
  }

  const avatarContent = `${name.split(' ')[0][0]}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`.toUpperCase();
  return {
    sx,
    children: <Typography variant={height && height > 50 ? 'h2' : 'h6'}>{avatarContent}</Typography>,
  };
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
export const AvatarIcon: React.FC<Props> = ({ hasBadge = false, avatar, name, width, height }) => {
  const avatarIcon = avatar ? <Avatar src={avatar as string} alt={name} sx={{ width, height }} /> : <Avatar {...stringAvatar(name, width, height)} />;

  return hasBadge ? (
    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
      {avatarIcon}
    </StyledBadge>
  ) : (
    avatarIcon
  );
};
