import { NotificationDTO } from 'core/generated/NotificationDTO';
import { TaskDetails } from '../task/task-details';
import { I } from '../user/user-details';

export interface NotificationDetails extends I {
  id: string;
  content: string;
  read: boolean;
  task: TaskDetails;
  owner: string;
  project: string;
  createdAt?: string;
  updatedAt?: string;
}

export namespace NotificationDetails {
  export const mapFromApiValue = (notification: NotificationDTO): NotificationDetails => {
    const notificationDetails = {} as NotificationDetails;
    Object.keys(notification).forEach((key) => {
      if (notification[key]) {
        if (key === '_id') {
          notificationDetails['id'] = notification['_id'];
        } else if (key === 'task') {
          notificationDetails['task'] = TaskDetails.mapFromApiValue(notification['task']);
        } else {
          notificationDetails[key] = notification[key];
        }
      }
    });
    return notificationDetails;
  };
  export const mapToApiValue = (notification: NotificationDetails): NotificationDTO => {
    const notificationDto = {} as NotificationDTO;
    Object.keys(notification).forEach((key) => {
      if (notification[key]) {
        if (key === 'id') {
          notificationDto['_id'] = notification['id'];
        } else if (key === 'task') {
          notificationDto['task'] = TaskDetails.mapToApiValue(notification['task']);
        } else {
          notificationDto[key] = notification[key];
        }
      }
    });
    return notificationDto;
  };
}
