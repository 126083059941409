export const profile = {
  firstName: 'Edit first Name',
  lastName: 'Edit last Name',
  contact: 'Contact',
  about: 'About',
  edit_profile_pic: 'Edit profile photo',
  profile_updated: 'Profile was updated',
  job_title: 'Edit job title',
  department: 'Edit department',
  location: 'Edit location',
  mail: 'Edit email address',
  theme_edit: 'Edit theme',
  secondary_color: 'Secondary Color',
  primary_color: 'Primary Color',
  edit_secondary_color: 'Edit secondary Color',
  edit_primary_color: 'Edit primary Color',
  open_profile: 'Open profile',
  add_profile: 'add profile +',
  logout: 'Logout',
  event_link: 'Event link',
  participants: 'Participants',
  event_add_success: 'Event added successfully',
  event_update_success: 'Event updated successfully',
  event_remove_success: 'Event removed successfully',
  organizer: 'Organizer',
  calendar_updating: 'Calendar is being updated',
  calendar_updating_success: 'Calendar successfully updated',
  password: 'Edit password',
  email: 'Edit email',
};
