import { IDocs } from 'core/generated/fetchedData';
import { ProfileDto } from 'core/generated/ProfileDto';
import { ProfileDetails } from 'core/models/profile/profile-details';
import { HttpParamsType } from '../models/http-params.type';
import { apiUrlMatcher, ApiUrls } from './helpers';
import { post, put, _delete } from './helpers/base-http';

export const addRole = async (params: HttpParamsType<ProfileDto>): Promise<ProfileDetails> => {
  const data = await post<ProfileDto>(apiUrlMatcher(ApiUrls.addProfile), params);
  return ProfileDetails.mapFromApiValue(data);
};

export const updateRole = async (params: HttpParamsType<ProfileDto>): Promise<ProfileDetails> => {
  const data = await put<ProfileDto>(apiUrlMatcher(ApiUrls.updateProfile) + '/' + params?.body?._id, params);
  return ProfileDetails.mapFromApiValue(data);
};
export const deleteRole = async (id: string): Promise<IDocs<ProfileDetails[]>> => {
  const data = await _delete<IDocs<ProfileDto[]>>(apiUrlMatcher(ApiUrls.deleteProfile) + '/' + id);
  return { ...data, docs: data.docs.map((profile) => ProfileDetails.mapFromApiValue(profile)) };
};
