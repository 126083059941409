import React from 'react';
import { Button, IconButton, CardContent, Card, CardHeader, Stack, Tooltip, Typography, Divider } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material/';
import styles from './file.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/index';
import { resources, Permissions } from '../../../../core/enums';
import { isPermitted } from '../../../../core/utils';
import { downloadDocsAction } from 'modules/Project/state/project.actions';
import { Close as CloseIcon } from '@mui/icons-material/';

interface Props {
  filename: string;
  apiFilename?: string;
  size?: number;
  deleteAction?: (apiFilename: string) => void;
  hasAction?: boolean;
  fileStyles?: string;
  variant?: 'button' | 'card';
  cardHeaderAction?: () => void;
}

export const FileComponent: React.FC<Props> = ({
  variant = 'button',
  fileStyles = '',
  hasAction = true,
  filename = '',
  apiFilename = '',
  deleteAction = () => {},
  cardHeaderAction = () => {},
  size = 0,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);
  const permitted = isPermitted(user, resources.PROJECTS, Permissions.edit);
  const sizeInKB = Math.round(size / 1024);
  const sizeInMB = Math.round(sizeInKB / 1024);
  const fileSize = sizeInMB > 0 ? sizeInMB + ' MB' : sizeInKB + ' KB';
  // cleaning unwanted letters
  filename = filename.replace('uploads/', '');

  const handleClick = () => {
    dispatch(downloadDocsAction({ apiFilename, filename }));
  };
  return variant === 'button' ? (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width="100%">
      <Tooltip title={t('project.download_file') as string}>
        <Button className={fileStyles} onClick={() => handleClick()}>
          {filename}
        </Button>
      </Tooltip>
      {permitted && hasAction && (
        <Tooltip title={t('project.remove_file') as string}>
          <IconButton
            onClick={() => {
              deleteAction(apiFilename);
            }}
          >
            <DeleteIcon className={styles.delete_icon} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  ) : (
    <Card>
      <CardHeader
        action={
          <IconButton onClick={() => cardHeaderAction()}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Typography gutterBottom>{filename} </Typography>
        <Divider />
        <Typography color="gray">{fileSize}</Typography>
      </CardContent>
    </Card>
  );
};
