import { IconButton, InputBase } from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import styles from './search.module.scss';
interface Props {
  setsearchData: (value: string) => void;
  value?: string;
}

export const Search: React.FC<Props> = ({ setsearchData, value = '' }) => {
  const { t } = useTranslation();

  const handleChange = (data: string) => {
    setsearchData(data);
  };

  return (
    <InputBase
      value={value}
      className={styles.search_input}
      placeholder={t('common.search')}
      onChange={(e) => handleChange(e.target.value)}
      endAdornment={
        <IconButton disabled={value === ''} onClick={() => handleChange('')}>
          {!value ? <SearchIcon className={styles.icon_btn} /> : <ClearIcon className={styles.icon_btn} />}
        </IconButton>
      }
    />
  );
};
