export const task = {
  task: 'Task',
  issues: 'Issues',
  title: 'Title',
  key: 'Key',
  type: 'Type',
  status: 'Status',
  description: 'Description',
  estimatedDuration: 'Estimated duration',
  duration: 'Duration',
  priority: 'Priority',
  assignee: 'Assignee',
  assignees: 'Assignees',
  reporter: 'Reporter',
  story: 'Story',
  epic: 'Epic',
  sub_task: 'Sub task',
  create_task: 'Create issue',
  task_created: 'Task created',
  in_progress: 'In progress',
  to_do: 'To do',
  rejected: 'Rejected',
  need_clarification: 'Need clarification',
  done: 'Done',
  ready_for_test: 'Ready for test',
  need_to_be_fixed: 'Need to be fixed',
  task_updated: 'Task updated',
  time_tracking: 'Time tracking',
  sprint: 'Sprint',
};
