import { createReducer, Action } from '@reduxjs/toolkit';
import { recoverPwAction, failAction, recoverPwSuccessAction, resetPwSuccessAction } from './password.actions';

interface PasswordState {
  resetPasswordToken: string;
  resetPasswordExpires: string;
  loading: boolean;
}

const initialState: PasswordState = {
  resetPasswordToken: '',
  resetPasswordExpires: '',
  loading: false,
};

const _passwordReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(recoverPwAction, (state) => ({ ...state, loading: true }))
    .addCase(recoverPwSuccessAction, (state, { payload }) => ({
      ...state,
      loading: false,
      resetPasswordExpires: payload.resetPasswordExpires,
      resetPasswordToken: payload.resetPasswordToken,
    }))
    .addCase(failAction, (state) => ({ ...state, loading: false }))
    .addCase(resetPwSuccessAction, () => initialState),
);
export const passwordReducer = (state: PasswordState | undefined, action: Action) => _passwordReducer(state, action);
